import React from 'react';
// import $ from 'jquery';
import { Col, Form, Row } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { URLS } from '../urls';
import '../../assets/css/employee.css';
import '../../assets/css/jobgrade.css';
import '../../../src/assets/css/date-picker.css';
import AddJobGradeModal from './AddJobGradeModal';
import AddLocationModal from './AddLocationModal';
import AddEmployeeHMOModal from './AddEmployeeHMOModal';
import AddLifeInsuranceModal from './AddLifeInsuranceModal';
import DatePicker from 'react-datetime';
import moment from 'moment';
import Multiselect from './Multiselect';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class EmployeeModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleEducationInfoField = this.handleEducationInfoField.bind(this);
        this.addMoreEducationInfo = this.addMoreEducationInfo.bind(this);
        this.state = {
            show_add_job: false,
            show_add_location: false,
            show_add_employeeHMO: false,
            show_add_lifeInsurance: false,
            _id: 'qwerty',
            displayImageSrc: null,
            displayResumeSrc: null,
            pdfPageNumber: 1,
            pageHeight: 120,
            pageWidth: 120,
            buttonText : 'Submit',
            isWaiting : false,
            personalInfo: {
                name: {
                    first: '',
                    last: '',
                    middle: ''
                },
                address: {
                    apartment: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                phone: {
                    home: '',
                    alternate: ''
                },
                email: '',
                password : '',
                birth: '',
                isAdmin : '',
                govtId: '',
                gender: '',
                martial: 'Single',
                spouse: {
                    name: '',
                    employer: '',
                    phone: ''
                },
                prevEmployee: {
                    employee: '',
                    phone: ''
                }
            },
            jobInfo: {
                title: '',
                employeeId: '',
                grade: '',
                company_id : '',
                supervisor: [],
                department: '',
                workLocation: '',
                email: '',
                password :'',
                workPhone: '',
                cellPhone: '',
                startDate: '',
                salary: '',
                status: '',
                salaryBasis: '',
                contract: false,
                contractType: '',
            },
            emergencyInfo: {
                name: {
                    first: '',
                    last: '',
                    middle: '',
                },
                address: {
                    apartment: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                phone: {
                    primary: '',
                    secondary: '',
                },
                relation: '',
                guarantor: {
                    title: '',
                    name: '',
                    phone: '',
                    address: '',
                },
                referee: {
                    title: '',
                    name: '',
                    phone: '',
                    address: '',
                },
                employeeHMO: '',
                lifeInsurance: '',
                image: '',
                resume: '',
                certificate: '',
            },
            educationInfo: [{
                institution: '',
                subject: '',
                startDate: '',
                completeDate: '',
                degree: '',
                grade: '',
                certificate: ''
            }],
            jobGrades: [],
            locations: [],
            departments: [],
            employeeHMOs: [],
            lifeInsurances: [],
            educationCertificateFiles: [{
                file: null
            }],
            supervisors: [],
            companies : [],
            projects : []
        };
    }
    componentDidMount() {
        const token = 'dummy token'; // localStorage.getItem('token');
        fetch(`${URLS.backendJobGrades}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    jobGrades: result.jobGrades,
                });
            });

        fetch(`${URLS.backendDepartments}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    departments: result.departments,
                });
            });

        fetch(`${URLS.backendLocations}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    locations: result.locations,
                });
            });

        fetch(`${URLS.backendEmployeeHMOs}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    employeeHMOs: result.employeeHMOs,
                });
            });

        fetch(`${URLS.backendLifeInsurances}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    lifeInsurances: result.lifeInsurances,
                });
            });
        fetch(`${URLS.backendEmployees}/supervisors`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        }).then(res => res.json()).then((result) => {
            if (result.supervisors) {
                this.setState({
                    supervisors: result.supervisors,
                });
            }
        });

        fetch(`${URLS.backendCompanies}/get`, {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        }).then(res => res.json()).then((result) => {
            if (result.companies) {
                this.setState({
                    companies: result.companies,
                });
            }
        });

        fetch(`${URLS.backendProjects}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
            this.setState({
                projects: result.projects,
            });
        });

    };
    componentWillReceiveProps(props) {
        if (Object.keys(props.employee).length) {
            this.setState({
                personalInfo: props.employee.personalInfo,
                jobInfo: props.employee.jobInfo,
                emergencyInfo: props.employee.emergencyInfo,
                educationInfo: props.employee.educationInfo,
                _id: props.employee._id
            });
            const educationInfo = props.employee.educationInfo;
            const educationInfoLength = educationInfo.length;
            console.log('educationInfo', this.state.educationInfo);
            console.log('educationCertificateFiles', this.state.educationCertificateFiles);
            console.log('educationInfoLength', educationInfoLength);
            let newEducationCertificateFiles = [];
            for (let index = 0; index < educationInfoLength; index++) {
                newEducationCertificateFiles.push({ 'file': null });
            }
            this.setState({ educationCertificateFiles: newEducationCertificateFiles });
        }
    }
    changeShowJobState = () => {
        const { show_add_job } = this.state;
        this.setState({
            show_add_job: !show_add_job
        });
    };
    changeShowLocationState = () => {
        const { show_add_location } = this.state;
        this.setState({
            show_add_location: !show_add_location
        });
    };
    changeShowEmployeeHMOState = () => {
        const { show_add_employeeHMO } = this.state;
        this.setState({
            show_add_employeeHMO: !show_add_employeeHMO
        });
    };
    changeShowLifeInsuranceState = () => {
        const { show_add_lifeInsurance } = this.state;
        this.setState({
            show_add_lifeInsurance: !show_add_lifeInsurance
        });
    };
    updateJobGradesList = (item) => {
        const { jobGrades } = this.state;
        jobGrades.push(item);
        this.setState({
            jobGrades
        });
    };
    updateLocationsList = (item) => {
        const { locations } = this.state;
        locations.push(item);
        this.setState({
            locations
        });
    };
    updateEmployeeHMOsList = (item) => {
        const { employeeHMOs } = this.state;
        employeeHMOs.push(item);
        this.setState({
            employeeHMOs
        });
    };
    updateLifeInsurancesList = (item) => {
        const { lifeInsurances } = this.state;
        lifeInsurances.push(item);
        this.setState({
            lifeInsurances
        });
    };

    handleEducationInfoField = idx => evt => {
        const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
            if (idx !== eIdx) return info;
            let tName = evt.target.name;
            const value = evt.target.value;
            return { ...info, [tName]: value };
        });
        this.setState({ educationInfo: newEducationInfo });
    };

    handleEducationInfoCompleteField = idx => evt => {
        const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
            if (idx !== eIdx) return info;
            let tName = 'completeDate';
            const value = evt.target ? evt.target.value : moment(evt).format("DD/MM/YYYY");
            return { ...info, [tName]: value };
        });
        this.setState({ educationInfo: newEducationInfo });
    };

    handleEducationInfoStartField = idx => evt => {
        const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
            if (idx !== eIdx) return info;
            let tName = 'startDate';
            const value = evt.target ? evt.target.value : moment(evt).format("DD/MM/YYYY");;
            return { ...info, [tName]: value };
        });
        this.setState({ educationInfo: newEducationInfo });
    };
    handleEducationInfoFileField = idx => evt => {
        const refName = 'certificate_' + idx;
        const file = this.refs[refName].files[0] ? this.refs[refName].files[0] : null;
        if (file !== null) {
            const { educationInfo, educationCertificateFiles } = this.state;
            let currentDate = new Date();
            currentDate = currentDate.getTime();
            educationInfo[idx].certificate = currentDate + '.pdf';
            educationCertificateFiles[idx].file = file;
            this.setState({ educationInfo: educationInfo, educationCertificateFiles: educationCertificateFiles });
        }
    };
    addMoreEducationInfo() {
        this.setState({
            educationInfo: this.state.educationInfo.concat({
                institution: '',
                subject: '',
                startDate: '',
                completeDate: '',
                degree: '',
                grade: '',
                certificate: ''
            })
        });
        this.setState({
            educationCertificateFiles: this.state.educationCertificateFiles.concat({
                file: null
            })
        });
    }

    updatePersonalInfoFirstName = (event) => {
        const { personalInfo } = this.state;
        personalInfo.name.first = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoLastName = (event) => {
        const { personalInfo } = this.state;
        personalInfo.name.last = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoMiddleName = (event) => {
        const { personalInfo } = this.state;
        personalInfo.name.middle = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoAddressApartment = (event) => {
        const { personalInfo } = this.state;
        personalInfo.address.apartment = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoAddressStreet = (event) => {
        const { personalInfo } = this.state;
        personalInfo.address.street = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoAddressCity = (event) => {
        const { personalInfo } = this.state;
        personalInfo.address.city = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoAddressState = (event) => {
        const { personalInfo } = this.state;
        personalInfo.address.state = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoAddressZip = (event) => {
        const { personalInfo } = this.state;
        personalInfo.address.zip = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoPhoneHome = (event) => {
        const { personalInfo } = this.state;
        personalInfo.phone.home = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoPhoneAlternate = (event) => {
        const { personalInfo } = this.state;
        personalInfo.phone.alternate = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoEmail = (event) => {
        const { personalInfo } = this.state;
        personalInfo.email = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoPassword = (event) => {
        const { personalInfo } = this.state;
        personalInfo.password = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoIsAdmin = (event) => {
        const { personalInfo } = this.state;
        personalInfo.isAdmin = event.target.checked;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoGovtId = (event) => {
        const { personalInfo } = this.state;
        personalInfo.govtId = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalGender = (event) => {
        const { personalInfo } = this.state;
        personalInfo.gender = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoBirth = (event) => {
        const { personalInfo } = this.state;
        personalInfo.birth = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoMartial = (event) => {
        const { personalInfo } = this.state;
        personalInfo.martial = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoSpouseName = (event) => {
        const { personalInfo } = this.state;
        personalInfo.spouse.name = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoSpouseEmployer = (event) => {
        const { personalInfo } = this.state;
        personalInfo.spouse.employer = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoSpousePhone = (event) => {
        const { personalInfo } = this.state;
        personalInfo.spouse.phone = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoPrevEmployeeEmployee = (event) => {
        const { personalInfo } = this.state;
        personalInfo.prevEmployee.employee = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updatePersonalInfoPrevEmployeePhone = (event) => {
        const { personalInfo } = this.state;
        personalInfo.prevEmployee.phone = event.target.value;
        this.setState({
            personalInfo
        });
    };
    updateJobInfoTitle = (event) => {
        const { jobInfo } = this.state;
        jobInfo.title = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoEmployeeId = (event) => {
        const { jobInfo } = this.state;
        jobInfo.employeeId = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoGrade = (event) => {
        const { jobInfo } = this.state;
        const value = event.target.value;
        if (value === 'add_job') {
            // $('#add_job').show();
            const { show_add_job } = this.state;
            this.setState({
                show_add_job: !show_add_job
            });
        } else {
            jobInfo.grade = value
            this.setState({
                jobInfo
            });
        }
    };
    updateJobInfoCompanyId = (event) => {
        const { jobInfo } = this.state;
        jobInfo.company_id = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoSupervisor = (params) => {
        const { jobInfo } = this.state;
        jobInfo.supervisor = params.map((data)=>{return data.id});
        this.setState({
            jobInfo
        });
        console.log(this.state.jobInfo);
    };
    updateJobInfoDepartment = (event) => {
        const { jobInfo } = this.state;
        jobInfo.department = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoWorkLocation = (event) => {
        const { jobInfo } = this.state;
        const value = event.target.value;
        if (value === 'add_location') {
            const { show_add_location } = this.state;
            this.setState({
                show_add_location: !show_add_location
            });
        } else {
            jobInfo.workLocation = value;
            this.setState({
                jobInfo
            });
        }
    };
    updateJobInfoEmail = (event) => {
        const { jobInfo } = this.state;
        jobInfo.email = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoWorkPhone = (event) => {
        const { jobInfo } = this.state;
        jobInfo.workPhone = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoCellPhone = (event) => {
        const { jobInfo } = this.state;
        jobInfo.cellPhone = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoStartDate = (event) => {
        const { jobInfo } = this.state;
        jobInfo.startDate = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoSalary = (event) => {
        const { jobInfo } = this.state;
        jobInfo.salary = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoStatus = (event) => {
        const { jobInfo } = this.state;
        jobInfo.status = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoSalaryBasis = (event) => {
        const { jobInfo } = this.state;
        jobInfo.salaryBasis = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoContract = (event) => {
        const { jobInfo } = this.state;
        jobInfo.contract = event.target.checked;
        this.setState({
            jobInfo
        });
    };
    updateJobInfoContractType = (event) => {
        const { jobInfo } = this.state;
        jobInfo.contractType = event.target.value;
        this.setState({
            jobInfo
        });
    };
    updateEmergencyInfoNameFirst = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.name.first = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoNameLast = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.name.last = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoNameMiddle = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.name.middle = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoAddressApartment = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.address.apartment = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoAddressStreet = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.address.street = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoAddressCity = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.address.city = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoAddressState = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.address.state = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoAddressZip = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.address.zip = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoPhonePrimary = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.phone.primary = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoPhoneSecondary = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.phone.secondary = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoRelation = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.relation = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoGuarantorTitle = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.guarantor.title = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoGuarantorName = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.guarantor.name = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoGuarantorPhone = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.guarantor.phone = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoGuarantorAddress = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.guarantor.address = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoRefereeTitle = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.referee.title = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoRefereeName = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.referee.name = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoRefereePhone = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.referee.phone = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoRefereeAddress = (event) => {
        const { emergencyInfo } = this.state;
        emergencyInfo.referee.address = event.target.value;
        this.setState({
            emergencyInfo
        });
    };
    updateEmergencyInfoEmployeeHMO = (event) => {
        const { emergencyInfo } = this.state;
        const value = event.target.value;
        if (value === 'add_employeeHMO') {
            const { show_add_employeeHMO } = this.state;
            this.setState({
                show_add_employeeHMO: !show_add_employeeHMO
            });
        } else {
            emergencyInfo.employeeHMO = value;
            this.setState({
                emergencyInfo
            });
        }
    };
    updateEmergencyInfoLifeInsurance = (event) => {
        const { emergencyInfo } = this.state;
        const value = event.target.value;
        if (value === 'add_lifeInsurance') {
            const { show_add_lifeInsurance } = this.state;
            this.setState({
                show_add_lifeInsurance: !show_add_lifeInsurance
            });
        } else {
            emergencyInfo.lifeInsurance = value;
            this.setState({
                emergencyInfo
            });
        }
    };
    updateEmergencyInfoImage = (event) => {
        const { emergencyInfo } = this.state;
        let currentDate = new Date();
        currentDate = currentDate.getTime();
        // currentDate = currentDate.toUTCString();
        // currentDate = currentDate.replace(/ /g, '_');
        emergencyInfo.image = currentDate + '.png';
        this.setState({
            emergencyInfo
        });
        let file = this.refs.emergency_image.files[0];
        let reader = new FileReader();
        let url = reader.readAsDataURL(file);
        reader.onloadend = function () {
            this.setState({
                displayImageSrc: [reader.result]
            })
        }.bind(this);
    };
    updateEmergencyInfoResume = (event) => {
        const { emergencyInfo } = this.state;
        let currentDate = new Date();
        currentDate = currentDate.getTime();
        // currentDate = currentDate.toUTCString();
        // currentDate = currentDate.replace(/ /g, '_');
        // emergencyInfo.resume = this.refs.emergency_resume.files[0].name +' '+ currentDate;
        emergencyInfo.resume = currentDate + '.pdf';
        this.setState({
            emergencyInfo
        });
        this.setState({
            displayResumeSrc: event.target.files[0]
        });
    };
    onDocumentLoadSuccess = (e) => {
        console.log('success', e);
    };
    updateEmergencyInfoCertificate = (event) => {
        const { emergencyInfo } = this.state;
        let currentDate = new Date();
        currentDate = currentDate.toUTCString();
        currentDate = currentDate.replace(/ /g, '_');
        emergencyInfo.certificate = currentDate + '.pdf';
        this.setState({
            emergencyInfo
        });
    };

    submit = (e) => {
        this.setState({buttonText:'Submitting ...',isWaiting:true})
        e.preventDefault();
        const { educationInfo, personalInfo, jobInfo, emergencyInfo, _id, educationCertificateFiles } = this.state;
        let formData = new FormData();

        const employee = { _id, personalInfo, jobInfo, emergencyInfo, educationInfo };
        // employee.emergencyInfo.name = imageName;
        // employee.emergencyInfo.resume = resumeName;
        // employee.emergencyInfo.certificate = certificateName;

        formData.append('employee', JSON.stringify(employee));
        if(this.refs.emergency_image && this.refs.emergency_image.files[0])
            formData.append('file', this.refs.emergency_image.files[0], this.state.emergencyInfo.image);
        if(this.refs.emergency_resume && this.refs.emergency_resume.files[0])
            formData.append('file', this.refs.emergency_resume.files[0], this.state.emergencyInfo.resume);
        // formData.append('file', this.refs.emergency_certificate.files[0], this.state.emergencyInfo.certificate);
        
        for (let i = 0; i < educationInfo.length; i++) {
            if(educationCertificateFiles[i].file)
                formData.append('file', educationCertificateFiles[i].file, educationInfo[i].certificate);
        }

        const token = 'dummy token'; // localStorage.getItem('token');
        fetch(`${URLS.backendEmployees}/update`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
            },
            body: formData,
        }).then(res => res.json())
            .then((result) => {
                setTimeout(()=>{
                    window.$("#edit_employee").modal("toggle");
                    this.setState({ buttonText: 'Submit', isWaiting: false });
                }, 500);
                this.props.updateEmployeesList(result.employee);
            });
    };

    render() {
        const { mode } = this.props;
        const id = (mode === 'edit' ? 'edit_employee' : 'add_empolyee');
        const title = (mode === 'edit' ? 'Edit Employee' : 'Add Employee');
        const { personalInfo, jobInfo, emergencyInfo, educationInfo, jobGrades, departments, locations, educationCertificateFiles, displayImageSrc, displayResumeSrc, pdfPageNumber, employeeHMOs, lifeInsurances, supervisors, projects } = this.state;
        const displayImage = displayImageSrc ? displayImageSrc : URLS.backendStatic + '/' + emergencyInfo.image;
        const displayResume = displayResumeSrc ? displayResumeSrc : URLS.backendStatic + '/' + emergencyInfo.resume;
        let displayEducationCertificates = [];
        for (let i = 0; i < educationInfo.length; i++) {
            let sFileUrl = URLS.backendStatic + '/' + educationInfo[i].certificate;
            let fff = educationCertificateFiles[i].file;
            if (fff) {
                displayEducationCertificates.push(fff);
            } else {
                displayEducationCertificates.push(sFileUrl);
            }
        }


        return (
            <div id={id} className='modal custom-modal fade' role='dialog'>
                <AddJobGradeModal updateJobGradesList={this.updateJobGradesList} isShow={this.state.show_add_job} changeShowJobState={this.changeShowJobState} />
                <AddLocationModal updateLocationsList={this.updateLocationsList} isShow={this.state.show_add_location} changeShowLocationState={this.changeShowLocationState} />
                <AddEmployeeHMOModal updateEmployeeHMOsList={this.updateEmployeeHMOsList} isShow={this.state.show_add_employeeHMO} changeShowEmployeeHMOState={this.changeShowEmployeeHMOState} />
                <AddLifeInsuranceModal updateLifeInsurancesList={this.updateLifeInsurancesList} isShow={this.state.show_add_lifeInsurance} changeShowLifeInsuranceState={this.changeShowLifeInsuranceState} />
                <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>{title}</h5>
                            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <Form onSubmit={this.submit}>
                                <Form.Group as={Row}>
                                    {/*<Col xs={6}>*/}
                                    {/*    <Image src={this.state.displayImageSrc} alt='Image'*/}
                                    {/*        style={{ height: '100px', width: '80px' }} rounded />*/}
                                    {/*</Col>*/}
                                    <Col xs={{ span: 6, offset: 6 }} style={{
                                        textAlignLast: 'end',
                                        justifyContent: 'right'
                                    }}>
                                        Company Name
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} style={{
                                    backgroundColor: '#00c5fb',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    color: '#ffffff',
                                    fontWeight: 'bold'
                                }}>
                                    Personal Information
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Full Name
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Control placeholder='First name' value={personalInfo.name.first}
                                                    onChange={this.updatePersonalInfoFirstName.bind(this)} required/>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Control placeholder='Last name' value={personalInfo.name.last}
                                                    onChange={this.updatePersonalInfoLastName.bind(this)} required/>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Control placeholder='Middle Initial'
                                                    value={personalInfo.name.middle}
                                                    onChange={this.updatePersonalInfoMiddleName.bind(this)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Address
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='Apartment/Unit #'
                                                    value={personalInfo.address.apartment}
                                                    onChange={this.updatePersonalInfoAddressApartment.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={8}>
                                                <Form.Control placeholder='Street Address'
                                                    value={personalInfo.address.street}
                                                    onChange={this.updatePersonalInfoAddressStreet.bind(this)} />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='City' value={personalInfo.address.city}
                                                    onChange={this.updatePersonalInfoAddressCity.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='State' value={personalInfo.address.state}
                                                    onChange={this.updatePersonalInfoAddressState.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='Zip code' value={personalInfo.address.zip}
                                                    onChange={this.updatePersonalInfoAddressZip.bind(this)} />
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Home Phone
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={personalInfo.phone.home}
                                            onChange={this.updatePersonalInfoPhoneHome.bind(this)} />
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Alternate Phone
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={personalInfo.phone.alternate}
                                            onChange={this.updatePersonalInfoPhoneAlternate.bind(this)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Email
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control placeholder='Email' value={personalInfo.email}
                                            onChange={this.updatePersonalInfoEmail.bind(this)} />
                                    </Col>
                                    {/* <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Password
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={personalInfo.password}
                                            onChange={this.updatePersonalInfoPassword.bind(this)} />
                                    </Col> */}
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                       Govt ID:
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control placeholder='Gov’t ID:' value={personalInfo.govtId}
                                            onChange={this.updatePersonalInfoGovtId.bind(this)} />
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Is Admin
                                    </Form.Label>
                                    <Col sm={4}>
                                        <div className="form-check" style={{ height: '44px',padding: '6px 0',display: 'inline-block' }}>
                                            <input style={{height:'30px',width:'30px'}} type='checkbox' checked={personalInfo.isAdmin}
                                                onChange={this.updatePersonalInfoIsAdmin.bind(this)} />
                                                <label style={{ margin: '10px',verticalAlign: 'bottom'}}>Check if Admin</label>
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Gender:
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control as='select' value={personalInfo.gender}
                                            onChange={this.updatePersonalGender.bind(this)}>
                                            <option>Choose...</option>
                                            <option value='Male'>Male</option>
                                            <option value='Female'>Female</option>
                                            <option value='Others'>Others</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Birth Date
                                    </Form.Label>
                                    <Col sm={4}>
                                        <div classname='datePicker'>
                                            <i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
                                            <DatePicker value={personalInfo.birth} closeOnSelect={true}
                                                onChange={this.updatePersonalInfoBirth.bind(this)} />
                                        </div>


                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Marital Status
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control as='select' value={personalInfo.martial}
                                            onChange={this.updatePersonalInfoMartial.bind(this)}>
                                            <option value='Single'>Single</option>
                                            <option value='Married'>Married</option>
                                            <option value='Divorce'>Divorce</option>
                                            <option value='Widow'>Widow</option>
                                            <option value='Widower'>Widower</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                {
                                    personalInfo.martial !== 'Single' &&
                                        <Form.Group as={Row}>
                                            <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                Spouse’s Name
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Control value={personalInfo.spouse.name}
                                                    onChange={this.updatePersonalInfoSpouseName.bind(this)} />
                                            </Col>
                                        </Form.Group>
                                }
                                {
                                    personalInfo.martial !== 'Single' &&
                                        <Form.Group as={Row}>
                                            <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                Spouse’s Employer
                                            </Form.Label>
                                            <Col sm={4}>
                                                <Form.Control placeholder='Spouse’s Employer'
                                                    value={personalInfo.spouse.employer}
                                                    onChange={this.updatePersonalInfoSpouseEmployer.bind(this)} />
                                            </Col>
                                            <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                Spouse’s Work Phone
                                            </Form.Label>
                                            <Col sm={4}>
                                                <Form.Control placeholder='Spouse’s Work Phone'
                                                    value={personalInfo.spouse.phone}
                                                    onChange={this.updatePersonalInfoSpousePhone.bind(this)} />
                                            </Col>
                                        </Form.Group>
                                }
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Previous Employer
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control placeholder='Previous Employee'
                                            value={personalInfo.prevEmployee.employee}
                                            onChange={this.updatePersonalInfoPrevEmployeeEmployee.bind(this)} />
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Previous Employer Phone
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control placeholder='Previous Employer Phone'
                                            value={personalInfo.prevEmployee.phone}
                                            onChange={this.updatePersonalInfoPrevEmployeePhone.bind(this)} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} style={{
                                    backgroundColor: '#00c5fb',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    color: '#ffffff',
                                    fontWeight: 'bold'
                                }}>
                                    Job Information
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Job Title
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={jobInfo.title}
                                            onChange={this.updateJobInfoTitle.bind(this)} />
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Employee ID
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={jobInfo.employeeId}
                                            onChange={this.updateJobInfoEmployeeId.bind(this)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Job Grade
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control as='select' value={jobInfo.grade}
                                            onChange={this.updateJobInfoGrade.bind(this)} required>
                                            <option value=''>Select</option>
                                            <option value='add_job'>Add New</option>
                                            <option disabled>__________________</option>
                                            {
                                                jobGrades.map(jobGrade => {
                                                    return <option value={jobGrade._id}>{jobGrade.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Work Location
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control as='select' value={jobInfo.workLocation}
                                            onChange={this.updateJobInfoWorkLocation.bind(this)} required>
                                            <option value=''>Select</option>
                                            <option value='add_location'>Add New</option>
                                            <option disabled>__________________</option>
                                            {
                                                locations.map(location => {
                                                    return <option value={location._id}>{location.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Supervisor
                                    </Form.Label>
                                    <Col sm={4}>
                                    {
                                        supervisors.length && <Multiselect options={supervisors} onSelectOptions={this.updateJobInfoSupervisor} selectedOptions={jobInfo.supervisor}/>
                                    }
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Department
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control as='select' value={jobInfo.department} onChange={this.updateJobInfoDepartment.bind(this)} required>
                                            <option value=''>Select</option>
                                            {
                                                departments.map((dep) => {
                                                    return <option value={dep._id}>{dep.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Email
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={jobInfo.email} type="email"
                                            onChange={this.updateJobInfoEmail.bind(this)}  required/>
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Password
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={jobInfo.password} type="password"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Work Phone
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={jobInfo.workPhone}
                                            onChange={this.updateJobInfoWorkPhone.bind(this)} />
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Cell Phone
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={jobInfo.cellPhone}
                                            onChange={this.updateJobInfoCellPhone.bind(this)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Start Date
                                    </Form.Label>
                                    <Col sm={4}>
                                        <div classname='datePicker'>
                                            <i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
                                            <DatePicker value={jobInfo.startDate} closeOnSelect={true}
                                                onChange={this.updateJobInfoStartDate.bind(this)} />
                                        </div>

                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Salary
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={jobInfo.salary}
                                            onChange={this.updateJobInfoSalary.bind(this)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Status
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control as='select' value={jobInfo.status}
                                            onChange={this.updateJobInfoStatus.bind(this)}>
                                            <option>Choose...</option>
                                            <option value='Internship'>Internship</option>
                                            <option value='Probation'>Probation</option>
                                            <option value='Trainee'>Trainee</option>
                                            <option value='Confirmed'>Confirmed</option>
                                        </Form.Control>
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Salary Basis
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control as='select' value={jobInfo.salaryBasis}
                                            onChange={this.updateJobInfoSalaryBasis.bind(this)}>
                                            <option>Choose...</option>
                                            <option value='Hourly'>Hourly</option>
                                            <option value='Daily'>Daily</option>
                                            <option value='Daily'>Weekly</option>
                                            <option value='Monthly'>Monthly</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Contractor
                                    </Form.Label>
                                    <Col sm={4}>
                                        <div className="form-check" style={{height:'44px',padding:'6px 0', display:'inline-block'}}>
                                            <input style={{height:'30px',width:'30px'}} type='checkbox' 
                                                checked={jobInfo.contract}
                                                onChange={this.updateJobInfoContract.bind(this)} />
                                            <label style={{ margin: '10px',verticalAlign: 'bottom'}}>Check if contract based</label>
                                        </div>
                                    </Col>
                                    {jobInfo.contract && <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Contract Type
                                    </Form.Label>}
                                    {jobInfo.contract && <Col sm={4}>
                                        <Form.Control as='select' value={jobInfo.contractType}
                                            onChange={this.updateJobInfoContractType.bind(this)}>
                                                <option value=''>Choose...</option>
                                                {
                                                    projects.length ? projects.map(project => {
                                                        return <option value={project._id}>{project.name}</option>
                                                    }) : <option value=''>Choose...</option>
                                                }
                                        </Form.Control>
                                    </Col>}
                                </Form.Group>

                                <Form.Group as={Row} style={{
                                    backgroundColor: '#00c5fb',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    color: '#ffffff',
                                    fontWeight: 'bold'
                                }}>
                                    Emergency Contact Information
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Full Name
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Control placeholder='First name' value={emergencyInfo.name.first}
                                                    onChange={this.updateEmergencyInfoNameFirst.bind(this)} />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Control placeholder='Last name' value={emergencyInfo.name.last}
                                                    onChange={this.updateEmergencyInfoNameLast.bind(this)} />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Control placeholder='Middle Initial'
                                                    value={emergencyInfo.name.middle}
                                                    onChange={this.updateEmergencyInfoNameMiddle.bind(this)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Address
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='Apartment/Unit #'
                                                    value={emergencyInfo.address.apartment}
                                                    onChange={this.updateEmergencyInfoAddressApartment.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={8}>
                                                <Form.Control placeholder='Street Address'
                                                    value={emergencyInfo.address.street}
                                                    onChange={this.updateEmergencyInfoAddressStreet.bind(this)} />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='City' value={emergencyInfo.address.city}
                                                    onChange={this.updateEmergencyInfoAddressCity.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='State' value={emergencyInfo.address.state}
                                                    onChange={this.updateEmergencyInfoAddressState.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4}>
                                                <Form.Control placeholder='Zip code' value={emergencyInfo.address.zip}
                                                    onChange={this.updateEmergencyInfoAddressZip.bind(this)} />
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Home Phone
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={emergencyInfo.phone.primary}
                                            onChange={this.updateEmergencyInfoPhonePrimary.bind(this)} />
                                    </Col>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Alternate Phone
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control value={emergencyInfo.phone.secondary}
                                            onChange={this.updateEmergencyInfoPhoneSecondary.bind(this)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Relationship
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control value={emergencyInfo.relation}
                                            onChange={this.updateEmergencyInfoRelation.bind(this)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Guarantor
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={2}>
                                                <Form.Control placeholder='Title' value={emergencyInfo.guarantor.title}
                                                    onChange={this.updateEmergencyInfoGuarantorTitle.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={5}>
                                                <Form.Control placeholder='Guarantor Name'
                                                    value={emergencyInfo.guarantor.name}
                                                    onChange={this.updateEmergencyInfoGuarantorName.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={5}>
                                                <Form.Control placeholder='Guarantor Phone'
                                                    value={emergencyInfo.guarantor.phone}
                                                    onChange={this.updateEmergencyInfoGuarantorPhone.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={12}>
                                                <Form.Control placeholder='Guarantor Address'
                                                    value={emergencyInfo.guarantor.address}
                                                    onChange={this.updateEmergencyInfoGuarantorAddress.bind(this)} />
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Referee
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={2}>
                                                <Form.Control placeholder='Title' value={emergencyInfo.referee.title}
                                                    onChange={this.updateEmergencyInfoRefereeTitle.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={5}>
                                                <Form.Control placeholder='Referee Name'
                                                    value={emergencyInfo.referee.name}
                                                    onChange={this.updateEmergencyInfoRefereeName.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={5}>
                                                <Form.Control placeholder='Referee Phone'
                                                    value={emergencyInfo.referee.phone}
                                                    onChange={this.updateEmergencyInfoRefereePhone.bind(this)} />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={12}>
                                                <Form.Control placeholder='Referee Address'
                                                    value={emergencyInfo.referee.address}
                                                    onChange={this.updateEmergencyInfoRefereeAddress.bind(this)} />
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Employee HMO
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as='select' value={emergencyInfo.employeeHMO}
                                            onChange={this.updateEmergencyInfoEmployeeHMO.bind(this)} required>
                                            <option value=''>Select</option>
                                            <option value='add_employeeHMO'>Add New</option>
                                            <option disabled>__________________</option>
                                            {
                                                employeeHMOs.map(location => {
                                                    return <option value={location._id}>{location.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Group Life Insurance
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as='select' value={emergencyInfo.lifeInsurance}
                                            onChange={this.updateEmergencyInfoLifeInsurance.bind(this)} required>
                                            <option value=''>Select</option>
                                            <option value='add_lifeInsurance'>Add New</option>
                                            <option disabled>__________________</option>
                                            {
                                                lifeInsurances.map(lifeInsurance => {
                                                    return <option value={lifeInsurance._id}>{lifeInsurance.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Upload Employee Photo
                                    </Form.Label>
                                    <Col sm={10}>
                                        <div className='file-upload-div'>
                                            <div className='file-preview'>
                                                <img style={{ height: '120px', width: '110px' }} src={displayImage} alt=''/>
                                            </div>

                                            <div className='upload-btn-wrapper'>
                                                <button className='custom-upload-btn'>Browse</button>
                                                <input type='file' label='Upload File' ref='emergency_image'
                                                    onChange={this.updateEmergencyInfoImage.bind(this)} />
                                            </div>
                                        </div>

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Upload Employee Resume
                                    </Form.Label>
                                    <Col sm={10}>
                                        <div className='file-upload-div'>
                                            <div className='file-preview'>
                                                <Document file={displayResume}
                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                    noData={<div style={{ width: '110px', height: '120px', border: '1px solid #bebebe' }}></div>}>
                                                    <Page pageNumber={pdfPageNumber} height={this.state.pageHeight} width={this.state.pageWidth} className='change-pdf-size' />
                                                </Document>
                                            </div>

                                            <div className='upload-btn-wrapper'>
                                                <button className='custom-upload-btn'>Browse</button>
                                                <input type='file' label='Upload File' ref='emergency_resume'
                                                    onChange={this.updateEmergencyInfoResume.bind(this)} />
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} style={{
                                    backgroundColor: '#00c5fb',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    color: '#ffffff',
                                    fontWeight: 'bold'
                                }}>
                                    Education Information
                                </Form.Group>
                                {
                                    educationInfo.map((info, idx) => {
                                        return (
                                            <Form style={{
                                                border: '1px solid #e3e3e3',
                                                borderRadius: '5px',
                                                padding: '20px 10px',
                                                margin: '10px'
                                            }}>
                                                <Form.Group>
                                                    Education Information
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                        Institution
                                                    </Form.Label>
                                                    <Col sm={4}>
                                                        <Form.Control value={info.institution} name='institution'
                                                            id={'institution' + idx}
                                                            onChange={this.handleEducationInfoField(idx)} />
                                                    </Col>
                                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                        Subject
                                                    </Form.Label>
                                                    <Col sm={4}>
                                                        <Form.Control value={info.subject} name='subject'
                                                            id={'subject' + idx}
                                                            onChange={this.handleEducationInfoField(idx)} />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                        Starting Date
                                                    </Form.Label>
                                                    <Col sm={4}>
                                                        <div classname='datePicker'>
                                                            <i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
                                                            <DatePicker value={info.startDate} name='startDate'
                                                                id={'startDate' + idx}
                                                                closeOnSelect={true}
                                                                onChange={this.handleEducationInfoStartField(idx)} />
                                                        </div>

                                                    </Col>
                                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                        Complete Date
                                                    </Form.Label>
                                                    <Col sm={4}>
                                                        <div classname='datePicker'>
                                                            <i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
                                                            <DatePicker datepicker value={info.completeDate} name='completeDate'
                                                                id={'completeDate' + idx}
                                                                closeOnSelect={true}
                                                                onChange={this.handleEducationInfoCompleteField(idx)} />
                                                        </div>


                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                        Degree
                                                    </Form.Label>
                                                    <Col sm={4}>
                                                        <Form.Control value={info.degree} name='degree'
                                                            id={'degree' + idx}
                                                            onChange={this.handleEducationInfoField(idx)} />
                                                    </Col>
                                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                        Grade
                                                    </Form.Label>
                                                    <Col sm={4}>
                                                        <Form.Control value={info.grade} name='grade' id={'grade' + idx}
                                                            onChange={this.handleEducationInfoField(idx)} />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                                        Upload Employee Certificate
                                                    </Form.Label>
                                                    <Col sm={10}>
                                                        <div className='file-upload-div'>
                                                            <div className='file-preview'>
                                                                <Document file={displayEducationCertificates[idx]}
                                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                                    noData={<div style={{ width: '110px', height: '120px', border: '1px solid #bebebe' }}></div>}>
                                                                    <Page pageNumber={pdfPageNumber} height={this.state.pageHeight} width={this.state.pageWidth} className='change-pdf-size' />
                                                                </Document>
                                                            </div>

                                                            <div className='upload-btn-wrapper'>
                                                                <button className='custom-upload-btn'>Browse</button>
                                                                <input type='file' label='Upload File' name='certificate' ref={'certificate_' + idx}
                                                                    onChange={this.handleEducationInfoFileField(idx)} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        );
                                    })
                                }
                                <div className='add-more'>
                                    <a href='javascript:void(0);' onClick={this.addMoreEducationInfo}><i
                                        className='fa fa-plus-circle' /> Add More</a>
                                </div>

                                <div className='submit-section'>
                                    <button className='btn btn-primary submit-btn'>{this.state.buttonText}</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}