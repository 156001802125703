import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-sm-12">
							<h4 className="page-title">Payments</h4>
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">	
								<table className="table table-striped custom-table datatable mb-0">
									<thead>
										<tr>
											<th>Invoice ID</th>
											<th>Client</th>
											<th>Payment Type</th>
											<th>Paid Date</th>
											<th>Paid Amount</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td><a href="invoice-view.html">#INV-0001</a></td>
											<td>
												<h2><a href="#">Global Technologies</a></h2>
											</td>
											<td>Paypal</td>
											<td>8 Feb 2019</td>
											<td>$500</td>
										</tr>
										<tr>
											<td><a href="invoice-view.html">#INV-0002</a></td>
											<td>
												<h2><a href="#">Delta Infotech</a></h2>
											</td>
											<td>Paypal</td>
											<td>8 Feb 2019</td>
											<td>$500</td>
										</tr>
										<tr>
											<td><a href="invoice-view.html">#INV-0003</a></td>
											<td>
												<h2><a href="#">Cream Inc</a></h2>
											</td>
											<td>Paypal</td>
											<td>8 Feb 2019</td>
											<td>$500</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
				
            </div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Payments))

