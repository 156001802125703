import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      notifications: [],
    };
  }
  componentWillMount() {
    const id = localStorage.getItem("userId");
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employee: result.employee,
        });
      });

    fetch(`${URLS.backendNotifications}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          notifications: result.notifications,
        });
      });
  }
  getDateDiff = (date) => {
    let createDate = moment(date);
    let now = moment(new Date());
    let diff = now.diff(createDate);
    diff = parseInt(diff / 1000);
    if (diff < 60) {
      return diff + " seconds ago";
    }
    diff = parseInt(diff / 60);
    if (diff < 60) {
      if (diff === 1) return "a min ago";
      else return diff + " mins ago";
    }

    diff = parseInt(diff / 60);
    if (diff < 24) {
      if (diff === 1) return "an hour ago";
      else return diff + " hours ago";
    }

    diff = parseInt(diff / 24);
    if (diff < 30) {
      if (diff === 1) return "a day ago";
      else return diff + " days ago";
    }

    diff = parseInt(diff / 30);
    if (diff === 1) return "a month ago";
    else return diff + " months ago";
  };
  render() {
    const { employee, notifications } = this.state;
    const employeeName = employee ? employee.personalInfo.name.first : "";
    const employeeImage =
      employee && employee.emergencyInfo && employee.emergencyInfo.image
        ? URLS.backendStatic + "/" + employee.emergencyInfo.image
        : URLS.landingPage + "/assets/img/profiles/avatar-02.jpg";
    return (
      <div className="header" style={{ background: "#020e28" }}>
        {/* <!-- Logo --> */}
        <div className="header-left">
          <Link to={`${URLS.dashboard}`} className="logo">
            <img
              src={URLS.landingPage + "/assets/img/logo-2.png"}
              alt=""
              width="150px"
              height="50px"
            />
          </Link>
        </div>
        {/* <!-- /Logo --> */}

        <a id="toggle_btn" href="javaScript:Void(0);">
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>

        {/* <!-- Header Title --> */}
        <div className="page-title-box">
          {/* <img
            style={{ height: "30px", width: "30px" }}
            src={URLS.landingPage + "/assets/img/imgpsh_fullsize_anim.png"}
            alt=""
          /> */}
          <h3 style={{ display: "inline" }}> Daedal HR</h3>
        </div>
        {/* <!-- /Header Title --> */}

        <a id="mobile_btn" className="mobile_btn" href="#sidebar">
          <i className="fa fa-bars"></i>
        </a>

        {/* <!-- Mobile Menu --> */}
        <div className="dropdown mobile-user-menu">
          <a
            href="javaScript:void(0)"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to={employee ? URLS.profil + "/" + employee._id : ""}
            >
              My Profile
            </Link>
            <a className="dropdown-item" href={`${URLS.settings}`}>
              Settings
            </a>
            <a className="dropdown-item" href={`${URLS.login}`}>
              Logout
            </a>
          </div>
        </div>
        {/* <!-- /Mobile Menu --> */}
      </div>
    );
  }
}

export default Topbar;
