import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class Inbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<h4 className="page-title">Inbox</h4>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="card-box mb-0">
								<div className="email-header">
									<div className="row">
										<div className="col top-action-left">
											<div className="float-left">
												<div className="btn-group dropdown-action">
													<button type="button" className="btn btn-white dropdown-toggle" data-toggle="dropdown">Select <i className="fa fa-angle-down "></i></button>
													<div className="dropdown-menu">
														<a className="dropdown-item" href="#">All</a>
														<a className="dropdown-item" href="#">None</a>
														<div className="dropdown-divider"></div> 
														<a className="dropdown-item" href="#">Read</a>
														<a className="dropdown-item" href="#">Unread</a>
													</div>
												</div>
												<div className="btn-group dropdown-action">
													<button type="button" className="btn btn-white dropdown-toggle" data-toggle="dropdown">Actions <i className="fa fa-angle-down "></i></button>
													<div className="dropdown-menu">
														<a className="dropdown-item" href="#">Reply</a>
														<a className="dropdown-item" href="#">Forward</a>
														<a className="dropdown-item" href="#">Archive</a>
														<div className="dropdown-divider"></div> 
														<a className="dropdown-item" href="#">Mark As Read</a>
														<a className="dropdown-item" href="#">Mark As Unread</a>
														<div className="dropdown-divider"></div> 
														<a className="dropdown-item" href="#">Delete</a>
													</div>
												</div>
												<div className="btn-group dropdown-action">
													<button type="button" className="btn btn-white dropdown-toggle" data-toggle="dropdown"><i className="fa fa-folder"></i> <i className="fa fa-angle-down"></i></button>
													<div role="menu" className="dropdown-menu">
														<a className="dropdown-item" href="#">Social</a>
														<a className="dropdown-item" href="#">Forums</a>
														<a className="dropdown-item" href="#">Updates</a>
														<div className="dropdown-divider"></div> 
														<a className="dropdown-item" href="#">Spam</a>
														<a className="dropdown-item" href="#">Trash</a>
														<div className="dropdown-divider"></div> 
														<a className="dropdown-item" href="#">New</a>
													</div>
												</div>
												<div className="btn-group dropdown-action">
													<button type="button" data-toggle="dropdown" className="btn btn-white dropdown-toggle"><i className="fa fa-tags"></i> <i className="fa fa-angle-down"></i></button>
													<div role="menu" className="dropdown-menu">
														<a className="dropdown-item" href="#">Work</a>
														<a className="dropdown-item" href="#">Family</a>
														<a className="dropdown-item" href="#">Social</a>
														<div className="dropdown-divider"></div> 
														<a className="dropdown-item" href="#">Primary</a>
														<a className="dropdown-item" href="#">Promotions</a>
														<a className="dropdown-item" href="#">Forums</a>
													</div>
												</div>
											</div>
											<div className="float-left d-none d-sm-block">
												<input type="text" placeholder="Search Messages" className="form-control search-message" />
											</div>
										</div>
										<div className="col-auto top-action-right">
											<div className="text-right">
												<button type="button" title="Refresh" data-toggle="tooltip" className="btn btn-white d-none d-md-inline-block"><i className="fa fa-refresh"></i></button>
												<div className="btn-group">
													<a className="btn btn-white"><i className="fa fa-angle-left"></i></a>
													<a className="btn btn-white"><i className="fa fa-angle-right"></i></a>
												</div>
											</div>
											<div className="text-right">
												<span className="text-muted d-none d-md-inline-block">Showing 10 of 112 </span>
											</div>
										</div>
									</div>
								</div>
								<div className="email-content">
									<div className="table-responsive">
										<table className="table table-inbox table-hover">
											<thead>
												<tr>
													<th colspan="6">
														<input type="checkbox" className="checkbox-all" />
													</th>
												</tr>
											</thead>
											<tbody>
												<tr className="unread clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star starred"></i></span></td>
													<td className="name">John Doe</td>
													<td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
													<td><i className="fa fa-paperclip"></i></td>
													<td className="mail-date">13:14</td>
												</tr>
												<tr className="unread clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">Envato Account</td>
													<td className="subject">Important account security update from Envato</td>
													<td></td>
													<td className="mail-date">8:42</td>
												</tr>
												<tr className="clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">Twitter</td>
													<td className="subject">HRMS Bootstrap Admin Template</td>
													<td></td>
													<td className="mail-date">30 Nov</td>
												</tr>
												<tr className="unread clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">Richard Parker</td>
													<td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
													<td></td>
													<td className="mail-date">18 Sep</td>
												</tr>
												<tr className="clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">John Smith</td>
													<td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
													<td></td>
													<td className="mail-date">21 Aug</td>
												</tr>
												<tr className="clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">me, Robert Smith (3)</td>
													<td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
													<td></td>
													<td className="mail-date">1 Aug</td>
												</tr>
												<tr className="unread clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">Codecanyon</td>
													<td className="subject">Welcome To Codecanyon</td>
													<td></td>
													<td className="mail-date">Jul 13</td>
												</tr>
												<tr className="clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">Richard Miles</td>
													<td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
													<td><i className="fa fa-paperclip"></i></td>
													<td className="mail-date">May 14</td>
												</tr>
												<tr className="unread clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star-o"></i></span></td>
													<td className="name">John Smith</td>
													<td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
													<td></td>
													<td className="mail-date">11/11/16</td>
												</tr>
												<tr className="clickable-row" data-href="mail-view.html">
													<td>
														<input type="checkbox" className="checkmail" />
													</td>
													<td><span className="mail-important"><i className="fa fa-star starred"></i></span></td>
													<td className="name">Mike Litorus</td>
													<td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
													<td></td>
													<td className="mail-date">10/31/16</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				
                </div>
				{/*<!-- /Page Content -->*/}
				
            </div>
			{/*<!-- /Page Wrapper -->*/}
			
        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Inbox))

