import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import { URLS } from '../urls';
import EditJobModal from '../jobs/EditJobModal';
class JobDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			job : {
                '_id' : '',
				'jobTitle' : '',
				'department' : '',
				'location' : '',
				'vacancies' : 0,
				'experience' : '',
				'salaryFrom' : '',
				'salaryTo' : '',
				'jobType' : 'Full Time',
				'status' : 'Open',
				'startDate' : '',
				'expiredDate' : '',
				'description' : ''
			},
			departments : []
        };
	}
	componentWillMount() {
		const id = window.location.pathname.split('/')[3];
		const token = 'dummy token'; // localStorage.getItem('token');
		fetch(`${URLS.backendJobs}/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					job: result.job
				})
		});
		fetch(`${URLS.backendDepartments}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
		}).then(res => res.json())
		.then((result) => {
			this.setState({
				departments: result.departments,
			});
		});
	}
	changeDateFormat = (date) =>{
		const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		date = date.split('/');
		return months[parseInt(date[1])-1] +' '+ date[0] + ', ' +date[2];
	}
	updateJobsList = (job) =>{
		this.setState({
			job
		})
	}

	render() {
		const {job,departments} = this.state;
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
			{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
					
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-sm-12 col-12">
							<Breadcrumb>
								<BreadcrumbItem><Link to={`${URLS.dashboard}`}>Dashboard</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${URLS.jobs}`}>Jobs</Link></BreadcrumbItem>
								<BreadcrumbItem active>Job Details</BreadcrumbItem>
							</Breadcrumb>
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className="row">
						<div className="col-md-8">
							<div className="job-info job-widget">
								<h3 className="job-title">{job.jobTitle}</h3>
								<span className="job-dept">{job.department}</span>
								<ul className="job-post-det">
									<li><i className="fa fa-calendar"></i> Post Date: <span className="text-blue">{this.changeDateFormat(job.startDate)}</span></li>
									<li><i className="fa fa-calendar"></i> Last Date: <span className="text-blue">{this.changeDateFormat(job.expiredDate)}</span></li>
									<li><i className="fa fa-user-o"></i> Applications: <span className="text-blue">4</span></li>
									<li><i className="fa fa-eye"></i> Views: <span className="text-blue">3806</span></li>
								</ul>
							</div>
							<div className="job-content job-widget">
								<div className="job-desc-title"><h4>Job Description</h4></div>
								<div className="job-description">
									<p>{job.description}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="job-det-info job-widget">
								<a className="btn job-btn" href="#" data-toggle="modal" data-target="#edit_job">Edit</a>
								<div className="info-list">
									<span><i className="fa fa-bar-chart"></i></span>
									<h5>Job Type</h5>
									<p>{job.jobType}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-money"></i></span>
									<h5>Salary</h5>
									<p>{job.salaryFrom} - {job.salaryTo}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-suitcase"></i></span>
									<h5>Experience</h5>
									<p>{job.experience}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-ticket"></i></span>
									<h5>Vacancy</h5>
									<p>{job.vacancies}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-map-signs"></i></span>
									<h5>Location</h5>
									<p>{job.location}</p>
								</div>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
				
				{/*<!-- Edit Job Modal -->*/}
				<EditJobModal departments={departments} editJob={job} updateJobsList={this.updateJobsList}/>
				{/*<!-- /Edit Job Modal -->*/}
				
            </div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(JobDetails))

