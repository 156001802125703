import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {URLS} from '../urls';
import {Helmet} from 'react-helmet';
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import Project from '../projects/Project';
import EditProjectModal from '../projects/EditProjectModal'
import AllTypeTasksClient from '../tasks/AllTypeTasksClient';
class ClientProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			_id : '',
			client : {
                companyName : '',
                companyEmail : '',
                companyPhone : '',
                contactPersonFirstName : '',
                contactPersonLastName : '',
                contactPersonEmail : '',
                contactPersonPhone : '',
			},
			projects :[],
			editProject : '',
			clientList : [],
			employeesList : [],
			projectTasks : []
        };
	}
	editProject = (_id) => {
		this.setState({ editProject: _id })
	};

	getEditProjectDataFromId = (id) => {
		const { projects } = this.state;
		return projects.find(item => id === item._id)
	};
	updateProjectsList = (project) => {
		let { projects } = this.state;
		const id = project && project._id;
		if(id){
			const token = 'dummy token';
			fetch(`${URLS.backendProjects}/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			})
				.then(res => res.json())
				.then((result) => {
					let isExists = false;
					for (let index = projects.length - 1; index >= 0; --index) {
						if (projects[index]._id === id) {
							projects.splice(index, 1);
							projects.splice(index, 0, result.project);
							isExists = true;
							break;
						}
					}
					if (isExists) {
						this.setState({ projects: projects });
					} else {
						this.setState({ projects: projects.concat(result.project) });
					}
				});
		}
	};
	
	componentWillMount() {
		const id = window.location.pathname.split('/')[3];
		const token = 'dummy token';

		fetch(`${URLS.backendClients}/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					_id: result.client._id,
					client: result.client
				});
			});

		fetch(`${URLS.backendProjects}/getProjectsByClientId/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					projects: result.projects
				});
			});
			fetch(`${URLS.backendEmployees}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					employeesList: result.employees
				});
			});

			fetch(`${URLS.backendClients}/get`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			}).then(res => res.json())
				.then((result) => {
					this.setState({
						clientList: result.clients,
					});
				});

			fetch(`${URLS.backendEmployees}/get`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			}).then(res => res.json())
				.then((result) => {
					this.setState({
						employeesList: result.employees
					});
				});

				fetch(`${URLS.backendTasks}/getByClientId/${id}`, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Token ${token}`,
					},
				}).then(res => res.json())
					.then((result) => {
						this.setState({
							projectTasks : result.tasks
						})
					});
		}

	render() {
		const { client,projects, editProject, employeesList, clientList,projectTasks } = this.state;
		const editProjectData = this.getEditProjectDataFromId(editProject);
		return (
            <body>
                <Helmet>
                    <meta charSet='utf-8' />
                    <meta name='robots' content='noindex, nofollow'></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className='main-wrapper'>
		
        	<Topbar />
        	<Sidebar />
			{/*<!-- Page Wrapper -->*/}
            <div className='page-wrapper'>
			
				{/*<!-- Page Content -->*/}
                <div className='content container-fluid'>
				
					{/*<!-- Page Title -->*/}
					<div className='row'>
						<div className='col-sm-12'>
							<Breadcrumb>
								<BreadcrumbItem><Link to={`${URLS.dashboard}`}>Dashboard</Link></BreadcrumbItem>
								<BreadcrumbItem><Link to={`${URLS.clients}`}>Clients</Link></BreadcrumbItem>
                            	<BreadcrumbItem active>My Profile</BreadcrumbItem>
                        	</Breadcrumb>
							{/* <h4 className='page-title'>My Profile</h4> */}
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className='card-box mb-0'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='profile-view'>
									<div className='profile-img-wrap'>
										<div className='profile-img'>
											<img src='/assets/img/profiles/avatar-19.jpg' alt='' />
										</div>
									</div>
									<div className='profile-basic'>
										<div className='row'>
											<div className='col-md-5'>
												<div className='profile-info-left'>
													<h3 className='user-name m-t-0'>{client.companyName}</h3>
													<h5 className='company-role m-t-0 mb-0'>{client.contactPersonFirstName+ ' '+client.contactPersonLastName}</h5>
													{/* <small className='text-muted'>CEO</small>
													<div className='staff-id'>Employee ID : CLT-0001</div> */}
													<div className='staff-msg'><a href='chat.html' className='btn btn-custom'>Send Message</a></div>
												</div>
											</div>
											<div className='col-md-7'>
												<ul className='personal-info'>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Company Phone:</div>
														<div className='text' style={{ flex: 1 }}><a href=''>{client.companyPhone}</a></div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Company Email:</div>
														<div className='text' style={{ flex: 1 }}><a href=''>{client.companyEmail}</a></div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title'style={{ flex: 1 }}>Contact Person Phone:</div>
														<div className='text' style={{ flex: 1 }}><a href=''>{client.contactPersonPhone}</a></div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Contact Person Email:</div>
														<div className='text' style={{ flex: 1 }}><a href=''>{client.contactPersonEmail}</a></div>
													</div>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='card-box tab-box'>
						<div className='row user-tabs'>
							<div className='col-lg-12 col-md-12 col-sm-12 line-tabs'>
								<ul className='nav nav-tabs nav-tabs-bottom'>
									<li className='nav-item col-sm-3'><a className='nav-link active' data-toggle='tab' href='#myprojects'>My Projects</a></li>
									<li className='nav-item col-sm-3'><a className='nav-link' data-toggle='tab' href='#tasks'>Tasks</a></li>
								</ul>
							</div>
						</div>
					</div>

                    <div className='row'>
                        <div className='col-lg-12'> 
							<div className='tab-content profile-tab-content'>
								
								{/*<!-- Projects Tab -->*/}
								<div id='myprojects' className='tab-pane fade show active'>
									<div className='row'>
									{projects.length ? projects.map((project) => {
									let projectTeam = [];
									let projectLeader = [];
									if(project.team && project.team.length){
										projectTeam = project.team.map(employee => {
											let name = employee.personalInfo.name.first;
											name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
											name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
											const id = employee._id;
											const image = employee.emergencyInfo.image;
											return {name: name, id: id, image: image};							
										})
									}
									if(project.projectLeader && project.projectLeader.length){
										projectLeader = project.projectLeader.map(employee => {
											let name = employee.personalInfo.name.first;
											name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
											name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
											const id = employee._id;
											const image = employee.emergencyInfo.image;
											return {name: name, id: id, image: image};
										})
									}
									return <Project key={project._id} project={project} projectTeamList={projectTeam} projectLeaderList={projectLeader} onEdit={this.editProject} />
								}):''}
									</div>
								</div>
								<EditProjectModal mode='edit' project={editProjectData} updateProjectsList={this.updateProjectsList} clientList={clientList} employeesList={employeesList}/>
								{/*<!-- /Projects Tab -->*/}
								
								{/*<!-- Task Tab -->*/}
								<div id='tasks' className='tab-pane fade'>
									<div className='project-task'>
										<ul className='nav nav-tabs nav-tabs-top nav-justified mb-0'>
											<li className='nav-item'><a className='nav-link active' href='#all_tasks' data-toggle='tab' aria-expanded='true'>All Tasks</a></li>
											<li className='nav-item'><a className='nav-link' href='#pending_tasks' data-toggle='tab' aria-expanded='false'>Pending Tasks</a></li>
											<li className='nav-item'><a className='nav-link' href='#completed_tasks' data-toggle='tab' aria-expanded='false'>Completed Tasks</a></li>
										</ul>
										<AllTypeTasksClient projectTasks={projectTasks}/>
									</div>
								</div>
								{/*<!-- /Task Tab -->*/}
								
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Page Content -->*/}
				
            </div>
        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className='sidebar-overlay' data-reff=''></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ClientProfile))

