import React from 'react';
import { URLS } from '../urls';
import moment from 'moment';
export default class AllTypeTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectTasks:[],
			inCompletedTask:[],
			underReviewedTask:[],
			completedTask:[],
			taskFilter : 'ALL'
        }
    }
    componentWillReceiveProps(props){
        if(props.projectTasks){
            const completedTask = props.projectTasks.filter(task => {
                return task.status === "COMPLETE"
			})
			const underReviewedTask = props.projectTasks.filter(task => {
                return task.status === "UNDER_REVIEW"
            })
			const inCompletedTask = props.projectTasks.filter(task => {
                return task.status === "INCOMPLETE"
            })
            this.setState({
                completedTask,
				inCompletedTask,
				underReviewedTask,
				projectTasks: props.projectTasks
            });
		}
		if(props.taskFilter){
			this.setState({
				taskFilter : props.taskFilter
			})
			
		}
    }
    changeStatusOfTask = (task) => (event) => {
		this.props.changeStatusOfTask(task);
    }
    changeSelectedTask = (task) => (evt) => {
		this.props.changeSelectedTask(task);
	}
	deleteTask = (task) => (evt) => {
		this.props.deleteTask(task);
	}

    render(){
        const {projectTasks,inCompletedTask,underReviewedTask,completedTask,taskFilter} = this.state;
        return (
            <ul id='task-list'>
			{
				taskFilter === 'ALL' ? projectTasks && projectTasks.map(projectTask => {
					return (
						<li className={projectTask.status === 'COMPLETE' ? 'completed task' : projectTask.status === 'UNDER_REVIEW' ? 'under-reviewed task ':'task'}>
							<div className='task-container' onClick={this.changeSelectedTask(projectTask)}>
								<span className='task-action-btn task-check'>
									<span className='action-circle large complete-btn' onClick={this.changeStatusOfTask(projectTask)} title={projectTask.status === 'COMPLETE' ? 'Mark Complete' : 'Mark Incomplete'} style={projectTask.status==='COMPLETE'?{pointerEvents:'none',cursor: 'default'}:{}}>
										<i className='material-icons'>check</i>
									</span>
								</span>
								<span className='task-label' >{projectTask.taskName}</span>
								<span className='task-action-btn task-btn-right'>
									{
										projectTask.status === 'COMPLETE' ?
											<span className='action-circle large' title='Assign'>
												<i className='material-icons'>person_add</i>
											</span>
										:
											<span className='action-circle large' title='Assign' data-toggle='modal' data-target='#assignee'>
												<i className='material-icons'>person_add</i>
											</span>
									}
									<span className='action-circle large delete-btn' title='Delete Task' onClick={this.deleteTask(projectTask)}>
										<i className='material-icons'>delete</i>
									</span>
								</span>
							</div>
						</li>
					);
				}) : taskFilter==='COMPLETE' ? completedTask && completedTask.map(projectTask => {
					return (
						<li className={projectTask.status === 'COMPLETE' ? 'completed task' : 'task'}>
							<div className='task-container' onClick={this.changeSelectedTask(projectTask)}>
								<span className='task-action-btn task-check'>
									<span className='action-circle large complete-btn' onClick={this.changeStatusOfTask(projectTask)} title={projectTask.status === 'COMPLETE' ? 'Mark Complete' : 'Mark Incomplete'} style={projectTask.status==='COMPLETE'?{pointerEvents:'none',cursor: 'default'}:{}}>
										<i className='material-icons'>check</i>
									</span>
								</span>
								<span className='task-label' >{projectTask.taskName}</span>
								<span className='task-action-btn task-btn-right'>
									<span className='action-circle large' title='Assign'>
										<i className='material-icons'>person_add</i>
									</span>
									<span className='action-circle large delete-btn' title='Delete Task' onClick={this.deleteTask(projectTask)}>
										<i className='material-icons'>delete</i>
									</span>
								</span>
							</div>
						</li>
					);
				}) : taskFilter==='UNDER_REVIEW' ? underReviewedTask && underReviewedTask.map(projectTask => {
					return (
						<li className='under-reviewed task'>
							<div className='task-container' onClick={this.changeSelectedTask(projectTask)}>
								<span className='task-action-btn task-check'>
									<span className='action-circle large complete-btn' onClick={this.changeStatusOfTask(projectTask)} title={projectTask.status === 'COMPLETE' ? 'Mark Complete' : 'Mark Incomplete'} style={projectTask.status==='COMPLETE'?{pointerEvents:'none',cursor: 'default'}:{}}>
										<i className='material-icons'>check</i>
									</span>
								</span>
								<span className='task-label' >{projectTask.taskName}</span>
								<span className='task-action-btn task-btn-right'>
									<span className='action-circle large' title='Assign' data-toggle='modal' data-target='#assignee'>
										<i className='material-icons'>person_add</i>
									</span>
									<span className='action-circle large delete-btn' title='Delete Task' onClick={this.deleteTask(projectTask)}>
										<i className='material-icons'>delete</i>
									</span>
								</span>
							</div>
						</li>
					);	
				}) : inCompletedTask && inCompletedTask.map(projectTask => {
					return (
						<li className={projectTask.status === 'COMPLETE' ? 'completed task' : 'task'}>
							<div className='task-container' onClick={this.changeSelectedTask(projectTask)}>
								<span className='task-action-btn task-check'>
									<span className='action-circle large complete-btn' onClick={this.changeStatusOfTask(projectTask)} title={projectTask.status === 'COMPLETE' ? 'Mark Complete' : 'Mark Incomplete'} style={projectTask.status==='COMPLETE'?{pointerEvents:'none',cursor: 'default'}:{}}>
										<i className='material-icons'>check</i>
									</span>
								</span>
								<span className='task-label' >{projectTask.taskName}</span>
								<span className='task-action-btn task-btn-right'>
									<span className='action-circle large' title='Assign' data-toggle='modal' data-target='#assignee'>
										<i className='material-icons'>person_add</i>
									</span>
									<span className='action-circle large delete-btn' title='Delete Task' onClick={this.deleteTask(projectTask)}>
										<i className='material-icons'>delete</i>
									</span>
								</span>
							</div>
						</li>
					);
				})
			}
			</ul>								
        )
    }
}