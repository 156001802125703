import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import { URLS } from '../urls';
import AddJobModal from './AddJobModal';
import EditJobModal from './EditJobModal';
var notificationTimeout;
class Jobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			jobs : [],
			departments : [],
			editJob : {
                '_id' : '',
				'jobTitle' : '',
				'department' : '',
				'location' : '',
				'vacancies' : 0,
				'applications' : 0,
				'views' : 0,
				'experience' : '',
				'salaryFrom' : '',
				'salaryTo' : '',
				'jobType' : 'Full Time',
				'status' : 'Open',
				'startDate' : '',
				'expiredDate' : '',
				'description' : ''
			},
			deleteJobId : null,
        };
	}
	changeDateFormat = (date) =>{
		const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		date = date.split('/');
		return date[0] + ' '+ months[parseInt(date[1])-1] + ' ' +date[2];
	}
	updateEditJob = (job) => (e) =>{
		this.setState({
			editJob : job
		})
	}
	updateDeleteJob = (j) => (e) =>{
		this.setState({
			deleteJobId : j._id
		})
	}
	updateJobsList = (job) => {
		let { jobs } = this.state;
		let isExist = false;
		const jobsTemp = jobs.map(j => {
			if(j._id === job._id){
				isExist = true;
				return job;
			}else
				return j;
		});
		if(isExist){
			this.setState({
				jobs : jobsTemp,
				editJob : {
					'_id' : '',
					'jobTitle' : '',
					'department' : '',
					'location' : '',
					'vacancies' : 0,
					'applications' : 0,
					'views' : 0,
					'experience' : '',
					'salaryFrom' : '',
					'salaryTo' : '',
					'jobType' : 'Full Time',
					'status' : 'Open',
					'startDate' : '',
					'expiredDate' : '',
					'description' : ''
				}
			})
		}else{
			this.setState({
				jobs : jobs.concat(job),
				editJob : {
					'_id' : '',
					'jobTitle' : '',
					'department' : '',
					'location' : '',
					'vacancies' : 0,
					'applications' : 0,
					'views' : 0,
					'experience' : '',
					'salaryFrom' : '',
					'salaryTo' : '',
					'jobType' : 'Full Time',
					'status' : 'Open',
					'startDate' : '',
					'expiredDate' : '',
					'description' : ''
				}
			})
		}
	}
	componentWillMount(){
		const token = 'dummy token';
		fetch(`${URLS.backendDepartments}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
		}).then(res => res.json())
		.then((result) => {
			this.setState({
				departments: result.departments,
			});
		});

		fetch(`${URLS.backendJobs}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
			}
		}).then(res => res.json())
		.then((result) => {
			console.log(result)
			this.setState({
                jobs : result.jobs
			});
		});
	}
	deleteJobConfirm = () =>{
		const {deleteJobId} = this.state;
		const token = 'dummy token';
		fetch(`${URLS.backendJobs}/delete/${deleteJobId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
            },
		}).then(res => res.json())
		.then((result) => {
			let notificationPopup = window.$('.notification-popup');
			notificationPopup.find('.task').text("Deleted Successfully");
			notificationPopup.find('.notification-text').text("");
			notificationPopup.removeClass('hide success');
			notificationPopup.addClass('success');
			if(notificationTimeout)
				clearTimeout(notificationTimeout);
			notificationTimeout = setTimeout(function(){
				notificationPopup.addClass('hide');
			}, 3000);
			
			const jobsTemp =  this.state.jobs.filter(jobItem =>{
				return jobItem._id !== result.job._id;
			});
			this.setState({
				jobs : jobsTemp,
				deleteJobId : null
			});

			window.$('#delete_job').modal('toggle');
		});
	}
	deleteJobCancel = () =>{
		this.setState({
			deleteJobId : null
		})
		window.$('#delete_job').modal('toggle');
	}

	render() {
		const {jobs,departments,editJob} = this.state;
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-md-9 col-sm-8">
							<Breadcrumb>
								<BreadcrumbItem><Link to={`${URLS.dashboard}`}>Dashboard</Link></BreadcrumbItem>
								<BreadcrumbItem active>Jobs</BreadcrumbItem>
							</Breadcrumb>
						</div>
						<div className="col-md-3 col-sm-4 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_job"><i className="fa fa-plus"></i> Add Job</a>
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table mb-0 datatable">
									<thead>
										<tr>
											<th>#</th>
											<th>Job Title</th>
											<th>Department</th>
											<th>Start Date</th>
											<th>Expire Date</th>
											<th className="text-center">Job Type</th>
											<th className="text-center">Status</th>
											<th>Applicants</th>
											<th className="text-right">Actions</th>
										</tr>
									</thead>
									<tbody>
										{
											jobs.length ? jobs.map((job,index) =>{
												return(
													<tr>
														<td>{index+1}</td>
														<td><Link to={`${URLS.jobDetail}/${job._id}`}>{job.jobTitle}</Link></td>
														<td>{job.department}</td>
														<td>{this.changeDateFormat(job.startDate)}</td>
														<td>{this.changeDateFormat(job.expiredDate)}</td>
														<td className="text-center">
															{
																job.jobType === 'Full Time' ? <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Full Time</a>
																	: (job.jobType === 'Part Time') ? <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Part Time</a>
																	: (job.jobType === 'Internship') ? <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Internship</a>
																	: (job.jobType === 'Temporary') ? <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-warning"></i> Temporary</a>
																	: (job.jobType === 'Remote') ? <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-warning"></i> Remote</a>
																	: <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-warning"></i> Other</a>
															}
														</td>
														<td className="text-center">
															{
																job.status === 'Open' ? <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Open</a>
																	: (job.status === 'Closed') ? <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Closed</a>
																	: <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Cancelled</a>
															}
														</td>
														<td><Link to={`${URLS.jobApplicants}/${job._id}`} className="btn btn-sm btn-primary">{job.applications} Candidates</Link></td>
														<td className="text-right">
															<div className="dropdown dropdown-action">
																<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
																<div className="dropdown-menu dropdown-menu-right">
																	<a href="#" className="dropdown-item" data-toggle="modal" data-target="#edit_job" onClick={this.updateEditJob(job)}><i className="fa fa-pencil m-r-5"></i> Edit</a>
																	<a href="#" className="dropdown-item" data-toggle="modal" data-target="#delete_job" onClick={this.updateDeleteJob(job)}><i className="fa fa-trash-o m-r-5"></i> Delete</a>
																</div>
															</div>
														</td>
													</tr>
												)
											}) : ''
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
				
				{/*<!-- Add Job Modal -->*/}
				<AddJobModal departments={departments} updateJobsList={this.updateJobsList}/>
				{/*<!-- /Add Job Modal -->*/}
				
				{/*<!-- Edit Job Modal -->*/}
				<EditJobModal departments={departments} editJob={editJob} updateJobsList={this.updateJobsList}/>
				{/*<!-- /Edit Job Modal -->*/}

				{/*<!-- Delete Job Modal -->*/}
				<div className="modal custom-modal fade" id="delete_job" role="dialog">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<div className="form-header">
									<h3>Delete Job</h3>
									<p>Are you sure want to delete?</p>
								</div>
								<div className="modal-btn delete-action">
									<div className="row">
										<div className="col-6">
											<a href="javascript:void(0);" className="btn btn-primary continue-btn" onClick={this.deleteJobConfirm.bind(this)}>Delete</a>
										</div>
										<div className="col-6">
											<a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn"onClick={this.deleteJobCancel.bind(this)}>Cancel</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Delete Job Modal -->*/}

				<div class="notification-popup hide">
					<p>
						<span class="task"></span>
						<span class="notification-text"></span>
					</p>
				</div>
            </div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Jobs))

