import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import { Form, Col, Row, Image } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import { URLS } from '../urls';

class Designations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			addDesignation : {
				name : '',
				department : ''
			},
			editDesignation : {
				_id : '',
				name : '',
				department : ''
			},
			departments : [],
			designations : []
        };
	}
	componentWillMount(){
		const token = 'dummy token';
		fetch(`${URLS.backendDepartments}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
		}).then(res => res.json())
		.then((result) => {
			this.setState({
				departments: result.departments,
			});
		});

		fetch(`${URLS.backendDesignations}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
		}).then(res => res.json())
		.then((result) => {
			this.setState({
				designations: result.designations,
			});
		});
	}

	updateAddDesignationName = (e) =>{
		const {addDesignation} = this.state;
		addDesignation.name = e.target.value;
		this.setState({
			addDesignation
		})
	}
	updateAddDesignationDepartment = (e) =>{
		const {addDesignation} = this.state;
		addDesignation.department = e.target.value;
		this.setState({
			addDesignation
		});
	}

	updateEditDesignation = (designation) => (e) =>{
		this.setState({
			editDesignation : designation
		})
	}

	updateEditDesignationName = (e) =>{
		const {editDesignation} = this.state;
		editDesignation.name = e.target.value;
		this.setState({
			editDesignation
		})
	}
	updateEditDesignationDepartment = (e) =>{
		const {editDesignation} = this.state;
		editDesignation.department = e.target.value;
		this.setState({
			editDesignation
		});
	}

	submitAddForm = (e) => {
		e.preventDefault();
		const token = 'dummy token';
		const {addDesignation} = this.state;
		fetch(`${URLS.backendDesignations}/add`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
			},
			body : JSON.stringify({designation : addDesignation })
		}).then(res => res.json())
		.then((result) => {
			this.setState({
				designations : this.state.designations.concat(result.designation),
				addDesignation : {
					name : '',
					department : ''
				}
			});
			window.$('#add_designation').modal('toggle');
		});
	}

	submitEditForm = (e) => {
		e.preventDefault();
		const token = 'dummy token';
		const {editDesignation} = this.state;
		fetch(`${URLS.backendDesignations}/update`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
			},
			body : JSON.stringify({designation : editDesignation })
		}).then(res => res.json())
		.then((result) => {
			const designationsTemp =  this.state.designations.map(designation =>{
				if(designation._id === result.designation._id){
					designation.name = result.designation.name;
					designation.department = result.designation.department;
					return designation;
				}else{
					return designation;
				}
			})
			this.setState({
				designations : designationsTemp,
				editDesignation : {
					_id : '',
					name : '',
					department : ''
				}
			});
			window.$('#edit_designation').modal('toggle');
		});
	}

	render() {
		const {designations,departments,addDesignation, editDesignation} = this.state;
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
					<div className="col-sm-12 col-12">
								<Breadcrumb>
									<BreadcrumbItem><Link to={`${URLS.dashboard}`}>Dashboard</Link></BreadcrumbItem>
                            		<BreadcrumbItem active>Designations</BreadcrumbItem>
                        		</Breadcrumb>
						</div>
						<div className="col-sm-5 col-5">
							
						</div>
						<div className="col-sm-7 col-7 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_designation"><i className="fa fa-plus"></i> Add Designation</a>
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table mb-0 datatable">
									<thead>
										<tr>
											<th style={{width: '80px'}}>#</th>
											<th>Designation </th>
											<th>Department </th>
											<th className="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										{
											designations.length && designations.map((designation,index) => {
												return(
													<tr>
														<td>{index+1}</td>
														<td>{designation.name}</td>
														<td>{designation.department}</td>
														<td className="text-right">
														<div className="dropdown dropdown-action">
																<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
															<div className="dropdown-menu dropdown-menu-right">
																<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_designation" onClick={this.updateEditDesignation(designation)}><i className="fa fa-pencil m-r-5"></i> Edit</a>
																<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_designation"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
															</div>
															</div>
														</td>
													</tr>
												);
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}

				{/*<!-- Add Designation Modal -->*/}
				<div id="add_designation" className="modal custom-modal fade" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add Designation</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={this.submitAddForm}>
									<div className="form-group">
										<label>Designation Name <span className="text-danger">*</span></label>
										<input className="form-control" type="text" value={addDesignation.name} onChange={this.updateAddDesignationName.bind(this)} required/>
									</div>
									<div className="form-group">
										<label>Department <span className="text-danger">*</span></label>
										<Form.Control as='select' value={addDesignation.department}
                                            onChange={this.updateAddDesignationDepartment.bind(this)} required>
                                            <option value=''>Select</option>
                                            {
												departments.length && departments.map(department =>{
													return <option value={department.name}>{department.name}</option>
												})
											}
                                        </Form.Control>
		
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Add Designation Modal -->*/}
				
				{/*<!-- Edit Designation Modal -->*/}
				<div id="edit_designation" className="modal custom-modal fade" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Designation</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={this.submitEditForm}>
									<div className="form-group">
										<label>Designation Name <span className="text-danger">*</span></label>
										<input className="form-control" type="text" value={editDesignation.name} onChange={this.updateEditDesignationName.bind(this)} required/>
									</div>
									<div className="form-group">
										<label>Department <span className="text-danger">*</span></label>
										<Form.Control as='select' value={editDesignation.department}
                                            onChange={this.updateEditDesignationDepartment.bind(this)} required>
                                            <option value=''>Select</option>
                                            {
												departments.length && departments.map(department =>{
													return <option value={department.name}>{department.name}</option>
												})
											}
                                        </Form.Control>
		
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Edit Designation Modal -->*/}
				
				{/*<!-- Delete Designation Modal -->*/}
				<div className="modal custom-modal fade" id="delete_designation" role="dialog">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<div className="form-header">
									<h3>Delete Designation</h3>
									<p>Are you sure want to delete?</p>
								</div>
								<div className="modal-btn delete-action">
									<div className="row">
										<div className="col-6">
											<a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a>
										</div>
										<div className="col-6">
											<a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Delete Designation Modal -->*/}
			
            </div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Designations))

