import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import TopbarGuest from '../topbar/TopbarGuest';
import { URLS } from '../urls';
import moment from 'moment';
import '../../assets/css/style.css';
var  notificationTimeout;
class JobView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			job : {
                '_id' : '',
				'jobTitle' : '',
				'department' : '',
				'location' : '',
				'vacancies' : 0,
				'applications' : 0,
				'views' : 0,
				'experience' : '',
				'salaryFrom' : '',
				'salaryTo' : '',
				'jobType' : 'Full Time',
				'status' : 'Open',
				'startDate' : '',
				'expiredDate' : '',
				'description' : ''
			},
			application :{
				jobId : '',
				name : '',
				email : '',
				phone : '',
				message : '',
				applyDate : '',
				status : 'New',
				resume : {
					original : '',
					unique : '',
					ext : ''
				}
			},
			applicationFile : null
        };
    }
	componentWillMount() {
		const id = window.location.pathname.split('/')[2];
		const token = 'dummy token'; // localStorage.getItem('token');
		fetch(`${URLS.backendJobs}/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					job: result.job
				})
		});
	}
	changeDateFormat = (date) =>{
		const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		date = date.split('/');
		return months[parseInt(date[1])-1] +' '+ date[0] + ', ' +date[2];
	}
	updateApplicationText = (event) =>{
		const {application} = this.state;
		application[event.target.name] = event.target.value;
		this.setState({
			application
		})
	}
	getDateDiff = (date) =>{
		let lastDate = moment(date,'DD/MM/YYYY');
		let today = moment(new Date(),'DD/MM/YYYY');
		let days = lastDate.diff(today,'days');
		if(days===0)
			return 'Application ends Today';
		else if(days===1)
			return 'Application ends Tomorrow';
		else
			return 'Application ends in ' + days + ' days';
	}

	updateApplicationFile = (event) =>{
		const {application} = this.state;
		const file = event.target.files[0];
		const name = file.name;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
		application.resume.original = fileName;
		application.resume.ext = ext;
		this.setState({
			application,
			applicationFile : file
		})
	}

	submitApplication = (e) => {
		e.preventDefault();
		const token = 'dummy token';
		const {job,application,applicationFile} = this.state;
		application.applyDate = moment(new Date()).format("DD/MM/YYYY");
		application.jobId = job._id;
		application.resume.unique = job._id+'@'+application.email+'.'+application.resume.ext;
		let formData = new FormData();
		formData.append('application', JSON.stringify({ ...application }));
		formData.append('file', applicationFile, application.resume.unique);
		console.log('formData',formData);
		fetch(`${URLS.backendApplications}/add`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
			},
			body : formData
		}).then(res => res.json())
		.then((result) => {
			if(result.success){
				let notificationPopup = window.$('.notification-popup');
				notificationPopup.find('.task').text("Your Application");
				notificationPopup.find('.notification-text').text("Submitted Successfully");
				notificationPopup.removeClass('hide success');
				notificationPopup.addClass('success');
				if(notificationTimeout)
					clearTimeout(notificationTimeout);
				notificationTimeout = setTimeout(function(){
					notificationPopup.addClass('hide');
				}, 3000);
				job.applications = job.applications + 1;
				this.setState({
					job
				});
			}else{
				let notificationPopup = window.$('.notification-popup');
				notificationPopup.find('.task').text("Your Application");
				notificationPopup.find('.notification-text').text("You have already Submitted");
				notificationPopup.removeClass('hide success');
				if(notificationTimeout)
					clearTimeout(notificationTimeout);
				notificationTimeout = setTimeout(function(){
					notificationPopup.addClass('hide');
				}, 3000);
			}	
			window.$('#apply_job').modal('toggle');
		});
	}
	render() {
		const {job,application,applicationFile} = this.state;
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<TopbarGuest />
			{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper job-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container">
					<div className="row">
						<div className="col-md-8">
							<div className="job-info job-widget">
								<h3 className="job-title">{job.jobTitle}</h3>
								<span className="job-dept">{job.department}</span>
								<ul className="job-post-det">
									<li><i className="fa fa-calendar"></i> Post Date: <span className="text-blue">{this.changeDateFormat(job.startDate)}</span></li>
									<li><i className="fa fa-calendar"></i> Last Date: <span className="text-blue">{this.changeDateFormat(job.expiredDate)}</span></li>
									<li><i className="fa fa-user-o"></i> Applications: <span className="text-blue">{job.applications}</span></li>
									<li><i className="fa fa-eye"></i> Views: <span className="text-blue">{job.views}</span></li>
								</ul>
							</div>
							<div className="job-content job-widget">
								<div className="job-desc-title"><h4>Job Description</h4></div>
								<div className="job-description">
									<p>{job.description}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="job-det-info job-widget">
								<a className="btn job-btn" href="#" data-toggle="modal" data-target="#apply_job">Apply For This Job</a>
								<div className="info-list">
									<span><i className="fa fa-bar-chart"></i></span>
									<h5>Job Type</h5>
									<p>{job.jobType}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-money"></i></span>
									<h5>Salary</h5>
									<p>{job.salaryFrom} - {job.salaryTo}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-suitcase"></i></span>
									<h5>Experience</h5>
									<p>{job.experience}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-ticket"></i></span>
									<h5>Vacancy</h5>
									<p>{job.vacancies}</p>
								</div>
								<div className="info-list">
									<span><i className="fa fa-map-signs"></i></span>
									<h5>Location</h5>
									<p>{job.location}</p>
								</div>
								<div className="info-list text-center">
									<p className="app-ends">{this.getDateDiff(job.expiredDate)}</p>
								</div>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
				
				{/*<!-- Apply Job Modal -->*/}
				<div className="modal custom-modal fade" id="apply_job" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add Your Details</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={this.submitApplication}>
									<div className="form-group">
										<label>Name</label>
										<input className="form-control" type="text" name= 'name' value={application.name} onChange={this.updateApplicationText.bind(this)} required/>
									</div>
									<div className="form-group">
										<label>Phone</label>
										<input className="form-control" type="text" name= 'phone' value={application.phone} onChange={this.updateApplicationText.bind(this)} required/>
									</div>
									<div className="form-group">
										<label>Email Address</label>
										<input className="form-control" type="email" name= 'email' value={application.email} onChange={this.updateApplicationText.bind(this)} required/>
									</div>
									<div className="form-group">
										<label>Message</label>
										<textarea className="form-control" name= 'message' value={application.message} onChange={this.updateApplicationText.bind(this)} required></textarea>
									</div>
									<div className="form-group">
										<label>Upload your CV</label>
										<div className="custom-file">
											<input type="file" className="custom-file-input" id="cv_upload" onChange={this.updateApplicationFile.bind(this)}/>
											<label className="custom-file-label" for="cv_upload">Choose file</label>
										</div>
										<div className="custom-file">
										{
				 							applicationFile && <p style={{margin:'10px'}}>{applicationFile.name}</p>
										}
											
										</div>
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Apply Job Modal -->*/}
			
            </div>
			<div class="notification-popup hide">
				<p>
					<span class="task"></span>
					<span class="notification-text"></span>
				</p>
			</div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(JobView))

