/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Form, Col, Row, Image } from 'react-bootstrap';
import { pdfjs, Document, Page } from 'react-pdf';
import Datepicker from 'react-datetime'

import '../../assets/css/project-files.css';
import '../../../src/assets/css/date-picker.css';
import '../../../src/assets/css/image-list.css';

import { URLS } from '../urls';
import Multiselect from './Multiselect'
import ImageNameList from './ImageNameList';
import moment from 'moment';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class ProjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.removeFormList = this.removeFormList.bind(this);
        this.state = {
            buttonText : 'Submit',
            isWaiting : false,
            oldProject : null,
            employee : null,
            project: {
                name: '',
                client: null,
                startDate: '',
                endDate: '',
                cost: 0,
                duration: 0,
                durationType: '',
                priority: 'High',
                projectLeader: [],
                team: [],
                description: '',
                files:[],
                teamId : null,
                status : 'Created',
                createdBy : null
            },
            projectTeamList : [],
            projectLeaderList : [],
            clientList: [],
            employeeProjectTeam : [],
            employeeProjectLeader :[],
            employeeProjectMember :[],
            teamTemplates :[],
        };
    }

    updateProjectName = event => {
        const { project } = this.state;
        project.name = event.target.value;
        this.setState({
            project
        });
    }

    updateClient = event => {
        const { project } = this.state;
        project.client = event.target.value;
        this.setState({
            project
        });
    }

    updateStartDate = event => {
        const { project } = this.state;
        project.startDate = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");
        this.setState({
            project
        });
    }

    updateEndDate = event => {
        const { project } = this.state;
        project.endDate = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");
        this.setState({
            project
        });
    }

    updateCost = event => {
        const { project } = this.state;
        project.cost = event.target.value;
        this.setState({
            project
        });
    }
    updateDuration = event => {
        const { project } = this.state;
        project.duration = event.target.value;
        this.setState({
            project
        });
    }

    updateDurationType = event => {
        const { project } = this.state;
        project.durationType = event.target.value;
        this.setState({
            project
        });
    }
    updateStatus = event => {
        const { project } = this.state;
        project.status = event.target.value;
        this.setState({
            project
        });
    }

    updatePriority = event => {
        const { project } = this.state;
        project.priority = event.target.value;
        this.setState({
            project
        });
    }
    updateTeamTemplateId = event => {
        const { project,teamTemplates, employeeProjectMember} = this.state;
        project.teamId = event.target.value;
        const teamTemplate = teamTemplates.find(tT => tT._id === project.teamId)
        const projectTeamList = teamTemplate.members;
        const projectLeaderList = teamTemplate.leaders;
        project.projectLeader = projectLeaderList.map(m => m.id)
        project.team = projectTeamList.map(m => m.id)
        const employeeProjectTeam = employeeProjectMember.filter(ar => !projectLeaderList.find(rm => (rm.id === ar.id) ))
        const employeeProjectLeader = employeeProjectMember.filter(ar => !projectTeamList.find(rm => (rm.id === ar.id) ))
        this.setState({
            project,
            projectTeamList,
            projectLeaderList,
            employeeProjectTeam,
            employeeProjectLeader
        });
    }

    updateProjectLeader = (params) => {
        const { project,employeeProjectMember } = this.state;
        project.projectLeader = params.map(item => item.id)
        const employeeProjectTeam = employeeProjectMember.filter(ar => !params.find(rm => (rm.id === ar.id) ))
        this.setState({
            project,
            projectLeaderList : params,
            employeeProjectTeam : employeeProjectTeam
        })
        // const {employeeProjectTeam,projectLeaderList,employeeProjectLeader,projectTeamList} = this.state;
        // this.setState({
        //     employeeProjectTeam : employeeProjectTeam.filter(ar => !projectLeaderList.find(rm => (rm.id === ar.id) )),
        //     employeeProjectLeader : employeeProjectLeader.filter(ar => !projectTeamList.find(rm => (rm.id === ar.id) ))
        // })
    }

    updateTeam = (params) => {
        const { project,employeeProjectMember } = this.state;
        project.team = params.map(item => item.id);
        const employeeProjectLeader = employeeProjectMember.filter(ar => !params.find(rm => (rm.id === ar.id) ))
        this.setState({
                project,
                projectTeamList : params,
                employeeProjectLeader : employeeProjectLeader
            })
        
        // const {employeeProjectTeam,projectLeaderList,employeeProjectLeader,projectTeamList} = this.state;
        // this.setState({
        //     employeeProjectTeam : employeeProjectTeam.filter(ar => !projectLeaderList.find(rm => (rm.id === ar.id) )),
        //     employeeProjectLeader : employeeProjectLeader.filter(ar => !projectTeamList.find(rm => (rm.id === ar.id) ))
        // })
    }

    updateDescription = event => {
        const { project } = this.state;
        project.description = event.target.value;
        this.setState({
            project
        });
    }


    // updateProjectFile = (event) => {
    //     const { project } = this.state;
    //     let currentDate = new Date();
    //     currentDate = currentDate.getTime();
    //     project.file = currentDate + '.pdf';
    //     this.setState({
    //         project
    //     });
    //     this.setState({
    //         displayFileSrc: event.target.files[0]
    //     });
    // };
    updateProjectFile = (event) => {
        const { project } = this.state;
        let  fileInput = document.querySelector("#projectfilesEdit");
        let files = fileInput.files;
        let fl = files.length;
        let i = 0;
        while ( i < fl) {
            const projectFile = {
                originalName: '',
                uniqueName : '',
                extension : '',
                size : ''
            }
            const file = files[i];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const fileName = name.substring(0, lastDot);
            const ext = name.substring(lastDot + 1);
            const size = file.size;
            let currentDate = new Date();
            currentDate = currentDate.getTime();
            projectFile.originalName = fileName;
            projectFile.uniqueName = currentDate;
            projectFile.extension = ext;
            if(size < 1024){
                projectFile.size = size+' B';
            }else if(size >= 1024 && size < 1024*1024){
                projectFile.size = (size / 1024).toFixed(2)+' KB';
            }else{
                projectFile.size = (size / 1024 / 1024 ).toFixed(2)+' MB';
            }
            
            projectFile.file = file;
            project.files = project.files.concat(projectFile);
            this.setState({
                project,
            })
            i++;
        } 
    };
    removeProjectFile = (idx) => (evt) =>{
        const { project } = this.state;
        project.files.splice(idx,1);
        this.setState({
            project,
        })
    }
    componentWillReceiveProps(props) {
        if (props.project && props.project.client){
            const projectTeamList = props.project.team.map(employee => {
                let name = employee.personalInfo.name.first;
                name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
                name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
                const id = employee._id;
                const image = employee.emergencyInfo.image;
                return {name: name, id: id, image: image};
            })
            const projectLeaderList = props.project.projectLeader.map(employee => {
                let name = employee.personalInfo.name.first;
                name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
                name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
                const id = employee._id;
                const image = employee.emergencyInfo.image;
                return {name: name, id: id, image: image};
            })
            this.setState({
                project: props.project,
                projectTeamList : projectTeamList,
                projectLeaderList : projectLeaderList,
            })
            if(this.state.oldProject === null)
                this.fetchProject(props.project._id);
            
        }
        if (props.clientList){
            this.setState({
                clientList : props.clientList,
            })
        }
        if (props.employeesList){
            const {projectLeaderList,projectTeamList} = this.state;
            const employees = props.employeesList.map(employee => {
                let name = employee.personalInfo.name.first;
                name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
                name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
                const id = employee._id;
                const image = employee.emergencyInfo.image;
                return {name: name, id: id, image: image};
            })
            this.setState({
                employeeProjectTeam : employees.filter(ar => !projectLeaderList.find(rm => (rm.id === ar.id) )),
                employeeProjectLeader : employees.filter(ar => !projectTeamList.find(rm => (rm.id === ar.id) )),
                employeeProjectMember : employees
            })
        }
    }
    fetchProject = (id) =>{
        const token = 'dummy token'
        fetch(`${URLS.backendProjects}/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			if(result.project){
                const oldProject = {};
                oldProject.projectLeader = result.project.projectLeader.map(emp => emp._id);
                oldProject.team = result.project.team.map(emp => emp._id);
                this.setState({oldProject})
            }
        });
    }
    componentWillMount(){
        const token = 'dummy token'
        fetch(`${URLS.backendTeams}/get`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			if(result.teams)
				this.setState({
					teamTemplates: result.teams,
				});
        });
        const id = localStorage.getItem('userId');
		fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({
				employee : result.employee
			})
		});
    }

    submit = (e) => {
        e.preventDefault();
        this.setState({buttonText:'Submitting ...',isWaiting:true})
        const { project } = this.state;
        if (project.createdBy && project.createdBy._id){
            project.createdBy = project.createdBy._id;
        }
        if (project.status === undefined){
            project.status = 'Working';
        }
        let formData = new FormData();
        formData.append('project', JSON.stringify({ ...project }));
        for(let fileIdx in project.files){
            const fileInfo = project.files[fileIdx];
            if(fileInfo.file)
                formData.append('file', fileInfo.file?fileInfo.file:null, fileInfo.uniqueName+'.'+fileInfo.extension);
        }

        const token = 'dummy token'; // localStorage.getItem('token');
        fetch(`${URLS.backendProjects}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
            },
            body: formData,
        })
            .then(res => res.json())
            .then((result) => {
                setTimeout(()=>{
                    window.$("#edit_project").modal("toggle");
                    this.setState({ buttonText: 'Submit', isWaiting: false });
                }, 500);
                this.props.updateProjectsList(result.project);
                this.generateProjectNotification(result.project);
            });
    };
    removeFormList = item =>{
        const {project, projectTeamList, projectLeaderList, employeeProjectMember } = this.state;
        let index1 = projectTeamList.indexOf(item);
        let index2 = projectLeaderList.indexOf(item);
        if ( index1 > -1){
            projectTeamList.splice(index1, 1);
            project.team = projectTeamList.map(i => i.id);
        }
        // else if(index1 === 0){
        //     projectTeamList.shift();
        //     project.team = projectTeamList.map(i => i.id);
        // }
        else if ( index2 > -1){
            projectLeaderList.splice(index2, 1);
            project.projectLeader = projectLeaderList.map(i => i.id);
        }
        // else if ( index2 === 0){
        //     projectLeaderList.shift();
        //     project.projectLeader = projectLeaderList.map(i => i.id);
        // }
        this.setState({
            project : project,
            projectTeamList : projectTeamList,
            projectLeaderList : projectLeaderList,
            employeeProjectTeam : employeeProjectMember.filter(ar => !projectLeaderList.find(rm => (rm.id === ar.id) )),
            employeeProjectLeader : employeeProjectMember.filter(ar => !projectTeamList.find(rm => (rm.id === ar.id) ))
        })
    }
    validationStartDate = (currentDate) => {
        return currentDate.isAfter(moment(new Date()).add(-1,'days'));
        
    };
	validationEndDate = (currentDate) => {
		const {project} = this.state;
        return currentDate.isAfter(moment(project.startDate,"DD/MM/YYYY").add(-1,'days')); 
    };

    generateProjectNotification = (project) =>{
        const {employee, oldProject} = this.state;
       
        project.projectLeader = project.projectLeader.map(emp => emp._id);
        project.team = project.team.map(emp => emp._id);
		const employeeName = employee.personalInfo.name.first +' '+ employee.personalInfo.name.last;
		if(employee != null){
			const notification = {}
			notification.employeeFrom = employee._id;
			notification.target =  'project-view/'+project._id;
            notification.createDate = new Date();

            project.projectLeader.forEach(emp => {
               if(oldProject.projectLeader.indexOf(emp) === -1){
                    notification.employeeTo = emp;
                    notification.tag = 'ADDED_LEADER_PROJECT';
                    notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> added you as team leader to project <span class="noti-title"><b>'+project.name+'</b></span>';		
                    this.submitNotificationOnServer(notification);
                }
            });

            project.team.map(emp => {
                if(oldProject.team.indexOf(emp) === -1){
                    notification.employeeTo = emp;
                    notification.tag = 'ADDED_MEMBER_PROJECT';
                    notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> added you as team member to project <span class="noti-title"><b>'+project.name+'</b></span>';		
                    this.submitNotificationOnServer(notification);
                }
            });

           oldProject.projectLeader.map(emp => {
                if(project.projectLeader.indexOf(emp) === -1){
                    notification.employeeTo = emp;
                    notification.target =  'projects';
                    notification.tag = 'REMOVED_LEADER_PROJECT';
                    notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> removed you from project <span class="noti-title"><b>'+project.name+'</b></span>';		
                    this.submitNotificationOnServer(notification);
                }
            });

            oldProject.team.map(emp => {
                if(project.team.indexOf(emp) === -1){
                    notification.employeeTo = emp;
                    notification.target =  'projects';
                    notification.tag = 'REMOVED_MEMBER_PROJECT';
                    notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> removed you from project <span class="noti-title"><b>'+project.name+'</b></span>';		
                    this.submitNotificationOnServer(notification);
                }
            });
            this.fetchProject(project._id);
		}
	}

    submitNotificationOnServer = (notification) =>{
		const token = 'dummy token';
		fetch(`${URLS.backendNotifications}/addTaskNotification`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
			body : JSON.stringify({notification : notification})
		}).then(res => res.json()).then((result) => {
			if(result.notification){
				console.log("Notification created successfully", result.notification);
			}
		});
	}

    render() {
        
        const { project, clientList, employeeProjectTeam,employeeProjectLeader, projectTeamList, projectLeaderList, teamTemplates } = this.state;
        return (
            <div id='edit_project' className='modal custom-modal fade' role='dialog'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Project</h5>
                            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <Form onSubmit={this.submit}>
                                <Form.Group as={Row}>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Project Name
                                            </Form.Label>
                                        <Form.Control placeholder='Project name' value={project.name}
                                            onChange={this.updateProjectName} />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Client
                                            </Form.Label>`
                                            <Form.Control as='select' value={project.client}
                                            onChange={this.updateClient} >
                                            <option >Select</option>
                                            <option disabled>__________________</option>
                                            {
                                                clientList.map(client => {
                                                    return <option value={client._id}>{client.companyName}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Start Date
                                            </Form.Label>
                                        <div classname='datePicker'>
                                            <i className="fa fa-calendar fa-xs calendar-icon-3" aria-hidden="true"></i>
                                            <Datepicker value={project.startDate} 
                                                closeOnSelect={true}
                                                onChange={this.updateStartDate}
                                                isValidDate={this.validationStartDate} />
                                        </div>

                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }} >
                                            End Date
                                            </Form.Label>
                                        <div classname='datePicker'>
                                            <i className="fa fa-calendar fa-xs calendar-icon-3" aria-hidden="true"></i>
                                            <Datepicker value={project.endDate} 
                                                closeOnSelect={true}
                                                onChange={this.updateEndDate} 
                                                isValidDate={this.validationEndDate} />
                                        </div>

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={9}>
                                        {/* <Form.Label style={{ fontSize: '14px' }}>
                                            Cost
                                            </Form.Label> */}
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label style={{ fontSize: '14px' }} >
                                                    Cost (in USD)
                                                </Form.Label>
                                                <Form.Control value={project.cost}
                                                    placeholder="$"
                                                    onChange={this.updateCost} />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label style={{ fontSize: '14px' }} >
                                                    Duration
                                                </Form.Label>
                                                <Form.Control value={project.duration}
                                                    onChange={this.updateDuration} />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label style={{ fontSize: '14px' }} >
                                                    Duration Unit
                                                </Form.Label>
                                                <Form.Control as='select' value={project.durationType}
                                                    onChange={this.updateDurationType}>
                                                    <option value=''>Select Unit</option>
                                                    <option value='Hours'>Hours</option>
                                                    <option value='Days'>Days</option>
                                                    <option value='Months'>Months</option>
                                                    <option value='Years'>Years</option>
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Label style={{ fontSize: '14px' }} >
                                            Priority
                                            </Form.Label>
                                        <Form.Control as='select' value={project.priority}
                                            onChange={this.updatePriority}>
                                            <option value='High'>High</option>
                                            <option value='Medium'>Medium</option>
                                            <option value='Low'>Low</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }} >
                                            Assign a Team to Project
                                            </Form.Label>
                                        <Form.Control as='select' value={project.teamId}
                                            onChange={this.updateTeamTemplateId}>
                                                <option> Select Team </option>
                                            { 
                                                teamTemplates.length > 0 && teamTemplates.map(teamTemplate =>{
                                                    return <option value={teamTemplate._id}>{teamTemplate.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }} >
                                            Project Status
                                            </Form.Label>
                                        <Form.Control as='select' value={project.status}
                                            onChange={this.updateStatus}>
                                            <option value='Created'>Created</option>
                                            <option value='Working'>Working</option>
                                            <option value='Completed'>Completed</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Add Project Leader
                                            </Form.Label>
                                            <br />
                                           {
                                                employeeProjectLeader.length && <Multiselect options={ employeeProjectLeader.filter(ar => !projectTeamList.find(rm => (rm.id === ar.id) ))} 
                                                onSelectOptions={this.updateProjectLeader} selectedOptions={projectLeaderList.map(item => item.id)} />
                                            }
                                        <br />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Team Leaders
                                            </Form.Label>
                                            <br />
                                            <ImageNameList value={projectLeaderList} removeFormList={this.removeFormList}/>
                                            <br />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Add Team
                                            </Form.Label>
                                            <br />
                                           {
                                                employeeProjectTeam.length && <Multiselect options={employeeProjectTeam.filter(ar => !projectLeaderList.find(rm => (rm.id === ar.id) ))}
                                                onSelectOptions={this.updateTeam} selectedOptions={
                                                    projectTeamList.map(item => item.id)
                                                } />
                                            }
                                        <br />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Team Members
                                            </Form.Label>
                                            <br />
                                            <ImageNameList value={projectTeamList} removeFormList={this.removeFormList} />
                                            <br />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={12}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Description
                                            </Form.Label>
                                        <textarea rows="10" className="form-control" onChange={this.updateDescription} value={project.description}></textarea>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={12}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Upload Files
                                            </Form.Label>
                                        <div>{project.files.length ?
                                            <div className='project-file-preview'>
                                                { project.files.map((file,index)=>{
                                                    return (<div className="file-preview-frame krajee-default  kv-preview-thumb" id="thumb-input-ficons-4-817_ttt.html" data-fileindex="817_ttt.html" data-fileid="817_ttt.html" data-template="other" title="ttt.html">
                                                        <button type="button" class="close fileinput-remove" aria-label="Close" onClick={this.removeProjectFile(index)}>
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                        <div className="kv-file-content">
                                                            <div className="kv-preview-data file-preview-other-frame" style={{width:'100px',height:'100px'}}>
                                                                <i className="fa fa-file-o" style={{fontSize:'60px'}}/>
                                                            </div>
                                                        </div>
                                                        <div className="file-thumbnail-footer">
                                                            <div className="file-footer-caption" title={file.originalName+'.'+file.extension}>
                                                                <div className="file-caption-info">{file.originalName+'.'+file.extension}</div>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </div>)
                                                }) }
                                               
                                            </div>: null}
                                            <div className='project-upload-btn-wrapper' style={{height:'136px', margin:'8px'}}>
                                                <button className='project-custom-upload-btn' style={{height:'136px'}}>Upload File</button>
                                                <input id="projectfilesEdit" type='file' multiple label='Upload File'
                                                    onChange={this.updateProjectFile} />
                                            </div>
                                        </div>
                                    </Col>
                                </Form.Group>
                                <div className='submit-section'>
                                    <button className='btn btn-primary submit-btn' >{this.state.buttonText}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}