import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Form, Col, Row, Image, textarea, Popover } from 'react-bootstrap';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap'
import { Helmet } from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import AddEmployeeModal from './AddEmployeeModal';
import EditEmployeeModal from './EditEmployeeModal';
import Employee from './Employee';
import { URLS } from "../urls";
import PieChartModel from './PieChartModel';

class Employees extends React.Component {
	constructor(props) {
		super(props);
		this.updateEmployeesList = this.updateEmployeesList.bind(this);
		this.state = {
			employees: [],//update this whenever you add/update employee in backend.
			editEmployee: '',// id or index of the employee which is to be edited
			searchFilter : {
				id : '',
				name : '',
				department : ''
			},
			searchEmlpoyeeList : [],
			departments : [],
			employeesStatistics : {
				'confirmed' : 0,
				'trainee' : 0,
				'probation' : 0,
				'internship' : 0,
				'gender' : {
					'Male' : 0,
					'Female' : 0,
					'Others' : 0
				}
			}
		};
	}

	editEmployee = (_id) => {
		console.log('EDIT EMPLOYEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE')
		this.setState({ editEmployee: _id })
	};

	getEmployeeFromId = (_id) => {
		const { employees } = this.state;
		let employee = {};
		employees.forEach((e) => {
			if (e._id === _id) employee = e;
		});
		return employee;
	};
	getEditEmployeeDataFromId = (id) => {
		const { employees } = this.state;
		let employee = {};
		employees.forEach((e) => {
			if (e && e._id === id) {
				employee = e;
				employee.jobInfo.grade = employee.jobInfo.grade && employee.jobInfo.grade._id;
				employee.jobInfo.workLocation = employee.jobInfo.workLocation && employee.jobInfo.workLocation._id;
				employee.jobInfo.department = employee.jobInfo.department && employee.jobInfo.department._id;
			}
		});
		return employee;
	};
	updateEmployeesList = (employee) => {
		let { employees } = this.state;
		const id = employee && employee._id;
		let isExists = false;
		for (let index = employees.length - 1; index >= 0; --index) {
			if (employees[index]._id === id) {
				employees.splice(index, 1);
				employees.splice(index, 0, employee);
				isExists = true;
				break;
			}
		}
		if (isExists) {
			this.setState({ employees: employees });
		} else {
			this.setState({ employees: employees.concat(employee) });
		}
	};
	componentWillMount() {
		const {employeesStatistics} = this.state;
		const token = 'dummy token';
		fetch(`${URLS.backendEmployees}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					employees: result.employees,
					searchEmlpoyeeList : result.employees
				});
				if(result.employees && result.employees.length){

					result.employees.forEach(employee => {
						if(employee.jobInfo.status === 'Confirmed')
							employeesStatistics.confirmed += 1
						else if(employee.jobInfo.status === 'Trainee')
							employeesStatistics.trainee += 1
						else if(employee.jobInfo.status === 'Probation')
							employeesStatistics.probation += 1
						else if(employee.jobInfo.status === 'Internship')
							employeesStatistics.internship += 1
						

						if(employee.personalInfo.gender)
							employeesStatistics.gender[employee.personalInfo.gender] += 1
					});
					this.setState({
						employeesStatistics
					});
				}

		});
		fetch(`${URLS.backendDepartments}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        }).then(res => res.json())
            .then((result) => {
                this.setState({
                    departments: result.departments,
                });
        });
	};
	updateSearchFilterID = (evt) =>{
		const{searchFilter} = this.state;
		searchFilter.id = evt.target.value;
		this.setState({
			searchFilter
		})
	}
	updateSearchFilterName = (evt) =>{
		const{searchFilter} = this.state;
		searchFilter.name = evt.target.value;
		this.setState({
			searchFilter
		})
	}
	updateSearchFilterDepartment = (evt) =>{
		const{searchFilter} = this.state;
		searchFilter.department = evt.target.value;
		this.setState({
			searchFilter
		})
	}
	searchEmploees = () => {
		const {searchFilter,employees} = this.state;
		if(searchFilter.id && searchFilter.id !== ''){
			let tempEmlpoyeeList =  employees.filter(employee => {
				return employee.jobInfo.employeeId === searchFilter.id
			})
			this.setState({
				searchEmlpoyeeList : tempEmlpoyeeList
			})
		}else{
			if(searchFilter.name && searchFilter.name !== ''){
				let name = searchFilter.name.toLowerCase();
				let tempEmlpoyeeList = employees.filter(function(employee) {
					let employeeName = employee.personalInfo.name.first + employee.personalInfo.name.middle+employee.personalInfo.name.last;
					return employeeName.toLowerCase().indexOf(name) > -1;
				})
				if(searchFilter.department && searchFilter.department !== ''){
					tempEmlpoyeeList = tempEmlpoyeeList.filter(function(employee) {
						return employee.jobInfo.department ? employee.jobInfo.department._id === searchFilter.department : null;
					})
				}
				this.setState({
					searchEmlpoyeeList : tempEmlpoyeeList
				})
			}else if(searchFilter.department && searchFilter.department !== ''){
					let tempEmlpoyeeList = employees.filter(function(employee) {
						return employee.jobInfo.department ? employee.jobInfo.department._id === searchFilter.department : null;
					})
					this.setState({
						searchEmlpoyeeList : tempEmlpoyeeList
					})
			}else{
				this.setState({
					searchEmlpoyeeList : employees
				})
				}
		}	
	}

	render() {
		const { editEmployee,searchEmlpoyeeList,departments ,searchFilter, employeesStatistics} = this.state;
		const editEmployeeData = this.getEditEmployeeDataFromId(editEmployee);

		if (Object.keys(editEmployeeData).length) {
			console.log('editting...', editEmployeeData)
		}

		return (
			<body>
				<Helmet>
					<meta charSet="utf-8" />
					<meta name="robots" content="noindex, nofollow" />
					<title>Fexspace HR</title>
				</Helmet>
				<div className="main-wrapper">

					<Topbar />
					<Sidebar />
					{/*<!-- Page Wrapper -->*/}
					<div className="page-wrapper">

						{/*<!-- Page Content -->*/}
						<div className="content container-fluid">

							{/*<!-- Page Title -->*/}
							<div className="row">
								<div className="col">
								<Breadcrumb>
									<BreadcrumbItem><Link to={`${URLS.dashboard}`}>Dashboard</Link></BreadcrumbItem>
                            		<BreadcrumbItem active>Employees</BreadcrumbItem>
                        		</Breadcrumb>
									{/* <h4 className="page-title">Employee</h4> */}
								</div>
								<div className="col-auto text-right float-right ml-auto m-b-30">
									<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_employee"><i className="fa fa-plus"></i>Add Employee</a>
									<div className="view-icons">
										<a href="employees.html" className="grid-view btn btn-link active"><i className="fa fa-th"></i></a>
										<a href="employees-list.html" className="list-view btn btn-link"><i className="fa fa-bars"></i></a>
									</div>
								</div>
							</div>
							{/*<!-- /Page Title -->*/}
							{/*<!-- Leave Statistics -->*/}
							<div className="row">
								<div className="col-md-3">
									<div className="stats-info">
										<h6>Confirmed</h6>
										<h4>{employeesStatistics.confirmed}</h4>
									</div>
								</div>
								<div className="col-md-3">
									<div className="stats-info">
										<h6>Trainee</h6>
										<h4>{employeesStatistics.trainee}</h4>
									</div>
								</div>
								<div className="col-md-3">
									<div className="stats-info">
										<h6>Probation</h6>
										<h4>{employeesStatistics.probation}</h4>
									</div>
								</div>
								<div className="col-md-3">
									<div className="stats-info">
										<h6>Internship</h6>
										<h4>{employeesStatistics.internship}</h4>
									</div>
								</div>
							</div>
							{/*<!-- /Leave Statistics -->*/}
							<div className="row">
								<div className="col-md-12" style={{textAlign:'center', textAlign:'-webkit-center'}}>
									<PieChartModel data = {[
										{ name: 'Male', value: employeesStatistics['gender']['Male'] },
										{ name: 'Female', value: employeesStatistics['gender']['Female'] },
										{ name: 'Others', value: employeesStatistics['gender']['Others']}
									]}/>
								</div>
							</div>
							{/*<!-- Search Filter -->*/}
							<div className="row filter-row">
								<div className="col-sm-6 col-md-3">
									<div className="form-group form-focus">
										<input type="text" className="form-control floating" value={searchFilter.id} onChange={this.updateSearchFilterID}/>
										<label className="focus-label">Employee ID</label>
									</div>
								</div>
								<div className="col-sm-6 col-md-3">
									<div className="form-group form-focus">
										<input type="text" className="form-control floating" value={searchFilter.name} onChange={this.updateSearchFilterName}/>
										<label className="focus-label">Employee Name</label>
									</div>
								</div>
								<div className="col-sm-6 col-md-3">
									<div className='form-group form-focus'>
										<Form.Control  as='select' value={searchFilter.department}
											onChange={this.updateSearchFilterDepartment}>
											<option value=''>Select Department</option>
												{
													departments.length ? departments.map(department =>{
													return <option value={department._id}>{department.name}</option>;
													}) : null
												}
											
										</Form.Control>
										<label className="focus-label">Department</label>
									</div>
									
								</div>
								<div className="col-sm-6 col-md-3">
									<a href='Javascript:void(0);' className="btn btn-success btn-block" onClick={this.searchEmploees}> Search </a>
								</div>
							</div>
							{/* <div className="row">
								<div className="col">
									Filters
								</div>
							</div> */}
							{/*<!-- Search Filter -->*/}

							<div className="row staff-grid-row">
							{ searchEmlpoyeeList.length ? searchEmlpoyeeList.map((employee) => {
									employee = employee || { personalInfo: '', jobInfo: '' }
									return <Employee key={employee._id} _id={employee._id} name={employee.personalInfo.name} profile={employee.jobInfo.title} onEdit={this.editEmployee} displayImage={employee.emergencyInfo && employee.emergencyInfo.image} />
								}) : <h6 style={{textAlign:'center', width:'100%'}}>No employee found</h6>
							}
							</div>
						</div>
						{/*<!-- /Page Content -->*/}

						{/*<!-- Add Employee Modal -->*/}
						<AddEmployeeModal mode='add' updateEmployeesList={this.updateEmployeesList.bind(this)} />
						{/*<!-- /Add Employee Modal -->*/}

						{/*<!-- Edit Employee Modal -->*/}
						<EditEmployeeModal mode='edit' employee={editEmployeeData} updateEmployeesList={this.updateEmployeesList} />
						{/* {pass correct employee in edit employee modal. use editEmployee} */}
						{/*<!-- /Edit Employee Modal -->*/}

						{/*<!-- Delete Employee Modal -->*/}
						<div className="modal custom-modal fade" id="delete_employee" role="dialog">
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-body">
										<div className="form-header">
											<h3>Delete Employee</h3>
											<p>Are you sure want to delete?</p>
										</div>
										<div className="modal-btn delete-action">
											<div className="row">
												<div className="col-6">
													<a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a>
												</div>
												<div className="col-6">
													<a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Delete Employee Modal -->*/}

					</div>
					{/*<!-- /Page Wrapper -->*/}

				</div>
				{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
				<div className="sidebar-overlay" data-reff=""></div>
			</body>
		)
	}
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Employees))

