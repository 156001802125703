import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Main from './Main.js';
import './App.css';

class App extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
      return (
          <Provider store={store}>
              <Main />
          </Provider>
      );
  }
}

export default App;
