const SERVER_BASE_URL =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:4040'
		: 'http://invexonline.com:4040';
console.log(process.env.NODE_ENV, SERVER_BASE_URL);
export const URLS = {
    landingPage: '',
	dashboard: '/dashboard',
	clients: '/dashboard/clients',
	activities: '/dashboard/activities',
	employees: '/dashboard/employees',
	holidays: '/dashboard/holidays',
	leaves: '/dashboard/leaves',
	leavesEmployee: '/dashboard/leaves-employee',
	leaveSettings: '/dashboard/leaveSettings',
	attendance: '/dashboard/attendance',
	attendanceEmployee: '/dashboard/attendance-employee',
	departments: '/dashboard/departments',
	designations: '/dashboard/designations',
	performance : '/dashboard/performance',
	timesheet: '/dashboard/timesheet',
	promotion: '/dashboard/promotion',
	resignation: '/dashboard/resignation',
	termination: '/dashboard/termination',
	overtime: '/dashboard/overtime',
	projects: '/dashboard/projects',
	teams: '/dashboard/teams',
	projectView: '/dashboard/project-view',
	teamView: '/dashboard/team-view',
	tasks: '/dashboard/tasks',
	voiceCall: '/dashboard/voice-call',
	videoCall: '/dashboard/video-call',
	outgoingCall: '/dashboard/outgoing-call',
	incomingCall: '/dashboard/incoming-call',
	contacts: '/dashboard/contacts',
	leads: '/dashboard/leads',
	estimates: '/dashboard/estimates',
	invoices: '/dashboard/invoices',
	payments: '/dashboard/payments',
	expenses: '/dashboard/expenses',
	providentFund: '/dashboard/provident-fund',
	taxes: '/dashboard/taxes',
	salary: '/dashboard/salary',
	salaryView: '/dashboard/salary-view',
	payrollItems: '/dashboard/payroll-items',
	jobs: '/dashboard/jobs',
	jobDetail: '/dashboard/job-details',
	jobList: '/job-list',
	jobView: '/job-view',
	jobApplicants: '/dashboard/job-applicants',
	tickets: '/dashboard/tickets',
	events: '/dashboard/events',
	inbox: '/dashboard/inbox',
	chat: '/dashboard/chat',
	assets: '/dashboard/assets',
	policies: '/dashboard/policies',
	users: '/dashboard/users',
	expenseReports: '/dashboard/expense-reports',
	invoiceReports: '/dashboard/invoice-reports',
	reports: '/dashboard/invoice-reports',
	settings: '/dashboard/settings',
	login: '/login',
	register: '/dashboard/register',
	forgotPassword: '/dashboard/forgot-password',
	otp: '/dashboard/otp',
	lockScreen: '/dashboard/lock-screen',
	profile: '/dashboard/profile',
	clientProfile: '/dashboard/client-profile',
	error404: '/error-404',
	error500: '/dashboard/error-500',
	blankPage: '/dashboard/blank-page',
	components: '/dashboard/components',

	userDashboardBase : '/user',
	userDashboard : '/user/home',
	userDashboardProfile : '/user/profile',
	userDashboardProjects : '/user/projects',
	userDashboardProjectView : '/user/project-view',
	userDashboardTasks : '/user/tasks',
	userDashboardPerformance : '/user/performance',
	userDashboardPolicy : '/user/policy',
	userDashboardLeaves: '/user/leaves',
	userDashboardTeams: '/user/teams',
	userDashboardTeamView : '/user/team-view',


	backendStatic: `${SERVER_BASE_URL}/public/static`,
	backendEmployees: `${SERVER_BASE_URL}/api/employees`,
	backendUsers: `${SERVER_BASE_URL}/api/users`,
	backendJobGrades:`${SERVER_BASE_URL}/api/jobGrades`,
	backendLocations:`${SERVER_BASE_URL}/api/locations`,
	backendDepartments:`${SERVER_BASE_URL}/api/Departments`,
	backendSkills:`${SERVER_BASE_URL}/api/Skills`,
	backendTrainings:`${SERVER_BASE_URL}/api/Trainings`,
	backendLifeInsurances:`${SERVER_BASE_URL}/api/lifeinsurances`,
	backendEmployeeHMOs:`${SERVER_BASE_URL}/api/employeehmos`,
	backendProjects: `${SERVER_BASE_URL}/api/projects`,
	backendClients:`${SERVER_BASE_URL}/api/clients`,
	backendTasks: `${SERVER_BASE_URL}/api/tasks`,
	backendDesignations: `${SERVER_BASE_URL}/api/designations`,
	backendJobs: `${SERVER_BASE_URL}/api/jobs`,
	backendApplications : `${SERVER_BASE_URL}/api/applications`,
	backendPolicies : `${SERVER_BASE_URL}/api/policies`,
	backendLeaves : `${SERVER_BASE_URL}/api/leaves`,
	backendApplyLeaves : `${SERVER_BASE_URL}/api/applyleaves`,
	backendLeaveBalances : `${SERVER_BASE_URL}/api/leaveBalances`,
	backendLeaveCustomPolicies : `${SERVER_BASE_URL}/api/leaveCustomPolicies`,
	backendTeams : `${SERVER_BASE_URL}/api/teams`,
	backendCompanies : `${SERVER_BASE_URL}/api/companies`,
	backendNotifications : `${SERVER_BASE_URL}/api/notifications`,
};