import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class ExpenseReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-sm-12">
							<h4 className="page-title">Expense Report</h4>
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					{/*<!-- Search Filter -->*/}
					<div className="row filter-row">
						<div className="col-sm-6 col-md-3"> 
							<div className="form-group form-focus select-focus">
								<select className="select floating"> 
									<option>Select buyer</option>
									<option>Loren Gatlin</option>
									<option>Tarah Shropshire</option>
								</select>
								<label className="focus-label">Purchased By</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3">  
							<div className="form-group form-focus">
								<div className="cal-icon">
									<input className="form-control floating datetimepicker" type="text" />
								</div>
								<label className="focus-label">From</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3">  
							<div className="form-group form-focus">
								<div className="cal-icon">
									<input className="form-control floating datetimepicker" type="text" />
								</div>
								<label className="focus-label">To</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3">  
							<a href="#" className="btn btn-success btn-block"> Search </a>  
						</div>     
                    </div>
					{/*<!-- /Search Filter -->*/}
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table mb-0 datatable">
									<thead>
										<tr>
											<th>Item</th>
											<th>Purchase From</th>
											<th>Purchase Date</th>
											<th>Purchased By</th>
											<th>Amount</th>
											<th>Paid By</th>
											<th className="text-center">Status</th>
											<th className="text-right">Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<strong>Dell Laptop</strong>
											</td>
											<td>Amazon</td>
											<td>5 Jan 2019</td>
											<td>
												<a href="profile.html" className="avatar avatar-xs">
													<img src="assets/img/profiles/avatar-04.jpg" alt="" />
												</a>
												<h2><a href="profile.html">Loren Gatlin</a></h2>
											</td>
											<td>$ 1215</td>
											<td>Cash</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-danger"></i> Pending
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<strong>Mac System</strong>
											</td>
											<td>Amazon</td>
											<td>5 Jan 2019</td>
											<td>
												<a href="profile.html" className="avatar avatar-xs">
													<img src="assets/img/profiles/avatar-03.jpg" alt="" />
												</a>
												<h2><a href="profile.html">Tarah Shropshire</a></h2>
											</td>
											<td>$ 1215</td>
											<td>Cheque</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-success"></i> Approved
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
				
            </div>
			{/*<!-- /Page Wrapper -->*/}
			
        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ExpenseReports))

