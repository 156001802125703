import React, { Component } from "react";
import { URLS } from "../../urls";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

class UserTopbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      notifications: [],
    };
  }
  componentWillMount() {
    const id = localStorage.getItem("userId");
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employee: result.employee,
        });
      });
    fetch(`${URLS.backendNotifications}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("CC", result);
        this.setState({
          notifications: result.notifications,
        });
      });
  }

  getDateDiff = (date) => {
    let createDate = moment(date);
    let now = moment(new Date());
    let diff = now.diff(createDate);
    diff = parseInt(diff / 1000);
    if (diff < 60) {
      return diff + " seconds ago";
    }
    diff = parseInt(diff / 60);
    if (diff < 60) {
      if (diff === 1) return "a min ago";
      else return diff + " mins ago";
    }

    diff = parseInt(diff / 60);
    if (diff < 24) {
      if (diff === 1) return "an hour ago";
      else return diff + " hours ago";
    }

    diff = parseInt(diff / 24);
    if (diff < 30) {
      if (diff === 1) return "a day ago";
      else return diff + " days ago";
    }

    diff = parseInt(diff / 30);
    if (diff === 1) return "a month ago";
    else return diff + " months ago";
  };

  render() {
    const { employee, notifications } = this.state;
    const profileImage =
      employee && employee.emergencyInfo && employee.emergencyInfo.image
        ? URLS.backendStatic + "/" + employee.emergencyInfo.image
        : "/assets/img/profiles/avatar-21.jpg";
    return (
      <div className="header" style={{ background: "#020e28" }}>
        <div className="header-left">
          <Link to={URLS.userDashboard} className="logo">
            <img
              src={URLS.landingPage + "/assets/img/logo-2.png"}
              alt=""
              width="130px"
              height="50px"
            />
          </Link>
        </div>
        <a id="toggle_btn" href="javaScript:void(0);">
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>

        <div className="page-title-box">
          <h3>Daedal HR</h3>
        </div>

        <a id="mobile_btn" className="mobile_btn" href="#sidebar">
          <i className="fa fa-bars"></i>
        </a>

        {/* <!-- Header Menu --> */}
        <ul className="nav user-menu">
          {/* <!-- Notifications --> */}
          <li className="nav-item dropdown">
            <a
              href="javaScript:void(0);"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <i className="fa fa-bell-o"></i>
              {notifications.length ? (
                <span class="badge badge-pill">{notifications.length}</span>
              ) : null}
            </a>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                {/* <a href='javaScript:void(0)' className='clear-noti'> Clear All </a> */}
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  {notifications.map((notification) => {
                    return (
                      <li className="notification-message">
                        <a
                          href={
                            URLS.userDashboardBase + "/" + notification.target
                          }
                        >
                          <div className="media">
                            <span
                              style={{
                                width: "38px",
                                height: "38px",
                                lineHeight: "38px",
                              }}
                            >
                              {/* <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-02.jpg'} /> */}
                            </span>
                            <div className="media-body">
                              <p className="noti-details">
                                {ReactHtmlParser(notification.message)}
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">
                                  {this.getDateDiff(notification.createDate)}
                                </span>
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* <div className='topnav-dropdown-footer'>
                                <Link to={`${URLS.activities}`}>View all Notifications</Link>
                            </div> */}
            </div>
          </li>
          {/* <!-- /Notifications --> */}

          {/* <!-- Message Notifications --> */}
          <li className="nav-item dropdown">
            <a
              href="javaScript:void(0)"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <i className="fa fa-comment-o"></i>
            </a>
            {/* <div className='dropdown-menu notifications'>
                            <div className='topnav-dropdown-header'>
                                <span className='notification-title'>Messages</span>
                                <a href='javascript:void(0)' className='clear-noti'> Clear All </a>
                            </div>
                            <div className='noti-content'>
                                <ul className='notification-list'>
                                    <li className='notification-message'>
                                        <a href='http://app.fexspace.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-09.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'>Richard Miles </span>
                                                    <span className='message-time'>12:28 AM</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.fexspace.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-02.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'>John Doe</span>
                                                    <span className='message-time'>6 Mar</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.fexspace.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-03.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'> Tarah Shropshire </span>
                                                    <span className='message-time'>5 Mar</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.fexspace.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-05.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'>Mike Litorus</span>
                                                    <span className='message-time'>3 Mar</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.fexspace.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-08.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'> Catherine Manseau </span>
                                                    <span className='message-time'>27 Feb</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='topnav-dropdown-footer'>
                                <a href='http://app.fexspace.com/messenger'>View all Messages</a>
                            </div>
                        </div> */}
          </li>
          {/* <!-- /Message Notifications --> */}

          <li className="nav-item dropdown has-arrow main-drop">
            <a
              href="javaScript:void(0)"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <span className="user-img">
                <img style={{ height: "30px" }} src={profileImage} alt="" />
                <span className="status online"></span>
              </span>
              <span>
                &nbsp;{employee ? employee.personalInfo.name.first : "User"}
                &nbsp;
              </span>
            </a>
            <div className="dropdown-menu">
              <Link className="dropdown-item" to={URLS.userDashboardProfile}>
                My Profile
              </Link>
              <Link className="dropdown-item" to={URLS.login}>
                Logout
              </Link>
            </div>
          </li>
        </ul>
        {/* <!-- /Header Menu --> */}

        {/* <!-- Mobile Menu --> */}
        <div className="dropdown mobile-user-menu">
          <a
            href="javaScript:void(0)"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to={URLS.userDashboardProfile}>
              My Profile
            </Link>
            <Link className="dropdown-item" to={URLS.login}>
              Logout
            </Link>
          </div>
        </div>
        {/* <!-- /Mobile Menu --> */}
      </div>
    );
  }
}

export default UserTopbar;
