import React from 'react';
import { URLS } from '../urls';
import moment from 'moment';
import DatePicker from 'react-datetime';
import { Form, Col, Row, Image } from 'react-bootstrap';
export default class DeleteLeaveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonText : 'Delete',
            isWaiting : false,
            deleteLeave : null
        }
    }
    componentWillReceiveProps(props){
        if(props.deleteLeave)
            this.setState({
                deleteLeave : props.deleteLeave
            })
    }    
    deleteSelectedLeave = (e) => {
        e.preventDefault();
        this.setState({buttonText:'Deleting ...',isWaiting:true})
        const { deleteLeave } = this.state;
        const token = 'dummy token'; // localStorage.getItem('token');
        fetch(`${URLS.backendApplyLeaves}/delete/${deleteLeave._id}`, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Token ${token}`
            },
        }).then(res =>{
            if(res.ok){
                res.json().then((result) => {
                    setTimeout(()=>{
                        window.$("#delete_leave").modal("toggle");
                        this.setState({ buttonText: 'Delete', isWaiting: false });
                        this.props.removeLeaveFormAppliedLeavesList(result.applyLeave);
                    }, 500);
                });
            }else{
                res.json().then((responseJson) => {
                    console.log(responseJson);
                    alert("Error occurred, Check the form again.");
                    this.setState({ buttonText: 'Delete', isWaiting: false });
                });
            }
        });
    };

	render() {
        return (
            <div className="modal custom-modal fade" id="delete_leave" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h3>Delete Leave</h3>
                                <p>Are you sure want to delete this leave?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="col-6">
                                        <a href="javaScript:void(0);" className="btn btn-primary continue-btn" onClick={this.deleteSelectedLeave}>Delete</a>
                                    </div>
                                    <div className="col-6">
                                        <a href="javaScript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}