import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-sm-8 col-4">
							<h4 className="page-title">Events</h4>
						</div>
						<div className="col-sm-4 col-8 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_event"><i className="fa fa-plus"></i> Add Event</a>
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className="row">
						<div className="col-lg-12">
							<div className="card-box mb-0">
								<div className="row">
									<div className="col-md-12">
									
										{/*<!-- Calendar -->*/}
										<div id="calendar"></div>
										{/*<!-- /Calendar -->*/}
										
									</div>
								</div>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
			
				{/*<!-- Add Event Modal -->*/}
				<div id="add_event" className="modal custom-modal fade" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add Event</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label>Event Name <span className="text-danger">*</span></label>
										<input className="form-control" type="text" />
									</div>
									<div className="form-group">
										<label>Event Date <span className="text-danger">*</span></label>
										<div className="cal-icon">
											<input className="form-control datetimepicker" type="text" />
										</div>
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Add Event Modal -->*/}
				
				{/*<!-- Event Modal -->*/}
				<div className="modal custom-modal fade" id="event-modal">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Event</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body"></div>
							<div className="modal-footer text-center">
								<button type="button" className="btn btn-success submit-btn save-event">Create event</button>
								<button type="button" className="btn btn-danger submit-btn delete-event" data-dismiss="modal">Delete</button>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Event Modal -->*/}
				
				{/*<!-- Add Category Modal-->*/}
				<div className="modal custom-modal fade" id="add-category">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal">&times;</button>
								<h4 className="modal-title">Add a category</h4>
							</div>
							<div className="modal-body p-20">
								<form>
									<div className="row">
										<div className="col-md-6">
											<label className="col-form-label">Category Name</label>
											<input className="form-control" placeholder="Enter name" type="text" name="category-name" />
										</div>
										<div className="col-md-6">
											<label className="col-form-label">Choose Category Color</label>
											<select className="form-control" data-placeholder="Choose a color..." name="category-color">
												<option value="success">Success</option>
												<option value="danger">Danger</option>
												<option value="info">Info</option>
												<option value="pink">Pink</option>
												<option value="primary">Primary</option>
												<option value="warning">Warning</option>
												<option value="orange">Orange</option>
												<option value="brown">Brown</option>
												<option value="teal">Teal</option>
											</select>
										</div>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
								<button type="button" className="btn btn-danger save-category" data-dismiss="modal">Save</button>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Add Category Modal-->*/}
				
            </div>
			{/*<!-- /Page Wrapper -->*/}
			
        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Events))

