import React, { Component } from 'react';
import { URLS } from '../../urls';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import UserTopbar from '../user-topbar/UserTopbar';
import UserSidebar from '../user-sidebar/UserSidebar';
class UserHome extends Component {
	render() {
		return (
                <body>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="robots" content="noindex, nofollow"></meta>
                        <title>Fexspace HR</title>
                    </Helmet>

		            <div className="main-wrapper">
                        <UserTopbar />
                        <UserSidebar />
                        </div>
                </body>
        )
    }
}

export default UserHome;
