import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import moment from 'moment';
import {Helmet} from "react-helmet";
import TopbarGuest from '../topbar/TopbarGuest';
import { URLS } from '../urls';

class JobList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			jobs : [],
        };
	}
	getDateDiff = (date) =>{
		let postedDate = moment(date,'DD/MM/YYYY');
		let today = moment(new Date(),'DD/MM/YYYY');
		let days = today.diff(postedDate,'days');
		if(days===0)
			return 'Today';
		else if(days===1)
			return 'Yesterday';
		else if(days<7)
			return days + ' days ago';
		else if(days<14)
			return 'One week ago';
		else if(days<30)
			return parseInt(days/7) +' weeks ago';
		else if(days<60)
			return 'One month ago';
		else
			return parseInt(days/30) +' months ago';
	}
	componentWillMount(){
		const token = 'dummy token';
		fetch(`${URLS.backendJobs}/get`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
			}
		}).then(res => res.json())
		.then((result) => {
			console.log(result)
			this.setState({
                jobs : result.jobs
			});
		});
		
	}

	render() {
		const {jobs} = this.state;
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Daedel HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<TopbarGuest />
			{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper job-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-sm-12">
							<h4 className="page-title">Jobs</h4>
						</div>
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className="row">
						{jobs.length ? jobs.map((job)=>{
							return(
								<div className="col-md-6">
									<a className="job-list" href={`${URLS.jobView}/${job._id}`}>
										<div className="job-list-det">
											<div className="job-list-desc">
												<h3 className="job-list-title">{job.jobTitle}</h3>
												<h4 className="job-department">{job.development}</h4>
											</div>
											<div className="job-type-info">
												<span className="job-types">{job.jobType}</span>
											</div>
										</div>
										<div className="job-list-footer">
											<ul>
												<li><i className="fa fa-map-signs"></i>{job.location}</li>
												<li><i className="fa fa-money"></i> {job.salaryFrom}-{job.salaryTo}</li>
												<li><i className="fa fa-clock-o"></i> {this.getDateDiff(job.startDate)}</li>
											</ul>
										</div>
									</a>
								</div>
							)
						}):''}
					</div>
                </div>

            </div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(JobList))

