import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
			{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
					{/* <div className="row">
						<div className="col-md-8 offset-md-2">
							<form>
								<h3 className="page-title">Company Settings</h3>
								<div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Company Name <span className="text-danger">*</span></label>
											<input className="form-control" type="text" value="Dreamguy's Technologies" />
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Contact Person</label>
											<input className="form-control " value="Daniel Porter" type="text" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<label>Address</label>
											<input className="form-control " value="3864 Quiet Valley Lane, Sherman Oaks, CA, 91403" type="text" />
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-3">
										<div className="form-group">
											<label>Country</label>
											<select className="form-control select">
												<option>USA</option>
												<option>United Kingdom</option>
											</select>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-3">
										<div className="form-group">
											<label>City</label>
											<input className="form-control" value="Sherman Oaks" type="text" />
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-3">
										<div className="form-group">
											<label>State/Province</label>
											<select className="form-control select">
												<option>California</option>
												<option>Alaska</option>
												<option>Alabama</option>
											</select>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-3">
										<div className="form-group">
											<label>Postal Code</label>
											<input className="form-control" value="91403" type="text" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Email</label>
											<input className="form-control" value="danielporter@example.com" type="email" />
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Phone Number</label>
											<input className="form-control" value="818-978-7102" type="text" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Mobile Number</label>
											<input className="form-control" value="818-635-5579" type="text" />
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Fax</label>
											<input className="form-control" value="818-978-7102" type="text" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<label>Website Url</label>
											<input className="form-control" value="https://www.example.com" type="text" />
										</div>
									</div>
								</div>
								<div className="submit-section">
									<button className="btn btn-primary submit-btn">Save</button>
								</div>
							</form>
						</div>
					</div> */}
                </div>
				{/*<!-- /Page Content -->*/}
				
            </div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Settings))

