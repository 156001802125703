import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class Assets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
					<div className="row">
						<div className="col-sm-8 col-6">
							<h4 className="page-title">Assets</h4>
						</div>
						<div className="col-sm-4 col-6 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_asset"><i className="fa fa-plus"></i> Add Asset</a>
						</div>
					</div>
					
					{/*<!-- Search Filter -->*/}
					<div className="row filter-row">
						<div className="col-sm-6 col-md-3">  
							<div className="form-group form-focus">
								<input type="text" className="form-control floating" />
								<label className="focus-label">Employee Name</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3"> 
							<div className="form-group form-focus select-focus">
								<select className="select floating"> 
									<option value=""> -- Select -- </option>
									<option value="0"> Pending </option>
									<option value="1"> Approved </option>
									<option value="2"> Returned </option>
								</select>
								<label className="focus-label">Status</label>
							</div>
						</div>
						<div className="col-sm-12 col-md-4">  
						   <div className="row">  
							   <div className="col-md-6 col-sm-6">  
									<div className="form-group form-focus">
										<div className="cal-icon">
											<input className="form-control floating datetimepicker" type="text" />
										</div>
										<label className="focus-label">From</label>
									</div>
								</div>
							   <div className="col-md-6 col-sm-6">  
									<div className="form-group form-focus">
										<div className="cal-icon">
											<input className="form-control floating datetimepicker" type="text" />
										</div>
										<label className="focus-label">To</label>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-2">  
							<a href="#" className="btn btn-success btn-block"> Search </a>  
						</div>     
                    </div>
					{/*<!-- /Search Filter -->*/}
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table mb-0 datatable">
									<thead>
										<tr>
											<th>Asset User</th>
											<th>Asset Name</th>
											<th>Asset Id</th>
											<th>Purchase Date</th>
											<th>Warrenty</th>
											<th>Warrenty End</th>
											<th>Amount</th>
											<th className="text-center">Status</th>
											<th className="text-right"></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Richard Miles</td>
											<td>
												<strong>Dell Laptop</strong>
											</td>
											<td>#AST-0001</td>
											<td>5 Jan 2019</td>
											<td>12 Months</td>
											<td>5 Jan 2019</td>
											<td>$1215</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-danger"></i> Pending
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>John Doe</td>
											<td>
												<strong>Seagate Harddisk</strong>
											</td>
											<td>#AST-0002</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$300</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-success"></i> Approved
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>John Smith</td>
											<td>
												<strong>Canon Portable Printer</strong>
											</td>
											<td>#AST-0003</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$2500</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-info"></i> Returned
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Mike Litorus</td>
											<td>
												<strong>Dell Laptop</strong>
											</td>
											<td>#AST-0004</td>
											<td>5 Jan 2019</td>
											<td>12 Months</td>
											<td>5 Jan 2019</td>
											<td>$1215</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-danger"></i> Pending
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Wilmer Deluna</td>
											<td>
												<strong>Seagate Harddisk</strong>
											</td>
											<td>#AST-0005</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$300</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-success"></i> Approved
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Jeffrey Warden</td>
											<td>
												<strong>Canon Portable Printer</strong>
											</td>
											<td>#AST-0006</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$2500</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-info"></i> Returned
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Bernardo Galaviz</td>
											<td>
												<strong>Dell Laptop</strong>
											</td>
											<td>#AST-0007</td>
											<td>5 Jan 2019</td>
											<td>12 Months</td>
											<td>5 Jan 2019</td>
											<td>$1215</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-danger"></i> Pending
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Lesley Grauer</td>
											<td>
												<strong>Seagate Harddisk</strong>
											</td>
											<td>#AST-0008</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$300</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-success"></i> Approved
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Jeffery Lalor</td>
											<td>
												<strong>Canon Portable Printer</strong>
											</td>
											<td>#AST-0009</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$2500</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-info"></i> Returned
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Loren Gatlin</td>
											<td>
												<strong>Dell Laptop</strong>
											</td>
											<td>#AST-0010</td>
											<td>5 Jan 2019</td>
											<td>12 Months</td>
											<td>5 Jan 2019</td>
											<td>$1215</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-danger"></i> Pending
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Tarah Shropshire</td>
											<td>
												<strong>Seagate Harddisk</strong>
											</td>
											<td>#AST-0011</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$300</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-success"></i> Approved
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Catherine Manseau</td>
											<td>
												<strong>Canon Portable Printer</strong>
											</td>
											<td>#AST-0012</td>
											<td>14 Jan 2019</td>
											<td>12 Months</td>
											<td>14 Jan 2019</td>
											<td>$2500</td>
											<td className="text-center">
												<div className="dropdown action-label">
													<a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
														<i className="fa fa-dot-circle-o text-info"></i> Returned
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Pending</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
														<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Returned</a>
													</div>
												</div>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_asset"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_asset"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
			
				{/*<!-- Add Asset Modal -->*/}
				<div id="add_asset" className="modal custom-modal fade" role="dialog">
					<div className="modal-dialog modal-md" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add Asset</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Asset Name</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Asset Id</label>
												<input className="form-control" type="text" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Purchase Date</label>
												<input className="form-control datetimepicker" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Purchase From</label>
												<input className="form-control" type="text" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Manufacturer</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Model</label>
												<input className="form-control" type="text" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Serial Number</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Supplier</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Condition</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Warranty</label>
												<input className="form-control" type="text" placeholder="In Months" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Value</label>
												<input placeholder="$1800" className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Asset User</label>
												<select className="select">
													<option>John Doe</option>
													<option>Richard Miles</option>
												</select>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label>Description</label>
												<textarea className="form-control"></textarea>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Status</label>
												<select className="select">
													<option>Pending</option>
													<option>Approved</option>
													<option>Deployed</option>
													<option>Damaged</option>
												</select>
											</div>
										</div>
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Add Asset Modal -->*/}
				
				{/*<!-- Edit Asset Modal -->*/}
				<div id="edit_asset" className="modal custom-modal fade" role="dialog">
					<div className="modal-dialog modal-md" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Asset</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Asset Name</label>
												<input className="form-control" type="text" value="Dell Laptop" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Asset Id</label>
												<input className="form-control" type="text" value="#AST-0001" readonly />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Purchase Date</label>
												<input className="form-control datetimepicker" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Purchase From</label>
												<input className="form-control" type="text" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Manufacturer</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Model</label>
												<input className="form-control" type="text" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Serial Number</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Supplier</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Condition</label>
												<input className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Warranty</label>
												<input className="form-control" type="text" placeholder="In Months" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Value</label>
												<input placeholder="$1800" className="form-control" type="text" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Asset User</label>
												<select className="select">
													<option>John Doe</option>
													<option>Richard Miles</option>
												</select>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label>Description</label>
												<textarea className="form-control"></textarea>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Status</label>
												<select className="select">
													<option>Pending</option>
													<option>Approved</option>
													<option>Deployed</option>
													<option>Damaged</option>
												</select>
											</div>
										</div>
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Save</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- Edit Asset Modal -->*/}
				
				{/*<!-- Delete Asset Modal -->*/}
				<div className="modal custom-modal fade" id="delete_asset" role="dialog">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<div className="form-header">
									<h3>Delete Asset</h3>
									<p>Are you sure want to delete?</p>
								</div>
								<div className="modal-btn delete-action">
									<div className="row">
										<div className="col-6">
											<a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a>
										</div>
										<div className="col-6">
											<a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Delete Asset Modal -->*/}
				
            </div>
			{/*<!-- /Page Wrapper -->*/}
			
        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Assets))

