import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Form, Col, Row, Image, textarea, Popover } from 'react-bootstrap';
import { URLS } from '../../urls';
import {Helmet} from 'react-helmet';
import UserTopbar from '../user-topbar/UserTopbar';
// import 'src/assets/css/bootstrap-datetimepicker.min.css';
import moment from 'moment';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import DatePicker from 'react-datetime';
import UserTaskFilter from './UserTasksFilter';
const allMonths = ['Jan','Feb','Mar', 'Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
class UserTasks extends React.Component {
    constructor(props) {
		super(props);
		this.changeStatusOfTask = this.changeStatusOfTask.bind(this);
		this.changeSelectedTask = this.changeSelectedTask.bind(this);
        this.state = {
			employee : null,
			newComment : {
				employeeId : null,
				employeeImage : '',
				employeeName : '',
				date : '',
				time : '',
				comment : ''
			},
			newFile : {
				employeeId : null,
				employeeImage : '',
				employeeName : '',
				date : '',
				time : '',
				file: {
					message : '',
					original : '',
					unique : '',
					ext :  '',
					size : ''
				},
			},
			fileObject : null,
			taskFilter : 'ALL',
			projects : [],
			activeProjectId : '',
			tasks : {},
			leadersInfo : {},
			selectedTask : null,
			teams :{},
			clientList : [],
			employeesList : [],
			projectTasks : null,
			leadersForNotification : {}
        };
	}
	componentWillMount(){
		const id = localStorage.getItem('userId');
		const token = 'dummy token';
		fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({
				employee : result.employee
			})
		});

		fetch(`${URLS.backendProjects}/getProjectsByUserId/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({
				projects: result.projects
			});
			const project =  result.projects[0];
				this.setState({
					activeProjectId : project._id
				});
			if(result.projects.length){
				result.projects.forEach(function(_project){
					const {teams,leadersForNotification} = this.state;
					leadersForNotification[_project._id] = _project.projectLeader;
					teams[_project._id] = _project.projectLeader.concat(_project.team);
					this.setState({
						teams : teams,
						leadersForNotification : leadersForNotification
					});
					this.fetchTasksByProjectIdAndUserId(_project._id,id);
					// this.fetchTaskByProjectId(_project._id);
				}.bind(this));
				// this.fetchTasksByUserId(id);
				// this.fetchTeamByProjectId(project._id);
			}
		});

		fetch(`${URLS.backendClients}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({
				clientList: result.clients,
			});
		});
	
		fetch(`${URLS.backendEmployees}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({
				employeesList: result.employees
			});
		});
	};
	fetchTasksByUserId = (id) => {
		const token = 'dummy token';
		fetch(`${URLS.backendTasks}/getAllTasksByUserId/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			const { activeProjectId, tasks, projects } = this.state;
			let tasksData = result.tasks;
			projects.forEach(function(project){
				let tempTasks = tasksData.filter(task => {
					return task.project === project._id;
				});
				tasks[project._id] = tempTasks.length ? tempTasks : null;
			})
			this.setState({
				tasks : tasks,
				projectTasks:tasks[activeProjectId]
			})
			if(tasks[activeProjectId]){
				this.setState({
					selectedTask : tasks[activeProjectId][0]
				})
			}
		});
	}
	fetchTasksByProjectIdAndUserId(projectId, userId){
		const token = 'dummy token';
		fetch(`${URLS.backendTasks}/getTasksByProjectIdAndUserId/${projectId}/${userId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			const { activeProjectId, tasks, leadersInfo } = this.state;
			tasks[projectId] = result.tasks;
			leadersInfo[projectId] = result.leader;
			if(activeProjectId===projectId){
				this.setState({tasks : tasks, projectTasks:tasks[activeProjectId], leadersInfo})
				if(result.tasks.length)
					this.setState({selectedTask : tasks[activeProjectId][0]})
			}
			else
				this.setState({tasks : tasks, leadersInfo})
		});
	}
	updateNewComment = (event) =>{
		const {newComment} = this.state;
		newComment.comment = event.target.value;
		this.setState({
			newComment
		})
	}
	submitComment = (event) =>{
		const token = 'dummy token';
		let {newComment,employee,selectedTask} = this.state;
		const taskId = selectedTask._id;
		const {activeProjectId, tasks} = this.state;

		newComment.employeeId = employee._id;
		newComment.employeeName = employee.personalInfo.name.first +' '+ employee.personalInfo.name.last;
		newComment.employeeImage = employee.emergencyInfo.image;
		const date = new Date();
		newComment.date = date.getDate()+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear();
		newComment.time = date.getHours()+':'+('0'+date.getMinutes()).slice(-2);
		if(selectedTask.comments){
			selectedTask.comments.push(newComment);
		}else{
			selectedTask.comments = [];
			selectedTask.comments.push(newComment);
		}
		fetch(`${URLS.backendTasks}/update`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
			body : JSON.stringify({task : selectedTask})
		}).then(res => res.json())
			.then((result) => {
				tasks[activeProjectId].forEach(projectTask => {
					if(projectTask._id === taskId){
						projectTask.comments = result.task.comments;
					}
				});
				this.setState({
					tasks : tasks,
					selectedTask : result.task,
					newComment : {
						employeeId : null,
						employeeImage : '',
						employeeName : '',
						date : '',
						time : '',
						comment : ''
					}					
				});
		});
	}
	changeStatusOfTaskFromButton = (task) => (event) =>{
		const {leadersInfo} = this.state;
		if(task.status==="UNDER_REVIEW" && leadersInfo[task.project])
			this.approveTask(task);
		else if(task.status==='INCOMPLETE' || task.status==="UNDER_REVIEW")
			this.changeStatusOfTask(task);
	}
	approveTask(task){
		const token = 'dummy token';
		const id = localStorage.getItem('userId');
		const taskId = task._id;
		const {activeProjectId, tasks} = this.state;
		if(!task.assignedTo || task.assignedTo===''){
			alert("Task is not assigned to anyone. Can not change status.");
			return;
		}
		task.status = 'COMPLETE'
		task.approvedBy = id;
		task.approvedDate = new Date();
		fetch(`${URLS.backendTasks}/approveTask`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
			body : JSON.stringify({task : task})
		}).then(res => res.json()).then((result) => {
			if(result.task){
				tasks[activeProjectId].forEach(projectTask => {
					if(projectTask._id === taskId)
						projectTask.status = result.task.status;
				});
				this.setState({ tasks : tasks, selectedTask : result.task });
				this.generateTaskNotification("APPROVE",null,task);
			}
		});
	}

	changeStatusOfTask = (task) => {
		const token = 'dummy token';
		if(!task.assignedTo || task.assignedTo===''){
			alert("Task is not assigned to anyone. Can not change status.");
			return;
		}
		const taskId = task._id;
		const {activeProjectId, tasks} = this.state;
		const taskStatusOld = task.status;
		task.status = task.status ==='INCOMPLETE' ? 'UNDER_REVIEW' : 'INCOMPLETE'
		if(task.status === 'UNDER_REVIEW')
			task.completeDate = moment(new Date()).format("DD/MM/YYYY");
		fetch(`${URLS.backendTasks}/update`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
			body : JSON.stringify({task : task})
		}).then(res => res.json()).then((result) => {
			if(result.task){
				tasks[activeProjectId].forEach(projectTask => {
					if(projectTask._id === taskId)
						projectTask.status = result.task.status;
				});
				this.setState({ tasks : tasks, selectedTask : result.task });
				this.generateTaskNotification('TASK_STATUS',taskStatusOld, task);
			}
		});
	}
	changeSelectedTask = (task) =>{
		this.setState({
			selectedTask : task
		});
	}
	changeActiveProject = (id) => (evt) =>{
		const {tasks} = this.state;
		console.log("XPXP",tasks);
		const tempTasks = tasks[id];
		this.setState({
			activeProjectId : id,
			projectTasks : tempTasks,
		});
		if(tempTasks)
			this.setState({
				selectedTask : tempTasks[0]
			})
		else
			this.setState({
				selectedTask : null
			})
	}
	showDateInFormat = (date) =>{
		date = date.split('/');
		return allMonths[date[1]-1] + ' '+date[0]+', '+date[2];
	}
	uploadCommentFile = (event) =>{
		const {newFile} = this.state;
        let uploadedFile = event.target.files[0];
		const name = uploadedFile.name;
		const lastDot = name.lastIndexOf('.');
		const fileName = name.substring(0, lastDot);
		const ext = name.substring(lastDot + 1);
		const size = uploadedFile.size;
		let currentDate = new Date();
		currentDate = currentDate.getTime();
		newFile.file.original = fileName;
		newFile.file.unique = currentDate;
		newFile.file.ext = ext;
		if(size < 1024){
			newFile.file.size = size+' B';
		}else if(size >= 1024 && size < 1024*1024){
			newFile.file.size = (size / 1024).toFixed(2)+' KB';
		}else{
			newFile.file.size = (size / 1024 / 1024 ).toFixed(2)+' MB';
		}
		this.setState({
			fileObject : uploadedFile,
			newFile
		})
		window.$("#add_file").modal("toggle");
	}
	updateNewFileMessage = (event) =>{
		const {newFile} = this.state;
		newFile.file.message = event.target.value;
		this.setState({
			newFile
		})
	}
	closeNewFilePanel = (e) =>{
		window.$("#add_file").modal("toggle");
		this.setState({newFile : {
				employeeId : null,
				employeeImage : '',
				employeeName : '',
				date : '',
				time : '',
				file: {
					message : '',
					original : '',
					unique : '',
					ext :  '',
					size : ''
				},
			},
			fileObject : null					
		});
	}
	addNewFile = (event) => {
		event.preventDefault();
		const token = 'dummy token';
		let {newFile,employee,selectedTask,fileObject} = this.state;
		const taskId = selectedTask._id;
		const {activeProjectId, tasks} = this.state;

		newFile.employeeId = employee._id;
		newFile.employeeName = employee.personalInfo.name.first +' '+ employee.personalInfo.name.last;
		newFile.employeeImage = employee.emergencyInfo.image;
		const date = new Date();
		newFile.date = date.getDate()+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear();
		newFile.time = date.getHours()+':'+('0'+date.getMinutes()).slice(-2);
		if(selectedTask.comments){
			selectedTask.comments.push(newFile);
		}else{
			selectedTask.comments = [];
			selectedTask.comments.push(newFile);
		}
		let formData = new FormData();
		formData.append('task', JSON.stringify({ ...selectedTask }));
		if(fileObject)
			formData.append('file', fileObject, newFile.file.unique+'.'+newFile.file.ext);
		fetch(`${URLS.backendTasks}/updateTaskWithFile`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				Authorization: `Token ${token}`,
			},
			body : formData
		}).then(res => res.json())
			.then((result) => {
				window.$("#add_file").modal("toggle");
				tasks[activeProjectId].forEach(projectTask => {
					if(projectTask._id === taskId){
						projectTask.comments = result.task.comments;
					}
				});
				this.setState({
					tasks : tasks,
					selectedTask : result.task,
					newFile : {
						employeeId : null,
						employeeImage : '',
						employeeName : '',
						date : '',
						time : '',
						file: {
							message : '',
							original : '',
							unique : '',
							ext :  '',
							size : ''
						},
					},
					fileObject : null					
				});
		});
	}
	changeFilterToCompleted = (e) =>{
		this.setState({
			taskFilter : 'COMPLETE'
		})
	}
	changeFilterToPanding = (e) =>{
		this.setState({
			taskFilter : 'INCOMPLETE'
		})
	}
	changeFilterToAll = (e) =>{
		this.setState({
			taskFilter : 'ALL'
		})
	}
	changeFilter = (e) =>{
		this.setState({
			taskFilter : e.target.value
		})
	}

	generateTaskNotification = (action, oldStatus, task) =>{
		const {employee, leadersForNotification} = this.state;
		const employeeName = employee.personalInfo.name.first +' '+ employee.personalInfo.name.last;
		if(employee != null){
			if(action === 'TASK_STATUS'){
				const newStatus = task.status;
				if(oldStatus === 'INCOMPLETE' && newStatus=== 'UNDER_REVIEW'){
					const notification = {}
					const projectLeaders = leadersForNotification[task.project];
					notification.employeeFrom = task.assignedTo;
					notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> marked task <span class="noti-title"><b>'+task.taskName+'</b></span> as completed';
					notification.target =  'tasks';
					notification.tag = 'COMPLETED_TASK';
					notification.createDate = new Date();
					projectLeaders.forEach(emp =>{
						notification.employeeTo = emp._id;
						this.submitNotificationOnServer(notification);
					});
				}else if(oldStatus === 'UNDER_REVIEW' && newStatus=== 'INCOMPLETE'){
					const notification = {}
					const projectLeaders = leadersForNotification[task.project];
					notification.employeeFrom = task.assignedTo;
					notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> marked task <span class="noti-title"><b>'+task.taskName+'</b></span> as incomplete';
					notification.target =  'tasks';
					notification.tag = 'INCOMPLETED_TASK';
					notification.createDate = new Date();
					projectLeaders.forEach(emp =>{
						notification.employeeTo = emp._id;
						this.submitNotificationOnServer(notification);
					});
				}
			}else if(action === 'APPROVE'){
				const notification = {}
				const projectLeaders = leadersForNotification[task.project];
				notification.employeeFrom = employee._id;
				notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> approved task <span class="noti-title"><b>'+task.taskName+'</b></span> as completed';
				notification.target =  'tasks';
				notification.tag = 'APPROVED_TASK';
				notification.createDate = new Date();
				notification.employeeTo = task.assignedTo;
				this.submitNotificationOnServer(notification);
				projectLeaders.forEach(emp =>{
					// if(emp._id !== employee._id){
					// 	notification.employeeTo = emp._id;
					// 	this.submitNotificationOnServer(notification);
					// }
					notification.employeeTo = emp._id;
					this.submitNotificationOnServer(notification);
				});
			}
		}
	}
	submitNotificationOnServer = (notification) =>{
		const token = 'dummy token';
		fetch(`${URLS.backendNotifications}/addTaskNotification`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
			body : JSON.stringify({notification : notification})
		}).then(res => res.json()).then((result) => {
			if(result.notification){
				console.log("Notification created successfully", result.notification);
			}
		});
	}

	render() {
		const { projects, activeProjectId, selectedTask, teams,projectTasks,newComment,newFile,taskFilter,leadersInfo } = this.state;
		// const projectTasks = tasks[activeProjectId] ? tasks[activeProjectId] : null;
		return (
            <body>
                <Helmet>
                    <meta charSet='utf-8' />
                    <meta name='robots' content='noindex, nofollow'></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className='main-wrapper'>
					<UserTopbar />
					<div className='sidebar' id='sidebar'>
						<div className='sidebar-inner slimscroll'>
							<div id='sidebar-menu' className='sidebar-menu'>
								<ul>
									<li> 
										<Link to={`${URLS.userDashboardProfile}`}><i className='la la-home'></i> <span>Back to Home</span></Link>
									</li>
									<li className='menu-title'><Link style={{marginLeft:'0'}} to={URLS.userDashboardProjects}>Projects</Link></li>
									{
										projects.length ? projects.map((project) =>{
											return (
												<li className = {activeProjectId === project._id ? 'active' : ''} onClick={this.changeActiveProject(project._id)}> 
													<a href='javascript:void(0)'>{project.name}</a>
												</li>
											) 
										}) : ''
									}
								</ul>
							</div>
						</div>
					</div>
					{/*<!-- Page Wrapper -->*/}
					<div className='page-wrapper'>
						
							<div className='chat-main-row'>
							<Breadcrumb>
								<BreadcrumbItem><Link to={`${URLS.userDashboardProfile}`}>Dashboard</Link></BreadcrumbItem>
								{
									activeProjectId ? 
									<BreadcrumbItem><Link to={`${URLS.userDashboardBase}/project-view/${activeProjectId}`}>{projects.find((project)=>project._id===activeProjectId).name}</Link></BreadcrumbItem>
									: null
								}
								<BreadcrumbItem active>Tasks</BreadcrumbItem>
							</Breadcrumb>
							{
							projectTasks ?
							<div className='chat-main-wrapper'>
								<div className='col-lg-7 message-view task-view task-left-sidebar'>
									<div className='chat-window'>
										<div className='fixed-header'>
											<div className='navbar'>
												<div className='float-left mr-auto'>
													<div className='add-task-btn-wrapper'></div>
												</div>
												<a className='task-chat profile-rightbar float-right' id='task_chat' href='#task_window'><i className='fa fa fa-comment'></i></a>
												<div className='float-right custom-menu'>
													<Form.Control as='select' value={taskFilter}
														onChange={this.changeFilter} style={{height:'35px'}}>
														<option value='ALL'>All Task</option>
														<option value='INCOMPLETE'>Pending Task</option>
														<option value='UNDER_REVIEW'>Under Reviewing Task</option>
														<option value='COMPLETE'>Completed Task</option>
													</Form.Control>
												</div>
											</div>
										</div>
										<div className='chat-contents'>
											<div className='chat-content-wrap'>
												<div className='chat-wrap-inner'>
													<div className='chat-box'>
														<div className='task-wrapper'>
															<div className='task-list-container'>
																<div className='task-list-body'>
																	<UserTaskFilter projectTasks={projectTasks} taskFilter={taskFilter} changeSelectedTask={this.changeSelectedTask.bind(this)} changeStatusOfTask={this.changeStatusOfTask} />
																</div>
															</div>
														</div>
														<div className='notification-popup hide'>
															<p>
																<span className='task'></span>
																<span className='notification-text'></span>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-5 message-view task-chat-view task-right-sidebar' id='task_window'>
									<div className='chat-window'>
										{ selectedTask &&
											<div className='fixed-header'>
												<div className='navbar'>
													<div className='task-assign' style={selectedTask.status==='COMPLETE'?{pointerEvents:'none'}:{}}>
														<a className={selectedTask.status === 'COMPLETE' ? 'task-complete-btn task-completed' : selectedTask.status === 'UNDER_REVIEW' ? 'task-complete-btn task-under-review' : 'task-complete-btn'} 
															href='Javascript:void(0);' onClick={this.changeStatusOfTaskFromButton(selectedTask)}>
															<i className='material-icons'>check</i> { selectedTask.status === 'COMPLETE' ? 'Completed' : selectedTask.status === 'UNDER_REVIEW' && leadersInfo[selectedTask.project] ? 'Approve as Completed' : selectedTask.status === 'UNDER_REVIEW' ? 'Under Review' : 'Mark Completed'}
														</a>
													</div>	
												</div>
											</div>
										}
										<div className='chat-contents task-chat-contents'>
											<div className='chat-content-wrap'>
												<div className='chat-wrap-inner'>
													<div className='chat-box'>
														{ selectedTask &&
															<div className='chats'>
																<h4>{selectedTask.taskName}</h4>
																<div className='task-header'>
																	<div className='assignee-info'>
																		<a href='Javascript:void(0);' data-toggle='modal' data-target='#assignee'>
																			<div className='avatar'>
																				<img alt='' src={teams[activeProjectId] && teams[activeProjectId].length && teams[activeProjectId].filter((member) => {
																					return selectedTask.assignedTo && member._id ===selectedTask.assignedTo;
																				}).map(emp => emp.emergencyInfo.image ? (URLS.backendStatic + '/' + emp.emergencyInfo.image) : 'assets/img/profiles/avatar-09.jpg')}/>
																			</div>
																			<div className='assigned-info'>
																				<div className='task-head-title'>Assigned To</div>
																				<div className='task-assignee'>{teams[activeProjectId] && teams[activeProjectId].length && teams[activeProjectId].map((member) => {
																					if(selectedTask.assignedTo && member._id ===selectedTask.assignedTo){
																						let name = member.personalInfo.name.first;
																						name += member.personalInfo.name.middle ? (' ' + member.personalInfo.name.middle) : '';
																						name += member.personalInfo.name.last ? (' ' + member.personalInfo.name.last) : '';
																						return name;
																					}
																				})}</div>
																			</div>
																		</a>
																	</div>
																</div>
																<div className='task-header'>
																	<div class="task-due-date" style={{cursor: 'not-allowed',margin: '0',padding: '0',flex: '0 0 130px'}}>
																		<div class="due-icon" style={{margin:'10px',display:'inline-block'}}>
																			<div className="cal-icon-custom" >
																				<input style={{cursor: 'not-allowed'}}/>
																			</div>
																		</div>
																		<div class="due-info">
																			<div class="task-head-title">Start Date</div>
																			<div class="due-date">{this.showDateInFormat(selectedTask.startDate)}</div>
																		</div>
																	</div>
																	<div class="task-due-date" style={{cursor: 'not-allowed',margin: '0',padding: '0',flex: '0 0 130px'}}>
																		<div class="due-icon" style={{margin:'10px',display:'inline-block'}}>
																			<div className="cal-icon-custom" >
																				<input style={{cursor: 'not-allowed'}}/>
																			</div>
																		</div>
																		<div class="due-info">
																			<div class="task-head-title">Due Date</div>
																			<div class="due-date">{this.showDateInFormat(selectedTask.dueDate)}</div>
																		</div>
																	</div>
																	{ selectedTask.status === 'COMPLETE' && selectedTask.completeDate ?
																	<div class="task-due-date" style={{cursor: 'not-allowed',margin: '0',padding: '0',flex: '0 0 140px'}}>
																		<div class="due-icon" style={{margin:'10px',display:'inline-block'}}>
																			<div className="cal-icon-custom" >
																				<input style={{cursor: 'not-allowed'}}/>
																			</div>
																		</div>
																		<div class="due-info">
																			<div class="task-head-title">Complete Date</div>
																			<div class="due-date">{this.showDateInFormat(selectedTask.completeDate)}</div>
																		</div>
																	</div> : ''
																	}
																
																	{/* <div className='task-due-date' id="datetimepicker1">
																		<label style={{cursor: 'pointer', width: '100%'}}>
																			<div className='due-icon' style={{margin:'10px',display:'inline-block'}}>
																				<div className="cal-icon-custom" >
																					<DatePicker selected={selectedTask.dueDate} onChange={this.updateTaskDueDate} />
																				</div>
																			</div>
																			<div className='due-info' style={{verticalAlign:'middle',display:'inline-block'}}>
																				<div className='task-head-title'>Due Date</div>
																				<div className='due-date'>{this.showDateInFormat(selectedTask.dueDate)}</div>
																			</div>
																		</label>
																	</div> */}

																</div>
																<hr class="task-line" />

																<div class="task-desc">
																	<div class="task-desc-icon">
																		<i class="material-icons">subject</i>
																	</div>
																	<div class="task-textarea">
																		<textarea class="form-control" placeholder="Description" value={ selectedTask.taskDetail ? selectedTask.taskDetail : '' } readOnly></textarea>
																	</div>
																</div>

																<hr className='task-line' />
																{
																	selectedTask.comments ? selectedTask.comments.map(comment =>{
																		return(
																			<div class="chat chat-left">
																				<div class="chat-avatar">
																					<a class="avatar">
																						<img alt="" src={comment.employeeImage ? (URLS.backendStatic + '/' + comment.employeeImage) : 'assets/img/profiles/avatar-02.jpg'}/>
																					</a>
																				</div>
																				<div class="chat-body">
																					<div class="chat-bubble">
																						<div class="chat-content">
																							<span class="task-chat-user">{comment.employeeName}</span> <span class="chat-time">{this.showDateInFormat(comment.date)} at {comment.time}</span>
																							{
																								comment.comment ?
																									<p>{comment.comment}</p>
																								: <p>
																									<a href={URLS.backendStatic+'/'+comment.file.unique + '.'+comment.file.ext} target='_blank'>
																									<span style={{marginBottom : '5px',marginRight : '10px',height:'22px',lineHeight:'22px',width:'22px'}} class="files-icon">
																										<i style={{fontSize : '14px'}} class="fa fa-file-pdf-o"></i></span>
																										{comment.file.original + '.'+comment.file.ext}
																									</a>
																									<br/>
																									{comment.file.message}
																								</p>
																							}
																						</div>
																					</div>
																				</div>
																			</div>)
																	}) : ''
																}	
															</div>
														}
														
													</div>
												</div>
											</div>
										</div>
										<div class="chat-footer" style={{position:'sticky', bottom:'0'}}>
											<div class="message-bar">
												<div class="message-inner">
													<label style={{height: '44px',margin: '0', cursor:'pointer'}}>
														<input id="new-file" style={{display:'none'}} id="upload-file" type="file" onChange={this.uploadCommentFile}/>
														<img src={"/assets/img/attachment.png"} alt=""/>
													</label>
													
													<div class="message-area">
														<div class="input-group">
															<textarea class="form-control" placeholder="Type message..." value={newComment.comment} onChange={this.updateNewComment}></textarea>
															<span class="input-group-append">
																<button class="btn btn-primary" type="button" onClick={this.submitComment}><i class="fa fa-send"></i></button>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							
							
								<div id='add_file' className='modal custom-modal fade' role='dialog'>
									<div className='modal-dialog modal-dialog-centered modal-lg'>
										<div className='modal-content'>
											<div className='modal-header'>
												<h5 className='modal-title'>Upload new file</h5>
												<button type='button' className='close' aria-label='Close' onClick={this.closeNewFilePanel}>
													<span aria-hidden='true'>&times;</span>
												</button>
											</div>
											<div className='modal-body'>
												<form onSubmit={this.addNewFile}>
													<div  className='form-group row'>
														<div className='col-sm-1'></div>
														<div className='col-sm-10'>
															<textarea class="form-control" placeholder="Add a message about file..." value={newFile.file.message} onChange={this.updateNewFileMessage}></textarea>
														</div>
													</div>
													<div  className='form-group row'>
														<div className='col-sm-1'></div>
														<div className='col-sm-10'>
															<div style={{display: 'flex', padding: '12px', border:'1px solid #eaeaea',background: '#eeeeee', borderRadius: '5px'}}>
																<div style={{position : 'relative',background: '#ffffff', borderRadius: '5px'}} >
																	<div style={{display : 'flex', padding:'12px'}}>
																		<span style={{marginRight : '10px'}} class="files-icon"><i class="fa fa-file-pdf-o"></i></span>
																		<div class="c-pillow_file__description">
																			<span class="c-pillow_file__title">{newFile.file.original ? (newFile.file.original +'.'+newFile.file.ext) : ''}</span>
																			{/* <div class="c-pillow_file__meta">
																				<button class="c-button-unstyled p-pending_file__edit_btn" type="button" aria-label="Edit file title">Edit</button>
																			</div> */}
																			<div class="c-pillow_file__meta">
																				<span>{newFile.file.size}</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															
														</div>
													</div>
													
													<div className='submit-section'>
														<button className='btn btn-primary submit-btn'>Upload</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>

							</div>
							:	<div className='chat-main-wrapper'>
								<h6 style={{textAlign:'center'}}> No task Assigned</h6>
								</div>
							}
						</div>
															
						{/*<!-- Task Followers Modal -->*/}
						<div id='task_followers' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Add followers to this task</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<div className='input-group m-b-30'>
											<input placeholder='Search to add' className='form-control search-input' type='text' />
											<span className='input-group-append'>
												<button className='btn btn-primary'>Search</button>
											</span>
										</div>
										<div>
											<ul className='chat-user-list'>
												<li>
													<a href='#'>
														<div className='media'>
															<span className='avatar'><img alt='' src='assets/img/profiles/avatar-16.jpg' /></span>
															<div className='media-body media-middle text-nowrap'>
																<div className='user-name'>Jeffery Lalor</div>
																<span className='designation'>Team Leader</span>
															</div>
														</div>
													</a>
												</li>
												<li>
													<a href='#'>
														<div className='media'>
															<span className='avatar'><img alt='' src='assets/img/profiles/avatar-08.jpg' /></span>
															<div className='media-body media-middle text-nowrap'>
																<div className='user-name'>Catherine Manseau</div>
																<span className='designation'>Android Developer</span>
															</div>
														</div>
													</a>
												</li>
												<li>
													<a href='#'>
														<div className='media'>
															<span className='avatar'><img alt='' src='assets/img/profiles/avatar-26.jpg' /></span>
															<div className='media-body media-middle text-nowrap'>
																<div className='user-name'>Wilmer Deluna</div>
																<span className='designation'>Team Leader</span>
															</div>
														</div>
													</a>
												</li>
											</ul>
										</div>
										<div className='submit-section'>
											<button className='btn btn-primary submit-btn'>Add to Follow</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Task Followers Modal -->*/}
						
					</div>
					{/*<!-- /Page Wrapper -->*/}
					

				</div>
				{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
						<div className='sidebar-overlay' data-reff=''></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(UserTasks))
