import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserTopbar from '../user-topbar/UserTopbar';
import UserSidebar from '../user-sidebar/UserSidebar';
import { URLS } from '../../urls';
import { Document, Page, pdfjs } from "react-pdf";
import DocumentModal from './DocumentModal';
import '../../../assets/css/date-picker.css';
import EmployeeSkills from "./EmployeeSkills";
import EmployeeTraining from "./EmployeeTraining";
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import Project from '../user-project/UserProject';
import AllTypeTasksUser from '../user-task/AllTypeTasksUser';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class UserDashboardProfile extends React.Component {
	constructor(props) {
		super(props);
		this.getAddressValue = this.getAddressValue.bind(this);
		this.getPhoneValue = this.getPhoneValue.bind(this);
		this.state = {
			show_add_job: false,
			show_add_location: false,
			show_add_employeeHMO: false,
			show_add_lifeInsurance: false,
			_id: null,
			displayImageSrc: null,
			displayResumeSrc: null,
			pdfPageNumber: 1,
			personalInfo: {
				name: {
					first: '',
					last: '',
					middle: ''
				},
				address: {
					apartment: '',
					street: '',
					city: '',
					state: '',
					zip: ''
				},
				phone: {
					home: '',
					alternate: ''
				},
				email: '',
				birth: '',
				govtId: '',
				martial: '',
				spouse: {
					name: '',
					employer: '',
					phone: ''
				},
				prevEmployee: {
					employee: '',
					phone: ''
				}
			},
			jobInfo: {
				title: '',
				employeeId: '',
				grade: '',
				supervisor: [],
				department: '',
				workLocation: '',
				email: '',
				workPhone: '',
				cellPhone: '',
				startDate: '',
				salary: '',
				status: '',
				salaryBasis: '',
				contract: '',
				contractType: '',
			},
			emergencyInfo: {
				name: {
					first: '',
					last: '',
					middle: ''
				},
				address: {
					apartment: '',
					street: '',
					city: '',
					state: '',
					zip: '',
				},
				phone: {
					primary: '',
					secondary: '',
				},
				relation: '',
				guarantor: {
					title: '',
					name: '',
					phone: '',
					address: '',
				},
				referee: {
					title: '',
					name: '',
					phone: '',
					address: '',
				},
				employeeHMO: '',
				lifeInsurance: '',
				image: '',
				resume: '',
				certificate: '',
			},
			educationInfo: [{
				institution: '',
				subject: '',
				startDate: '',
				completeDate: '',
				degree: '',
				grade: '',
				certificate: ''
			}],
			jobGrades: [],
			locations: [],
			departments: [],
			employeeHMOs: [],
			lifeInsurances: [],
			educationCertificateFiles: [{
				file: null
			}],
			supervisors: [],
			projects : [],
			editProject : '',
			clientList : [],
			employeeList : [],
			projectTasks : []
		};
	}
	componentWillMount() {
		const id = localStorage.getItem('userId');
		const token = 'dummy token';
		fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					_id: result.employee._id,
					personalInfo: result.employee.personalInfo,
					emergencyInfo: result.employee.emergencyInfo,
					educationInfo: result.employee.educationInfo
				});
				let jobInfoTemp = result.employee.jobInfo;
				jobInfoTemp.grade = jobInfoTemp.grade && jobInfoTemp.grade._id;
				jobInfoTemp.workLocation = jobInfoTemp.workLocation && jobInfoTemp.workLocation._id;
				jobInfoTemp.department = jobInfoTemp.department && jobInfoTemp.department._id;
				this.setState({
					jobInfo: jobInfoTemp,
				});
				const educationInfo = result.employee.educationInfo;
				const educationInfoLength = educationInfo.length;
				let newEducationCertificateFiles = [];
				for (let index = 0; index < educationInfoLength; index++) {
					newEducationCertificateFiles.push({ 'file': null });
				}
				this.setState({ educationCertificateFiles: newEducationCertificateFiles });
		});
		
		fetch(`${URLS.backendEmployees}/supervisors`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				if (result.supervisors) {
					this.setState({
						supervisors: result.supervisors,
					});
				}
		});

		fetch(`${URLS.backendProjects}/getProjectsByUserId/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			console.log("result.projects",result.projects);
			this.setState({
				projects: result.projects
			});
		});

		fetch(`${URLS.backendClients}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					clientList: result.clients,
				});
		});
	
		fetch(`${URLS.backendEmployees}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					employeesList: result.employees
				});
		});

		fetch(`${URLS.backendTasks}/getByUserId/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					projectTasks : result.tasks
				})
		});
		fetch(`${URLS.backendDepartments}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					departments: result.departments,
				});
		});

		fetch(`${URLS.backendEmployeeHMOs}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					employeeHMOs: result.employeeHMOs,
				});
		});

		fetch(`${URLS.backendLifeInsurances}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					lifeInsurances: result.lifeInsurances,
				});
		});

		fetch(`${URLS.backendLocations}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					locations: result.locations,
				});
		});
		
		fetch(`${URLS.backendJobGrades}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json())
			.then((result) => {
				this.setState({
					jobGrades: result.jobGrades,
				});
		});

	}
	getAddressValue = (apartment, street, city, state, zip) => {
		let result = '';
		if (apartment && apartment !== '') result = result.concat(apartment);
		if (street && street !== '') result = result.concat(' ' + street);
		if (city && city !== '') result = result.concat(', ' + city);
		if (state && state !== '') result = result.concat(', ' + state);
		if (zip && zip !== '') result = result.concat(' ' + zip);
		return result;

	};
	getPhoneValue = (phone1, phone2) => {
		let result = '';
		if (phone1 && phone1 !== '') result = result.concat(phone1);
		if (phone2 && phone2 !== '') {
			if (result !== '') result = result.concat(', ' + phone2);
			else result = result.concat(phone2);
		}
		return result;

	};
	onDocumentLoadSuccess = (e) => {
		console.log("success", e);
	};
	editProject = (_id) => {
		this.setState({ editProject: _id })
	};

	getEditProjectDataFromId = (id) => {
		const { projects } = this.state;
		return projects.find(item => id === item._id)
	};

	render() {
		const { personalInfo, jobInfo, emergencyInfo, educationInfo, jobGrades, departments, locations, educationCertificateFiles, displayImageSrc, displayResumeSrc, pdfPageNumber, employeeHMOs, lifeInsurances, supervisors,projects,editProject, employeesList, clientList, projectTasks } = this.state;
		const editProjectData = this.getEditProjectDataFromId(editProject);
		const profileImage = (emergencyInfo && emergencyInfo.image) ? (URLS.backendStatic + '/' + emergencyInfo.image) : '/assets/img/profiles/avatar-02.jpg';
		const supervisorProfileImage = (supervisors.length && jobInfo) ? supervisors.map((item) => { return item.id === jobInfo.supervisor ? (URLS.backendStatic + '/' + item.image) : '/assets/img/profiles/avatar-02.jpg' }) : '/assets/img/profiles/avatar-02.jpg';
		const displayImage = displayImageSrc ? displayImageSrc : URLS.backendStatic + '/' + emergencyInfo.image;
		const displayResume = displayResumeSrc ? displayResumeSrc : URLS.backendStatic + '/' + emergencyInfo.resume;
		let displayEducationCertificates = [];
		for (let i = 0; i < educationInfo.length; i++) {
			let sFileUrl = URLS.backendStatic + '/' + educationInfo[i].certificate;
			let fff = educationCertificateFiles[i] && educationCertificateFiles[i].file;
			if (fff) {
				displayEducationCertificates.push(fff);
			} else {
				displayEducationCertificates.push(sFileUrl);
			}
		}
		return (
			<body>
				<Helmet>
					<meta charSet='utf-8' />
					<meta name='robots' content='noindex, nofollow' />
					<title>Fexspace HR</title>
				</Helmet>
				<div className='main-wrapper'>
					<UserTopbar />
                    <UserSidebar />
					<div className='page-wrapper'>

						{/*<!-- Page Content -->*/}
						<div className='content container-fluid'>

							{/*<!-- Page Title -->*/}
							<div className='row'>
								<div className='col-sm-12'>
								<Breadcrumb>
									<BreadcrumbItem><Link to={URLS.userDashboardProfile}>Dashboard</Link></BreadcrumbItem>
									<BreadcrumbItem active>My Profile</BreadcrumbItem>
                        		</Breadcrumb>
								</div>
							</div>
							{/*<!-- /Page Title -->*/}

							<div className='card-box mb-0'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='profile-view'>
											<div className='profile-img-wrap'>
												<div className='profile-img'>
													<img alt='' src={profileImage} />
												</div>
											</div>
											<div className='profile-basic'>
												<div className='row'>
													<div className='col-md-5'>
														<div className='profile-info-left'>
															<h3 className='user-name m-t-0 mb-0'>{personalInfo.name.first + ' ' + personalInfo.name.middle + ' ' + personalInfo.name.last}</h3>
															<h6 className='text-muted'>{departments.length ? departments.map((item) => { return item._id === jobInfo.department ? item.name : '' }) : null}</h6>
															<small className='text-muted'>{jobInfo.title}</small>
															<div className='staff-id'>{jobInfo.employeeId}</div>
															<div className='small doj text-muted'>{jobInfo.startDate}</div>
															<div className='staff-msg'><a className='btn btn-custom' href='javascript:void(0);' >Send Message</a></div>
														</div>
													</div>
													<div className='col-md-7'>
														<ul className='personal-info'>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Phone: </div>
																<div className='text' style={{ flex: 1 }}><a href='javascript:void(0);'> {personalInfo.phone.home} </a></div>
															</div>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Email: </div>
																<div className='text' style={{ flex: 1 }}><a href='javascript:void(0);'> {personalInfo.email} </a></div>
															</div>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Birthday: </div>
																<div className='text' style={{ flex: 1 }}> {personalInfo.birth} </div>
															</div>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Address: </div>
																<div className='text' style={{ flex: 1 }}> {this.getAddressValue(personalInfo.address.apartment, personalInfo.address.street, personalInfo.address.city, personalInfo.address.state, personalInfo.address.zip)} </div>
															</div>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='card-box tab-box'>
								<div className='row user-tabs'>
									<div className='col-lg-12 col-md-12 col-sm-12 line-tabs'>
										<ul className='nav nav-tabs nav-tabs-bottom'>
											<li className='nav-item'><a href='#emp_profile' data-toggle='tab' className='nav-link active'>Profile</a></li>
											<li className='nav-item'><a href='#emp_projects' data-toggle='tab' className='nav-link'>Projects</a></li>
											<li className='nav-item'><a href='#tasks' data-toggle='tab' className='nav-link'>Tasks</a></li>
											<li className='nav-item'><a href='#emp_skills' data-toggle='tab' className='nav-link'>Skills/Certification</a></li>
											<li className='nav-item'><a href='#emp_trainings' data-toggle='tab' className='nav-link'>Training</a></li>
											<li className='nav-item'><a href='#emp_documents' data-toggle='tab' className='nav-link'>Documents</a></li>
										</ul>
									</div>
								</div>
							</div>

							<div className='tab-content'>

								{/*<!-- Profile Info Tab -->*/}
								<div id='emp_profile' className='pro-overview tab-pane fade show active'>
									<div className='row'>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Personal Information </h3>
												<ul className='personal-info'>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Name:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.name.first + ' ' + personalInfo.name.middle + ' ' + personalInfo.name.last}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Address:</div>
														<div className='text' style={{ flex: 1 }}>{this.getAddressValue(personalInfo.address.apartment, personalInfo.address.street, personalInfo.address.city, personalInfo.address.state, personalInfo.address.zip)}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Tel:</div>
														<div className='text' style={{ flex: 1 }}><a href='javascript:void(0);'>{this.getPhoneValue(personalInfo.phone.home, personalInfo.phone.alternate)}</a></div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Email:</div>
														<div className='text' style={{ flex: 1 }}><a href='javascript:void(0);'>{personalInfo.email}</a></div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Govt ID:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.govtId}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Birthday:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.birth}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Marital Status:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.martial}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Employment of spouse:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.spouse.name}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Spouse’s Employer:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.spouse.employer}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Spouse’s Work Phone:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.spouse.phone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Previous Employee:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.prevEmployee.employee}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Previous Employer Phone:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.prevEmployee.phone}</div>
													</div>
												</ul>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Emergency Contact</h3>
												<ul className='personal-info'>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Name:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.name.first + ' ' + emergencyInfo.name.middle + ' ' + emergencyInfo.name.last}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Address:</div>
														<div className='text' style={{ flex: 1 }}>{this.getAddressValue(emergencyInfo.address.apartment, emergencyInfo.address.street, emergencyInfo.address.city, emergencyInfo.address.state, emergencyInfo.address.zip)}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Phone: </div>
														<div className='text' style={{ flex: 1 }}>{this.getPhoneValue(emergencyInfo.phone.primary, emergencyInfo.phone.secondary)}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Relationship:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.relation}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Guarantor:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.guarantor.title + ' ' + emergencyInfo.guarantor.name}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Guarantor Phone:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.guarantor.phone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Guarantor Address:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.guarantor.address}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Referee:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.referee.title + ' ' + emergencyInfo.referee.name}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Referee Phone:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.referee.phone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Referee Address:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.referee.address}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Employee HMO:</div>
														<div className='text' style={{ flex: 1 }}>{employeeHMOs.length ? employeeHMOs.map((item) => { return item._id === emergencyInfo.employeeHMO ? item.name : '' }) : null}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Group Life Insurance:</div>
														<div className='text' style={{ flex: 1 }}>{lifeInsurances.length ? lifeInsurances.map((item) => { return item._id === emergencyInfo.lifeInsurance ? item.name : '' }) : null}</div>
													</div>
												</ul>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Job Information</h3>
												<ul className='personal-info'>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Job Title:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.title}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Employee ID:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.employeeId}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Job Grade:</div>
														<div className='text' style={{ flex: 1 }}>{jobGrades.length ? jobGrades.map((item) => { return item._id === jobInfo.grade ? item.name : '' }) : null}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Supervisor:</div>
														<div className='text' style={{ flex: 1 }}>
															{
																supervisors.length && jobInfo.supervisor.length && supervisors.map((item) => {
																	if(jobInfo.supervisor.indexOf(item.id) > -1)
																		return (<span>
																					<a href='javascript:void(0);' style={{textDecoration: 'underline'}}>{item.name}</a>&nbsp;&nbsp;
																			 </span>);
																	else
																		return <span></span>;
																	}
																)
															}
														</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Department:</div>
														<div className='text' style={{ flex: 1 }}>{departments.length ? departments.map((item) => { return item._id === jobInfo.department ? item.name : '' }) : null}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Work Location:</div>
														<div className='text' style={{ flex: 1 }}>{locations.length ? locations.map((item) => { return item._id === jobInfo.workLocation ? item.name : '' }) : null}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Email:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.email}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Work Phone:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.workPhone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Cell Phone:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.cellPhone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Start Date:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.startDate}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Salary:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.salary + ' ' + jobInfo.salaryBasis}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Status:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.status}</div>
													</div>
												</ul>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Education Information</h3>
												<div className='experience-box'>
													<ul className='experience-list'>
														{educationInfo.map((education) => {
															return (
																<li>
																	<div className='experience-user'>
																		<div className='before-circle'></div>
																	</div>
																	<div className='experience-content'>
																		<div className='timeline-content'>
																			<a href='javascript:void(0);' className='name'>{education.institution}</a>
																			<div>{education.subject}</div>
																			<div>Grade: {education.grade}</div>
																			<span className='time'>{education.startDate + ' - ' + education.completeDate}</span>
																		</div>
																	</div>
																</li>
															)
														})}
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*<!-- /Profile Info Tab -->*/}

								{/*<!-- Projects Tab -->*/}
								<div className='tab-pane fade' id='emp_projects'>
									<div className="row staff-grid-row">
										{projects.length ? projects.map((project) => {
											let projectTeam = [];
											let projectLeader = [];
											if(project.team && project.team.length){
												projectTeam = project.team.map(employee => {
													let name = employee.personalInfo.name.first;
													name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
													name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
													const id = employee._id;
													const image = employee.emergencyInfo.image;
													return {name: name, id: id, image: image};							
												})
											}
											if(project.projectLeader && project.projectLeader.length){
												projectLeader = project.projectLeader.map(employee => {
													let name = employee.personalInfo.name.first;
													name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
													name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
													const id = employee._id;
													const image = employee.emergencyInfo.image;
													return {name: name, id: id, image: image};
												})
											}
											return <Project key={project._id} project={project} projectTeamList={projectTeam} projectLeaderList={projectLeader} onEdit={this.editProject} />
										}):''}
									</div>
								</div>
								{/*<!-- /Projects Tab -->*/}
							
								<div className='tab-pane fade' id='emp_skills'>
									<EmployeeSkills eId={this.state._id} />
								</div>
								<div className='tab-pane fade' id='emp_trainings'>
									<EmployeeTraining eId={this.state._id} />
								</div>
								<div className='tab-pane fade' id='emp_documents'>
									<DocumentModal eId={this.state._id}/>
								</div>
								<div id='tasks' className='tab-pane fade'>
									<div className='project-task'>
										<ul className='nav nav-tabs nav-tabs-top nav-justified mb-0'>
											<li className='nav-item'><a className='nav-link active' href='#all_tasks' data-toggle='tab' aria-expanded='true'>All Tasks</a></li>
											<li className='nav-item'><a className='nav-link' href='#pending_tasks' data-toggle='tab' aria-expanded='false'>Pending Tasks</a></li>
											<li className='nav-item'><a className='nav-link' href='#completed_tasks' data-toggle='tab' aria-expanded='false'>Completed Tasks</a></li>
										</ul>
										<AllTypeTasksUser projectTasks={projectTasks}/>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Page Content -->*/}
					</div>
					{/*<!-- /Page Wrapper -->*/}
				</div>
				{/*<!-- /Main Wrapper -->*/}
				{/* <!-- Sidebar Overlay --> */}
				<div className='sidebar-overlay' data-reff=''></div>
			</body>
		)
	}
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(UserDashboardProfile))