/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Form, Col, Row, Image } from 'react-bootstrap';
import { pdfjs, Document, Page } from 'react-pdf';
import '../../assets/css/project-files.css';
import '../../../src/assets/css/date-picker.css';
import '../../../src/assets/css/image-list.css';
import { URLS } from '../urls';
import Multiselect from './Multiselect'
import ImageNameList from './ImageNameList';
import moment from 'moment';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class TeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.removeFormList = this.removeFormList.bind(this);
        this.state = {
            buttonText : 'Submit',
            isWaiting : false,
            employee: null,
            team: {
                name : '',
                description : '',
                members : [],
                leaders : []
            },
            possibleTeamMembers : [],
            possibleTeamLeaders : [],
            employeesList : [],
            selectedTeamMembers : [],
            selectedTeamLeaders :[]
        };
    }

    updateTeamField = event => {
        const { team } = this.state;
        const {name, value} = event.target;
        team[name] = value;
        this.setState({
            team
        });
    }
    updateSelectedTeamLeadersList = (params) => {
        const { team, employeesList } = this.state;
        team.leaders = params.map(item => item.id)
        const members = employeesList.filter(ar => !params.find(rm => (rm.id === ar.id) ))
        this.setState({
            team,
            selectedTeamLeaders : params,
            possibleTeamMembers : members
        })
    }

    updateSelectedTeamMembersList = (params) => {
        const { team, employeesList } = this.state;
        team.members = params.map(item => item.id)
        const leaders = employeesList.filter(ar => !params.find(rm => (rm.id === ar.id) ))
        this.setState({
            team,
            selectedTeamMembers : params,
            possibleTeamLeaders : leaders
        })
    }

    fetchTeam = (id) =>{
        const token = 'dummy token'
        fetch(`${URLS.backendTeams}/get/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			if(result.team){
                const oldTeam = {};
                oldTeam.leaders = result.team.leaders.map(emp => emp.id);
                oldTeam.members = result.team.members.map(emp => emp.id);
                this.setState({oldTeam})
            }
        });
    }
    componentWillMount(){
        const token = 'dummy token'
        const id = localStorage.getItem('userId');
		fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({
				employee : result.employee
			})
		});
    }
    componentWillReceiveProps(props) {
        if (props.employeesList && props.employeesList.length){
            this.setState({
                employeesList : props.employeesList,
                possibleTeamLeaders : props.employeesList,
                possibleTeamMembers : props.employeesList
            })
        }
        if(props.team){
            this.setState({
                selectedTeamLeaders : props.team.leaders,
                selectedTeamMembers : props.team.members
            })
            // const team = props.team;
            // team.members = team.members.map(member => member.id)
            // team.leaders = team.leaders.map(leader => leader.id)
   
            this.setState({
                team : props.team
            })

            const {team} = this.state;
            team.members = team.members.map(member => member.id)
            team.leaders = team.leaders.map(leader => leader.id)
            this.setState({
                team
            })

            this.fetchTeam(props.team._id);
        }
    }

    submit = (e) => {
        e.preventDefault();
        this.setState({buttonText:'Submitting ...',isWaiting:true})
        const { team,employeesList } = this.state;
        const userId = localStorage.getItem('userId');
        team.totalSize = team.members.length + team.leaders.length;
        team.members = team.members.map(member => {
            return employeesList.find(employee => employee.id === member)
        })
        team.leaders = team.leaders.map(leader => {
            return employeesList.find(employee => employee.id === leader)
        })
        team.lastModified = moment(new Date()).format("YYYY-MM-DD HH:mm Z")
        team.lastModifiedBy = userId;
        const token = 'dummy token';
        fetch(`${URLS.backendTeams}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify({team: team}),
        }).then(res => res.json()).then((result) => {
            setTimeout(()=>{
                window.$("#edit_team").modal("toggle");
                this.setState({ buttonText: 'Submit', isWaiting: false });
            }, 500);
            this.props.updateTeamsList(result.team);
            this.generateTeamNotification(result.team);
        });
    };
    removeFormList = item => {
        let {team, selectedTeamLeaders, selectedTeamMembers, employeesList } = this.state;
        let index1 = selectedTeamMembers.indexOf(item);
        let index2 = selectedTeamLeaders.indexOf(item);
        if ( index1 > -1){
            selectedTeamMembers.splice(index1, 1);
            team.members = selectedTeamMembers.map(i => i.id);
        }
        else if ( index2 > -1){
            selectedTeamLeaders.splice(index2, 1);
            team.leaders = selectedTeamLeaders.map(i => i.id);
        }
        this.setState({
            team : team,
            selectedTeamLeaders : selectedTeamLeaders,
            selectedTeamMembers : selectedTeamMembers,
            possibleTeamMembers : employeesList.filter(ar => !selectedTeamLeaders.find(rm => (rm.id === ar.id) )),
            possibleTeamLeaders : employeesList.filter(ar => !selectedTeamMembers.find(rm => (rm.id === ar.id) ))
        })
    }


    generateTeamNotification = (team) =>{
		const {employee, oldTeam} = this.state;
		const employeeName = employee.personalInfo.name.first +' '+ employee.personalInfo.name.last;
        // team.leaders = team.leaders.map(emp => emp.id);
        // team.members = team.members.map(emp => emp.id);
        console.log("oldProject",oldTeam);
        console.log("project",team);
        if(employee != null){
			const notification = {}
			notification.employeeFrom = employee._id;
			notification.target =  'team-view/'+team._id;
            notification.createDate = new Date();
            
            team.leaders.forEach(emp => {
                if(oldTeam.leaders.indexOf(emp) === -1){
                     notification.employeeTo = emp;
                     notification.tag = 'ADDED_LEADER_TEAM';
                     notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> added you as team leader to team <span class="noti-title"><b>'+team.name+'</b></span>';		
                     this.submitNotificationOnServer(notification);
                 }
             });
 
             team.members.map(emp => {
                 if(oldTeam.members.indexOf(emp) === -1){
                     notification.employeeTo = emp;
                     notification.tag = 'ADDED_MEMBER_TEAM';
                     notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> added you as team member to team <span class="noti-title"><b>'+team.name+'</b></span>';		
                     this.submitNotificationOnServer(notification);
                 }
             });
 
            oldTeam.leaders.map(emp => {
                 if(team.leaders.indexOf(emp) === -1){
                     notification.employeeTo = emp;
                     notification.target =  'teams';
                     notification.tag = 'REMOVED_LEADER_TEAM';
                     notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> removed you from team <span class="noti-title"><b>'+team.name+'</b></span>';		
                     this.submitNotificationOnServer(notification);
                 }
             });
 
             oldTeam.members.map(emp => {
                 if(team.members.indexOf(emp) === -1){
                     notification.employeeTo = emp;
                     notification.target =  'teams';
                     notification.tag = 'REMOVED_MEMBER_TEAM';
                     notification.message = '<span class="noti-title"> <b>'+employeeName+'</b></span> removed you from team <span class="noti-title"><b>'+team.name+'</b></span>';		
                     this.submitNotificationOnServer(notification);
                 }
             });

            this.fetchTeam(team._id);
		}
	}

    submitNotificationOnServer = (notification) =>{
		const token = 'dummy token';
		fetch(`${URLS.backendNotifications}/addTaskNotification`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
			body : JSON.stringify({notification : notification})
		}).then(res => res.json()).then((result) => {
			if(result.notification){
				console.log("Notification created successfully", result.notification);
			}
		});
	}

    render() {
        const {team, possibleTeamLeaders, possibleTeamMembers, selectedTeamLeaders, selectedTeamMembers} = this.state;
        return (
            <div id='edit_team' className="modal custom-modal fade" role="dialog">
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Create Team</h5>
                            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <Form onSubmit={this.submit}>
                                <Form.Group as={Row}>
                                    <Col sm={12}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Team Name
                                            </Form.Label>
                                        <Form.Control placeholder='Team name' name='name' value={team.name}
                                            onChange={this.updateTeamField} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col sm={5}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Add Team Leader
                                            </Form.Label>
                                            <br />
                                           {
                                                possibleTeamLeaders.length > 0 && <Multiselect options={ possibleTeamLeaders.filter(ar => !selectedTeamMembers.find(rm => (rm.id === ar.id) ))} 
                                                onSelectOptions={this.updateSelectedTeamLeadersList} selectedOptions={selectedTeamLeaders.map(item => item.id)} />
                                            }
                                        <br />
                                    </Col>
                                    <Col sm={7}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Team Leaders
                                            </Form.Label>
                                            <br />
                                            <ImageNameList value={selectedTeamLeaders} removeFormList={this.removeFormList}/>
                                            <br />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col sm={5}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Add Team Member
                                            </Form.Label>
                                            <br />
                                           {
                                                possibleTeamMembers.length > 0 && <Multiselect options={possibleTeamMembers.filter(ar => !selectedTeamLeaders.find(rm => (rm.id === ar.id) ))}
                                                onSelectOptions={this.updateSelectedTeamMembersList} selectedOptions={ selectedTeamMembers.map(item => item.id)} />
                                            }
                                        <br />
                                    </Col>
                                    <Col sm={7}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Team Members
                                            </Form.Label>
                                            <br />
                                            <ImageNameList value={selectedTeamMembers} removeFormList={this.removeFormList} />
                                            <br />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={12}>
                                        <Form.Label style={{ fontSize: '14px' }}>
                                            Team Description
                                        </Form.Label>
                                        <textarea rows="3" class="form-control summernote" name='description' onChange={this.updateTeamField} value={team.description}></textarea>
                                    </Col>
                                </Form.Group>
                                <div className='submit-section'>
                                    <button className='btn btn-primary submit-btn' >{this.state.buttonText}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}