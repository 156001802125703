import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class Estimates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-sm-5 col-4">
							<h4 className="page-title">Estimates</h4>
						</div>
						<div className="col-sm-7 col-8 text-right m-b-30">
							<a href="create-estimate.html" className="btn add-btn"><i className="fa fa-plus"></i> Create Estimate</a>
						</div>
					</div>
					{/*<!-- Page Title -->*/}
					
					{/*<!-- Search Filter -->*/}
					<div className="row filter-row">
						<div className="col-sm-6 col-md-3">  
							<div className="form-group form-focus">
								<div className="cal-icon">
									<input className="form-control floating datetimepicker" type="text" />
								</div>
								<label className="focus-label">From</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3">  
							<div className="form-group form-focus">
								<div className="cal-icon">
									<input className="form-control floating datetimepicker" type="text" />
								</div>
								<label className="focus-label">To</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3"> 
							<div className="form-group form-focus select-focus">
								<select className="select floating"> 
									<option>Select Status</option>
									<option>Accepted</option>
									<option>Declined</option>
									<option>Expired</option>
								</select>
								<label className="focus-label">Status</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3">  
							<a href="#" className="btn btn-success btn-block"> Search </a>  
						</div>     
                    </div>
					{/*<!-- /Search Filter -->*/}
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table mb-0">
									<thead>
										<tr>
											<th>Estimate Number</th>
											<th>Client</th>
											<th>Estimate Date</th>
											<th>Expiry Date</th>
											<th>Amount</th>
											<th>Status</th>
											<th className="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td><a href="estimate-view.html">EST-0001</a></td>
											<td>Global Technologies</td>
											<td>11 Mar 2019</td>
											<td>17 Mar 2019</td>
											<td>$2099</td>
											<td><span className="badge badge-success-border">Accepted</span></td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="edit-estimate.html"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_estimate"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td><a href="estimate-view.html">EST-0002</a></td>
											<td>Delta Infotech</td>
											<td>11 Mar 2019</td>
											<td>17 Mar 2019</td>
											<td>$2099</td>
											<td><span className="badge badge-danger-border">Declined</span></td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="edit-estimate.html"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_estimate"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td><a href="estimate-view.html">EST-0003</a></td>
											<td>Cream Inc</td>
											<td>11 Mar 2019</td>
											<td>17 Mar 2019</td>
											<td>$2099</td>
											<td><span className="badge badge-info-border">Sent</span></td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="edit-estimate.html"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_estimate"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td><a href="estimate-view.html">EST-0004</a></td>
											<td>International Software Inc</td>
											<td>11 Mar 2019</td>
											<td>17 Mar 2019</td>
											<td>$2099</td>
											<td><span className="badge badge-warning-border">Expired</span></td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="edit-estimate.html"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_estimate"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
				
				{/*<!-- Delete Estimate Modal -->*/}
				<div className="modal custom-modal fade" id="delete_estimate" role="dialog">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<div className="form-header">
									<h3>Delete Estimate</h3>
									<p>Are you sure want to delete?</p>
								</div>
								<div className="modal-btn delete-action">
									<div className="row">
										<div className="col-6">
											<a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a>
										</div>
										<div className="col-6">
											<a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Delete Estimate Modal -->*/}
			
            </div>
			{/*<!-- /Page Wrapper -->*/}
			
        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Estimates))

