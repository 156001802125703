import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";

class TopbarGuest extends Component {
  render() {
    return (
      <div className="header" style={{ background: "#020e28" }}>
        {/* <!-- Logo --> */}
        <div className="header-left">
          <a href="https://www.daedaltechng.com/" className="logo">
            <img
              src={URLS.landingPage + "/assets/img/logo-2.png"}
              width="130px"
              height="50px"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </a>
        </div>
        {/* <!-- /Logo --> */}
        <a id="toggle_btn" href="javascript:void(0);">
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        {/* <!-- Header Title --> */}
        <div class="page-title-box float-left">
          <h4 style={{ color: "#fff" }}>Daedal HR</h4>
        </div>
        {/* <!-- /Header Title --> */}

        {/* <!-- Header Menu --> */}
        <ul class="nav user-menu">
          <li class="nav-item">
            <a class="nav-link" href="https://www.daedaltechng.com/">
              Home
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.daedaltechng.com/about.html">
              About Us
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://www.daedaltechng.com/contact.html"
            >
              Contact Us
            </a>
          </li>
        </ul>
        {/* <!-- /Header Menu --> */}

        {/* <!-- Mobile Menu --> */}
        <div class="dropdown mobile-user-menu">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-v"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="https://www.daedaltechng.com/">
              Home
            </a>
            <a
              class="dropdown-item"
              href="https://www.daedaltechng.com/about.html"
            >
              About Us
            </a>
            <a
              class="dropdown-item"
              href="https://www.daedaltechng.com/contact.html"
            >
              Contact Us
            </a>
          </div>
        </div>
        {/* <!-- /Mobile Menu --> */}
      </div>
    );
  }
}

export default TopbarGuest;
