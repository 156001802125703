import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

class Promotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

	render() {
		return (
            <body>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex, nofollow"></meta>
                    <title>Fexspace HR</title>
                </Helmet>
                <div className="main-wrapper">
		
        	<Topbar />
        	<Sidebar />
{/*<!-- Page Wrapper -->*/}
            <div className="page-wrapper">
			
				{/*<!-- Page Content -->*/}
                <div className="content container-fluid">
				
					{/*<!-- Page Title -->*/}
					<div className="row">
						<div className="col-sm-5 col-5">
							<h4 className="page-title">Promotion</h4>
						</div>
						{/* <div className="col-sm-7 col-7 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_promotion"><i className="fa fa-plus"></i> Add Promotion</a>
						</div> */}
					</div>
					{/*<!-- /Page Title -->*/}
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
							
								{/*<!-- Promotion Table -->*/}
								{/* <table className="table table-striped custom-table mb-0 datatable">
									<thead>
										<tr>
											<th>#</th>
											<th>Promoted Employee </th>
											<th>Department</th>
											<th>Promotion Designation From </th>
											<th>Promotion Designation To </th>
											<th>Promotion Date </th>
											<th className="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>
												<h2 className="table-avatar blue-link">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-02.jpg" /></a>
													<a href="profile.html">John Doe</a>
												</h2>
											</td>
											<td>Web Development</td>
											<td>Web Developer</td>
											<td>Sr Web Developer</td>
											<td>28 Feb 2019</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_promotion"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_promotion"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>*/}
								{/*<!-- /Promotion Table --> */}
								
							</div>
						</div>
					</div>
                </div>
				{/*<!-- /Page Content -->*/}
				
				{/*<!-- Add Promotion Modal -->*/}
				<div id="add_promotion" className="modal custom-modal fade" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add Promotion</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label>Promotion For <span className="text-danger">*</span></label>
										<input className="form-control" type="text" />
									</div>
									<div className="form-group">
										<label>Promotion From <span className="text-danger">*</span></label>
										<input className="form-control" type="text" value="Web Developer" readonly />
									</div>
									<div className="form-group">
										<label>Promotion To <span className="text-danger">*</span></label>
										<select className="select">
											<option>Web Developer</option>
											<option>Web Designer</option>
											<option>SEO Analyst</option>
										</select>
									</div>
									<div className="form-group">
										<label>Promotion Date <span className="text-danger">*</span></label>
										<div className="cal-icon">
											<input type="text" className="form-control datetimepicker" />
										</div>
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Add Promotion Modal -->*/}
				
				{/*<!-- Edit Promotion Modal -->*/}
				<div id="edit_promotion" className="modal custom-modal fade" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Promotion</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label>Promotion For <span className="text-danger">*</span></label>
										<input className="form-control" type="text" value="John Doe" />
									</div>
									<div className="form-group">
										<label>Promotion From <span className="text-danger">*</span></label>
										<input className="form-control" type="text" value="Web Developer" readonly />
									</div>
									<div className="form-group">
										<label>Promotion To <span className="text-danger">*</span></label>
										<select className="select">
											<option>Web Developer</option>
											<option>Web Designer</option>
											<option>SEO Analyst</option>
										</select>
									</div>
									<div className="form-group">
										<label>Promotion Date <span className="text-danger">*</span></label>
										<div className="cal-icon">
											<input type="text" className="form-control datetimepicker" />
										</div>
									</div>
									<div className="submit-section">
										<button className="btn btn-primary submit-btn">Save</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Edit Promotion Modal -->*/}
				
				{/*<!-- Delete Promotion Modal -->*/}
				<div className="modal custom-modal fade" id="delete_promotion" role="dialog">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<div className="form-header">
									<h3>Delete Promotion</h3>
									<p>Are you sure want to delete?</p>
								</div>
								<div className="modal-btn delete-action">
									<div className="row">
										<div className="col-6">
											<a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a>
										</div>
										<div className="col-6">
											<a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<!-- /Delete Promotion Modal -->*/}
			
            </div>
			{/*<!-- /Page Wrapper -->*/}

        </div>
		{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
                <div className="sidebar-overlay" data-reff=""></div>
            </body>
        )
    }
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Promotion))

