import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import EditProjectModal from '../projects/EditProjectModal'

import { Helmet } from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import { URLS } from '../urls';
import AllTypeTasks from '../tasks/AllTypeTasksClient';
import AddMemberModal from './AddMemberModal';
import AddLeaderModal from './AddLeaderModal';

class ProjectView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			project: {
				name: '',
				client: null,
				startDate: '',
				endDate: '',
				cost: 0,
                duration: 0,
                durationType: 'Hours',
				priority: 'High',
				projectLeader: [],
				team: [],
				description: '',
				files: [],
				status : 'Working',
				createdBy : null
			},
			editProject: '',
			completedTaskCount: 0,
			inCompletedTaskCount: 0,
			underReviewedTaskCount : 0,
			projectTasks: [],
			completedTask: [],
			inCompletedTask: [],
			underReviewedTask :[],
			clientList : [],
			employeesList : []
		};
	}

	editProject = (_id) => {
		this.setState({ editProject: _id })
	};

	getEditProjectDataFromId = (id) => {
		const { projects } = this.state;
		// return projects.find(item => id === item._id)
		return this.state.project
	};
	addNewMember = (id) =>{
		const { project}  = this.state;
		if(project.team.find(m => m._id===id)){
			alert("Already a team member.");
			return;
		}else{
			project.team = project.team.map(m => m._id);
			project.team.push(id);
			project.projectLeader = project.projectLeader.map(m => m._id);

			let formData = new FormData();
			formData.append('project', JSON.stringify({ ...project }));

			const token = 'dummy token'; // localStorage.getItem('token');
			fetch(`${URLS.backendProjects}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					Authorization: `Token ${token}`,
				},
				body: formData,
			})
				.then(res => res.json())
				.then((result) => {
					this.setState({
						project : result.project
					})
			});	
		}
	}
	addNewLeader = (id) =>{
		const { project}  = this.state;
		if(project.projectLeader.find(m => m._id===id)){
			alert("Already a team leader.");
			return;
		}else{
			project.team = project.team.map(m => m._id);
			project.projectLeader = project.projectLeader.map(m => m._id);
			project.projectLeader.push(id);

			let formData = new FormData();
			formData.append('project', JSON.stringify({ ...project }));

			const token = 'dummy token'; // localStorage.getItem('token');
			fetch(`${URLS.backendProjects}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					Authorization: `Token ${token}`,
				},
				body: formData,
			})
				.then(res => res.json())
				.then((result) => {
					this.setState({
						project : result.project
					})
			});	
		}
	}

	// changeStatusOfTask = (task) => (event) => {
	// 	const token = 'dummy token';
	// 	const taskId = task._id;
	// 	// const { activeProjectId, tasks } = this.state;
	// 	task.status = task.status === 'COMPLETE' ? 'INCOMPLETE' : 'COMPLETE'
	// 	fetch(`${URLS.backendTasks}/update`, {
	// 		method: 'POST',
	// 		headers: {
	// 			Accept: 'application/json',
	// 			'Content-Type': 'application/json',
	// 			Authorization: `Token ${token}`,
	// 		},
	// 		body: JSON.stringify({ task: task })
	// 	}).then(res => res.json())
	// 		.then((result) => {
	// 			let { projectTasks, completedTask, inCompletedTask } = this.state
	// 			if (result.task.status === 'COMPLETE') {
	// 				completedTask.push(result.task)
	// 				console.log("inCompletedTask", inCompletedTask)
	// 				inCompletedTask = inCompletedTask.filter(item => {
	// 					console.log(item._id, result.task._id)
	// 					return item._id != result.task._id
	// 				})
	// 				console.log("inCompletedTask", inCompletedTask)
	// 			} else {
	// 				console.log("completedTask", completedTask)
	// 				completedTask = completedTask.filter(item => {
	// 					console.log(item._id, result.task._id)
	// 					return item._id != result.task._id
	// 				})
	// 				console.log("completedTask", completedTask)
	// 				inCompletedTask.push(result.task)
	// 			}


	// 			this.state.completedTask.forEach(projectTask => {
	// 				if (projectTask._id === taskId) {
	// 					projectTask.status = projectTask.status === 'COMPLETE' ? 'COMPLETE' : 'INCOMPLETE';
	// 				}
	// 			});
	// 			this.setState({
	// 				// tasks: tasks,
	// 				selectedTask: result.task,
	// 				completedTask,
	// 				inCompletedTask
	// 			});
	// 			console.log('....result......', result);
	// 		});
	// }

	// changeSelectedTask = (task) => (evt) => {
	// 	this.setState({
	// 		selectedTask: task
	// 	});
	// }
	
	
	componentWillMount() {
		console.log("this.props.location.pathname",this.props.location.pathname)
		const id = window.location.pathname.split('/')[3];
		const token = 'dummy token'; // localStorage.getItem('token');
		fetch(`${URLS.backendProjects}/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({ project: result.project })
		});

		fetch(`${URLS.backendTasks}/getByProjectId/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			const tasks = result.tasks || [];
			const completedTask = tasks.filter(task => {return task.status === "COMPLETE"})
			const completedTaskCount = completedTask.length
			const inCompletedTask = tasks.filter(task => {return task.status === "INCOMPLETE"})
			const inCompletedTaskCount = inCompletedTask.length
			const underReviewedTask = tasks.filter(task => {return task.status === "UNDER_REVIEW"})
			const underReviewedTaskCount = underReviewedTask.length
			this.setState({
				completedTask,
				inCompletedTask,
				underReviewedTask,
				completedTaskCount,
				inCompletedTaskCount,
				underReviewedTaskCount,
				projectTasks: tasks
			})	
		});

		fetch(`${URLS.backendClients}/get`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({ clientList: result.clients });
		});
	
		fetch(`${URLS.backendEmployees}/get`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}).then(res => res.json()).then((result) => {
			this.setState({ employeesList: result.employees });
		});
	}

	updateProjectsList = (_project) => {
		this.setState({
			project : _project
		})
		console.log("Nothing updated")
	};
	render() {
		const { project, editProject, projectTasks, completedTaskCount, inCompletedTaskCount,underReviewedTaskCount,clientList,employeesList } = this.state;
		const editProjectData = this.getEditProjectDataFromId(editProject);
		console.log("editProject",editProject,"editProjectData",editProjectData);
		const progress = (completedTaskCount + inCompletedTaskCount+underReviewedTaskCount > 0) ? (completedTaskCount*100 / (completedTaskCount + inCompletedTaskCount+underReviewedTaskCount)).toFixed(2) : 0
		return (
			<body>
				<Helmet>
					<meta charSet="utf-8" />
					<meta name="robots" content="noindex, nofollow"></meta>
					<title>Fexspace HR</title>
				</Helmet>
				<div className="main-wrapper">

					<Topbar />
					<Sidebar />
					{/*<!-- Page Wrapper -->*/}
					<div className="page-wrapper">

						{/*<!-- Page Content -->*/}
						<div className="content container-fluid">

							{/*<!-- Page Title -->*/}
							<div className="row">
								<div className="col-sm-7 col-5">
								<Breadcrumb>
									<BreadcrumbItem><Link to={`${URLS.dashboard}`}>Dashboard</Link></BreadcrumbItem>
                            		<BreadcrumbItem><Link to={`${URLS.projects}`}>Projects</Link></BreadcrumbItem>
                            		<BreadcrumbItem active>{project.name}</BreadcrumbItem>
                        		</Breadcrumb>
								{/* <h4 className="page-title">{project.name}</h4>
									<h4 className="page-title"></h4> */}
								</div>
								<div className="col-sm-5 col-7 text-right m-b-30">
									<a href='Javascript:void(0);' className="btn add-btn" data-toggle="modal" data-target="#edit_project"><i className="fa fa-plus"></i> Edit Project</a>
								</div>
							</div>
							{/*<!-- /Page Title -->*/}

							<div className="row">
								<div className="col-lg-8 col-xl-9">
									<div className="card">
										<div className="card-body">
											<div className="project-title">
												<h5 className="card-title">{project.name}</h5>
												<small className="block text-ellipsis m-b-15"><span className="text-xs">{inCompletedTaskCount+underReviewedTaskCount}</span> <span className="text-muted">open tasks, </span><span className="text-xs">{completedTaskCount}</span> <span className="text-muted">tasks completed</span></small>
											</div>
											{project.description}
										</div>
									</div>
									{/* <div className="card">
										<div className="card-body">
											<h5 className="card-title m-b-20">Uploaded image files</h5>
											<div className="row">
												<div className="col-md-3 col-sm-4 col-lg-4 col-xl-3">
													<div className="uploaded-box">
														<div className="uploaded-img">
															<img src="assets/img/placeholder.jpg" className="img-fluid" alt="" />
														</div>
														<div className="uploaded-img-name">
															demo.png
												</div>
													</div>
												</div>
												<div className="col-md-3 col-sm-4 col-lg-4 col-xl-3">
													<div className="uploaded-box">
														<div className="uploaded-img">
															<img src="assets/img/placeholder.jpg" className="img-fluid" alt="" />
														</div>
														<div className="uploaded-img-name">
															demo.png
												</div>
													</div>
												</div>
												<div className="col-md-3 col-sm-4 col-lg-4 col-xl-3">
													<div className="uploaded-box">
														<div className="uploaded-img">
															<img src="assets/img/placeholder.jpg" className="img-fluid" alt="" />
														</div>
														<div className="uploaded-img-name">
															demo.png
												</div>
													</div>
												</div>
												<div className="col-md-3 col-sm-4 col-lg-4 col-xl-3">
													<div className="uploaded-box">
														<div className="uploaded-img">
															<img src="assets/img/placeholder.jpg" className="img-fluid" alt="" />
														</div>
														<div className="uploaded-img-name">
															demo.png
												</div>
													</div>
												</div>
											</div>
										</div>
									</div> */}
									<div className="card">
										<div className="card-body">
											<h5 className="card-title m-b-20">Uploaded files</h5>
											<ul className="files-list">
												{
													project.files.length ? project.files.map((file)=>{
														return(
															<li>
																<div className="files-cont">
																	<div className="file-type">
																		<span className="files-icon"><i className="fa fa-file-pdf-o"></i></span>
																	</div>
																	<div className="files-info">
																		<span className="file-name text-ellipsis"><a href={`${URLS.backendStatic}/${file.uniqueName+'.'+file.extension}`} target='_blank'>{file.originalName+'.'+file.extension}</a></span>
																		<div className="file-size">{file.size}</div>
																	</div>
																</div>
															</li>
														)
													}) : null
												}
											</ul>
										</div>
									</div>
									<div className="project-task">
										<ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
											<li className="nav-item"><a className="nav-link active" href="#all_tasks" data-toggle="tab" aria-expanded="true">All Tasks</a></li>
											<li className="nav-item"><a className="nav-link" href="#pending_tasks" data-toggle="tab" aria-expanded="false">Pending Tasks</a></li>
											<li className="nav-item"><a className="nav-link" href="#under_reviewed_tasks" data-toggle="tab" aria-expanded="false">Under Review Tasks</a></li>
											<li className="nav-item"><a className="nav-link" href="#completed_tasks" data-toggle="tab" aria-expanded="false">Completed Tasks</a></li>
										</ul>
										<AllTypeTasks projectTasks={projectTasks}/>
									</div>
								</div>
								<div className="col-lg-4 col-xl-3">
									<div className="card">
										<div className="card-body">
											<h6 className="card-title m-b-15">Project details</h6>
											<table className="table table-striped table-border">
												<tbody>
													<tr>
														<td>Cost:</td>
														<td className="text-right">{project.cost}USD</td>
													</tr>
													<tr>
														<td>Duration:</td>
														<td className="text-right">{project.duration+' '+project.durationType}</td>
													</tr>
													<tr>
														<td>Created:</td>
														<td className="text-right">{project.startDate}</td>
													</tr>
													<tr>
														<td>Deadline:</td>
														<td className="text-right">{project.endDate}</td>
													</tr>
													<tr>
														<td>Priority:</td>
														<td className="text-right">
															<div className="btn-group">
																{
																	project.priority ==='High' ? <a href="javaScript:Void(0);"><i className="fa fa-dot-circle-o text-danger"></i> High</a>
																	: project.priority ==='Medium' ?<a href="javaScript:Void(0);"><i className="fa fa-dot-circle-o text-primary"></i> Normal</a>
																	: <a href="javaScript:Void(0);"><i className="fa fa-dot-circle-o text-success"></i> Low </a>
																}
																{/* <a href="#" className="badge badge-danger dropdown-toggle" data-toggle="dropdown">Highest </a>
																<div className="dropdown-menu dropdown-menu-right">
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Highest priority</a>
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> High priority</a>
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-primary"></i> Normal priority</a>
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Low priority</a>
																</div> */}
															</div>
														</td>
													</tr>
													<tr>
														<td>Created by:</td>
															<td className="text-right"><a href={project.createdBy ?  URLS.profile+'/'+project.createdBy._id : 'javaScript:Void(0);'}>{project.createdBy ? project.createdBy.personalInfo.name.first +' '+project.createdBy.personalInfo.name.last : null}</a></td>
													</tr>
													<tr>
														<td>Status:</td>
														<td className="text-right">{project.status}</td>
													</tr>
												</tbody>
											</table>
											<p className="m-b-5">Progress <span className="text-success float-right">{progress.toString()+'%'}</span></p>
											<div className="progress progress-xs mb-0">
												<div className="progress-bar bg-success" role="progressbar" data-toggle="tooltip" title={progress.toString()+'%'} style={{ width: progress.toString()+'%' }}></div>
											</div>
										</div>
									</div>
									<div className="card project-user">
										<div className="card-body">
											<h6 style={{marginBottom : '10px'}} className="card-title">Assigned Leader </h6>
											<h6 style={{height : '30px'}}><button type="button" className="float-right btn btn-primary btn-sm" data-toggle="modal" data-target="#assign_leader"><i className="fa fa-plus"></i> Add</button></h6>
											<ul className="list-box">
											{project.projectLeader.length ? project.projectLeader.map((employee)=>{
													let flag = employee.emergencyInfo && employee.emergencyInfo.image;
													let name = employee.personalInfo.name;
													name = name.first + (name.middle ? ((' ' + name.middle) + name.last ? (' '+ name.last) : '') : (' '+ name.last));
													let designation = employee.jobInfo.title;  
													return(
														<li>
															<Link to={`${URLS.profile}/${employee._id}`}>
																<div className="list-item">
																	<div className="list-left">
																		<span className="avatar"><img alt="" src={flag ? URLS.backendStatic + '/' + employee.emergencyInfo.image : 'assets/img/profiles/avatar-02.jpg'} /></span>
																	</div>
																	<div className="list-body">
																		<span className="message-author">{name}</span>
																		<div className="clearfix"></div>
																		<span className="message-content">{designation}</span>
																	</div>
																</div>
															</Link>
														</li>
													)
												}) : null}			
											</ul>
										</div>
									</div>
									<div className="card project-user">
										<div className="card-body">
											<h6 style={{marginBottom : '10px'}} className="card-title">
												Assigned Members
											</h6>
											<h6 style={{height : '30px'}}>
												<button type="button" className="float-right btn btn-primary btn-sm" data-toggle="modal" data-target="#assign_user"><i className="fa fa-plus"></i> Add</button>
											</h6>
											<ul className="list-box">
												{project.team.length ? project.team.map((employee)=>{
													let flag = employee.emergencyInfo && employee.emergencyInfo.image;
													let name = employee.personalInfo.name;
													name = name.first + (name.middle ? ((' ' + name.middle) + name.last ? (' '+ name.last) : '') : (' '+ name.last));
													let designation = employee.jobInfo.title;  
													return(
														<li>
															<Link to={`${URLS.profile}/${employee._id}`}>
																<div className="list-item">
																	<div className="list-left">
																		<span className="avatar"><img alt="" src={flag ? URLS.backendStatic + '/' + employee.emergencyInfo.image : 'assets/img/profiles/avatar-02.jpg'} /></span>
																	</div>
																	<div className="list-body">
																		<span className="message-author">{name}</span>
																		<div className="clearfix"></div>
																		<span className="message-content">{designation}</span>
																	</div>
																</div>
															</Link>
														</li>
													)
												}) : null}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Page Content -->*/}

						{/*<!-- Assign User Modal -->*/}
						<AddMemberModal team={employeesList.filter(ar => !project.projectLeader.find(rm => (rm._id === ar._id) ))} projectTeam={project.team} projectLeader={project.projectLeader} addNewMember={this.addNewMember}/>
						{/*<!-- /Assign User Modal -->*/}

						{/*<!-- Assign Leader Modal -->*/}
						<AddLeaderModal team={employeesList.filter(ar => !project.team.find(rm => (rm._id === ar._id) ))} projectTeam={project.team} projectLeader={project.projectLeader} addNewLeader={this.addNewLeader}/>
						{/*<!-- /Assign Leader Modal -->*/}

						{/*<!-- Edit Project Modal -->*/}
						<EditProjectModal mode='edit' project={editProjectData} updateProjectsList={this.updateProjectsList} clientList={clientList} employeesList={employeesList}/>
						{/*<!-- /Edit Project Modal -->*/}

					</div>
					{/*<!-- /Page Wrapper -->*/}

				</div>
				{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
				<div className="sidebar-overlay" data-reff=""></div>
			</body>
		)
	}
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ProjectView))

