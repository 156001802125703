import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import { URLS } from '../urls';
import { Col, Form, Row } from 'react-bootstrap';
import { Document, Page, pdfjs } from "react-pdf";
import DocumentModal from '../employees/DocumentModal';
import AddJobGradeModal from "../employees/AddJobGradeModal";
import AddLocationModal from "../employees/AddLocationModal";
import AddEmployeeHMOModal from "../employees/AddEmployeeHMOModal";
import AddLifeInsuranceModal from "../employees/AddLifeInsuranceModal";
import '../../assets/css/date-picker.css'
import Datepicker from 'react-datetime';
import moment from 'moment'
import EmployeeSkills from "../employees/EmployeeSkills";
import EmployeeTraining from "../employees/EmployeeTraining";
import Multiselect from '../employees/Multiselect';
import { Breadcrumb, BreadcrumbItem} from 'reactstrap';
import Project from '../projects/Project';
import EditProjectModal from '../projects/EditProjectModal';
import AllTypeTasksEmployee from '../tasks/AllTypeTasksEmployee';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.handleEducationInfoField = this.handleEducationInfoField.bind();
		this.getAddressValue = this.getAddressValue.bind(this);
		this.getPhoneValue = this.getPhoneValue.bind(this);
		this.addMoreEducationInfo = this.addMoreEducationInfo.bind(this);
		this.state = {
			show_add_job: false,
			show_add_location: false,
			show_add_employeeHMO: false,
			show_add_lifeInsurance: false,
			_id: null,
			displayImageSrc: null,
			displayResumeSrc: null,
			pdfPageNumber: 1,
			personalInfo: {
				name: {
					first: '',
					last: '',
					middle: ''
				},
				address: {
					apartment: '',
					street: '',
					city: '',
					state: '',
					zip: ''
				},
				phone: {
					home: '',
					alternate: ''
				},
				email: '',
				birth: '',
				govtId: '',
				martial: '',
				spouse: {
					name: '',
					employer: '',
					phone: ''
				},
				prevEmployee: {
					employee: '',
					phone: ''
				}
			},
			jobInfo: {
				title: '',
				employeeId: '',
				grade: '',
				supervisor: [],
				department: '',
				workLocation: '',
				email: '',
				workPhone: '',
				cellPhone: '',
				startDate: '',
				salary: '',
				status: '',
				salaryBasis: '',
				contract: '',
				contractType: '',
			},
			emergencyInfo: {
				name: {
					first: '',
					last: '',
					middle: ''
				},
				address: {
					apartment: '',
					street: '',
					city: '',
					state: '',
					zip: '',
				},
				phone: {
					primary: '',
					secondary: '',
				},
				relation: '',
				guarantor: {
					title: '',
					name: '',
					phone: '',
					address: '',
				},
				referee: {
					title: '',
					name: '',
					phone: '',
					address: '',
				},
				employeeHMO: '',
				lifeInsurance: '',
				image: '',
				resume: '',
				certificate: '',
			},
			educationInfo: [{
				institution: '',
				subject: '',
				startDate: '',
				completeDate: '',
				degree: '',
				grade: '',
				certificate: ''
			}],
			jobGrades: [],
			locations: [],
			departments: [],
			employeeHMOs: [],
			lifeInsurances: [],
			educationCertificateFiles: [{
				file: null
			}],
			supervisors: [],
			projects : [],
			editProject : '',
			clientList : [],
			employeeList : [],
			projectTasks : []
		};
	}
	componentWillMount() {
		const id = window.location.pathname.split('/')[3];
		const token = 'dummy token'; // localStorage.getItem('token');

		fetch(`${URLS.backendEmployees}/${id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})
			.then(res => res.json())
			.then((result) => {
				this.setState({
					_id: result.employee._id,
					personalInfo: result.employee.personalInfo,
					emergencyInfo: result.employee.emergencyInfo,
					educationInfo: result.employee.educationInfo
				});
				let jobInfoTemp = result.employee.jobInfo;
				jobInfoTemp.grade = jobInfoTemp.grade && jobInfoTemp.grade._id;
				jobInfoTemp.workLocation = jobInfoTemp.workLocation && jobInfoTemp.workLocation._id;
				jobInfoTemp.department = jobInfoTemp.department && jobInfoTemp.department._id;
				this.setState({
					jobInfo: jobInfoTemp,
				});
				const educationInfo = result.employee.educationInfo;
				const educationInfoLength = educationInfo.length;
				let newEducationCertificateFiles = [];
				for (let index = 0; index < educationInfoLength; index++) {
					newEducationCertificateFiles.push({ 'file': null });
				}
				this.setState({ educationCertificateFiles: newEducationCertificateFiles });
			});
		fetch(`${URLS.backendJobGrades}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})
			.then(res => res.json())
			.then((result) => {
				this.setState({
					jobGrades: result.jobGrades,
				});
			});

		fetch(`${URLS.backendDepartments}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})
			.then(res => res.json())
			.then((result) => {
				this.setState({
					departments: result.departments,
				});
			});

		fetch(`${URLS.backendLocations}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})
			.then(res => res.json())
			.then((result) => {
				this.setState({
					locations: result.locations,
				});
			});
		fetch(`${URLS.backendEmployeeHMOs}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})
			.then(res => res.json())
			.then((result) => {
				this.setState({
					employeeHMOs: result.employeeHMOs,
				});
			});

		fetch(`${URLS.backendLifeInsurances}/get`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})
			.then(res => res.json())
			.then((result) => {
				this.setState({
					lifeInsurances: result.lifeInsurances,
				});
			});
		fetch(`${URLS.backendEmployees}/supervisors`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		})
			.then(res => res.json())
			.then((result) => {
				if (result.supervisors) {
					this.setState({
						supervisors: result.supervisors,
					});
				}
			});

			fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			}).then(res => res.json())
				.then((result) => {
					console.log("result.projects",result.projects);
					this.setState({
						projects: result.projects
					});
				});
				fetch(`${URLS.backendClients}/get`, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Token ${token}`,
					},
				}).then(res => res.json())
					.then((result) => {
						this.setState({
							clientList: result.clients,
						});
					});
	
				fetch(`${URLS.backendEmployees}/get`, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Token ${token}`,
					},
				}).then(res => res.json())
					.then((result) => {
						this.setState({
							employeesList: result.employees
						});
					});
					fetch(`${URLS.backendTasks}/tasks/${id}`, {
						method: 'GET',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization: `Token ${token}`,
						},
					}).then(res => res.json())
						.then((result) => {
							this.setState({
								projectTasks : result.tasks
							})
						});

	}
	getAddressValue = (apartment, street, city, state, zip) => {
		let result = '';
		if (apartment && apartment !== '') result = result.concat(apartment);
		if (street && street !== '') result = result.concat(' ' + street);
		if (city && city !== '') result = result.concat(', ' + city);
		if (state && state !== '') result = result.concat(', ' + state);
		if (zip && zip !== '') result = result.concat(' ' + zip);
		return result;

	};
	getPhoneValue = (phone1, phone2) => {
		let result = '';
		if (phone1 && phone1 !== '') result = result.concat(phone1);
		if (phone2 && phone2 !== '') {
			if (result !== '') result = result.concat(', ' + phone2);
			else result = result.concat(phone2);
		}
		return result;

	};
	changeShowJobState = () => {
		const { show_add_job } = this.state;
		this.setState({
			show_add_job: !show_add_job
		});
	};
	changeShowLocationState = () => {
		const { show_add_location } = this.state;
		this.setState({
			show_add_location: !show_add_location
		});
	};
	changeShowEmployeeHMOState = () => {
		const { show_add_employeeHMO } = this.state;
		this.setState({
			show_add_employeeHMO: !show_add_employeeHMO
		});
	};
	changeShowLifeInsuranceState = () => {
		const { show_add_lifeInsurance } = this.state;
		this.setState({
			show_add_lifeInsurance: !show_add_lifeInsurance
		});
	};
	updateJobGradesList = (item) => {
		const { jobGrades } = this.state;
		jobGrades.push(item);
		this.setState({
			jobGrades
		});
	};
	updateLocationsList = (item) => {
		const { locations } = this.state;
		locations.push(item);
		this.setState({
			locations
		});
	};
	updateEmployeeHMOsList = (item) => {
		const { employeeHMOs } = this.state;
		employeeHMOs.push(item);
		this.setState({
			employeeHMOs
		});
	};
	updateLifeInsurancesList = (item) => {
		const { lifeInsurances } = this.state;
		lifeInsurances.push(item);
		this.setState({
			lifeInsurances
		});
	};
	handleEducationInfoField = idx => evt => {
		const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
			if (idx !== eIdx) return info;
			let tName = evt.target.name;
			const value = evt.target.value;
			return { ...info, [tName]: value };
		});
		this.setState({ educationInfo: newEducationInfo });
	};

	handleEducationInfoCompleteField = idx => evt => {
		const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
			if (idx !== eIdx) return info;
			let tName = 'completeDate';
			const value = evt.target ? evt.target.value : moment(evt).format("DD/MM/YYYY");;
			return { ...info, [tName]: value };
		});
		this.setState({ educationInfo: newEducationInfo });
	};

	handleEducationInfoStartField = idx => evt => {
		const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
			if (idx !== eIdx) return info;
			let tName = 'startDate';
			const value = evt.target ? evt.target.value : moment(evt).format("DD/MM/YYYY");;
			return { ...info, [tName]: value };
		});
		this.setState({ educationInfo: newEducationInfo });
	};
	handleEducationInfoFileField = idx => evt => {
		const refName = 'certificate_' + idx;
		const file = this.refs[refName].files[0] ? this.refs[refName].files[0] : null;
		if (file !== null) {
			const { educationInfo, educationCertificateFiles } = this.state;
			let currentDate = new Date();
			currentDate = currentDate.getTime();
			educationInfo[idx].certificate = currentDate + '.pdf';
			educationCertificateFiles[idx].file = file;
			this.setState({ educationInfo: educationInfo, educationCertificateFiles: educationCertificateFiles });
		}
	};
	addMoreEducationInfo() {
		const { educationInfo, educationCertificateFiles } = this.state;
		this.setState({
			educationInfo: educationInfo.concat({
				institution: '',
				subject: '',
				startDate: '',
				completeDate: '',
				degree: '',
				grade: '',
				certificate: ''
			})
		});
		this.setState({
			educationCertificateFiles: educationCertificateFiles.concat({
				file: null
			})
		});
	}
	updatePersonalInfoFirstName = (event) => {
		const { personalInfo } = this.state;
		personalInfo.name.first = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoLastName = (event) => {
		const { personalInfo } = this.state;
		personalInfo.name.last = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoMiddleName = (event) => {
		const { personalInfo } = this.state;
		personalInfo.name.middle = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoAddressApartment = (event) => {
		const { personalInfo } = this.state;
		personalInfo.address.apartment = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoAddressStreet = (event) => {
		const { personalInfo } = this.state;
		personalInfo.address.street = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoAddressCity = (event) => {
		const { personalInfo } = this.state;
		personalInfo.address.city = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoAddressState = (event) => {
		const { personalInfo } = this.state;
		personalInfo.address.state = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoAddressZip = (event) => {
		const { personalInfo } = this.state;
		personalInfo.address.zip = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoPhoneHome = (event) => {
		const { personalInfo } = this.state;
		personalInfo.phone.home = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoPhoneAlternate = (event) => {
		const { personalInfo } = this.state;
		personalInfo.phone.alternate = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoEmail = (event) => {
		const { personalInfo } = this.state;
		personalInfo.email = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoGovtId = (event) => {
		const { personalInfo } = this.state;
		personalInfo.govtId = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalGender = (event) => {
		const { personalInfo } = this.state;
		personalInfo.gender = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoBirth = (event) => {
		try {
			const { personalInfo } = this.state;
			personalInfo.birth = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");;
			this.setState({
				personalInfo
			});
		}
		catch (e) {
			console.log('there was an error :: ', e)
		}

	};
	updatePersonalInfoMartial = (event) => {
		const { personalInfo } = this.state;
		personalInfo.martial = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoSpouseName = (event) => {
		const { personalInfo } = this.state;
		personalInfo.spouse.name = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoSpouseEmployer = (event) => {
		const { personalInfo } = this.state;
		personalInfo.spouse.employer = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoSpousePhone = (event) => {
		const { personalInfo } = this.state;
		personalInfo.spouse.phone = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoPrevEmployeeEmployee = (event) => {
		const { personalInfo } = this.state;
		personalInfo.prevEmployee.employee = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updatePersonalInfoPrevEmployeePhone = (event) => {
		const { personalInfo } = this.state;
		personalInfo.prevEmployee.phone = event.target.value;
		this.setState({
			personalInfo
		});
	};
	updateJobInfoTitle = (event) => {
		const { jobInfo } = this.state;
		jobInfo.title = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoEmployeeId = (event) => {
		const { jobInfo } = this.state;
		jobInfo.employeeId = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoGrade = (event) => {
		const { jobInfo } = this.state;
		const value = event.target.value;
		if (value === 'add_job') {
			const { show_add_job } = this.state;
			this.setState({
				show_add_job: !show_add_job
			});
		} else {
			jobInfo.grade = value
			this.setState({
				jobInfo
			});
		}
	};
	updateJobInfoSupervisor = (params) => {
        const { jobInfo } = this.state;
        jobInfo.supervisor = params.map((data)=>{return data.id});
        this.setState({
            jobInfo
        });
        console.log(this.state.jobInfo);
    };
	updateJobInfoDepartment = (event) => {
		const { jobInfo } = this.state;
		jobInfo.department = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoWorkLocation = (event) => {
		const { jobInfo } = this.state;
		const value = event.target.value;
		if (value === 'add_location') {
			const { show_add_location } = this.state;
			this.setState({
				show_add_location: !show_add_location
			});
		} else {
			jobInfo.workLocation = value;
			this.setState({
				jobInfo
			});
		}
	};
	updateJobInfoEmail = (event) => {
		const { jobInfo } = this.state;
		jobInfo.email = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoWorkPhone = (event) => {
		const { jobInfo } = this.state;
		jobInfo.workPhone = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoCellPhone = (event) => {
		const { jobInfo } = this.state;
		jobInfo.cellPhone = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoStartDate = (event) => {
		const { jobInfo } = this.state;
		jobInfo.startDate = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoSalary = (event) => {
		const { jobInfo } = this.state;
		jobInfo.salary = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoStatus = (event) => {
		const { jobInfo } = this.state;
		jobInfo.status = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoSalaryBasis = (event) => {
		const { jobInfo } = this.state;
		jobInfo.salaryBasis = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoContract = (event) => {
		const { jobInfo } = this.state;
		jobInfo.contract = event.target.checked;
		this.setState({
			jobInfo
		});
	};
	updateJobInfoContractType = (event) => {
		const { jobInfo } = this.state;
		jobInfo.contractType = event.target.value;
		this.setState({
			jobInfo
		});
	};
	updateEmergencyInfoNameFirst = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.name.first = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoNameLast = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.name.last = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoNameMiddle = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.name.middle = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoAddressApartment = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.address.apartment = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoAddressStreet = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.address.street = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoAddressCity = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.address.city = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoAddressState = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.address.state = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoAddressZip = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.address.zip = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoPhonePrimary = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.phone.primary = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoPhoneSecondary = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.phone.secondary = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoRelation = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.relation = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoGuarantorTitle = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.guarantor.title = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoGuarantorName = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.guarantor.name = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoGuarantorPhone = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.guarantor.phone = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoGuarantorAddress = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.guarantor.address = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoRefereeTitle = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.referee.title = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoRefereeName = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.referee.name = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoRefereePhone = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.referee.phone = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoRefereeAddress = (event) => {
		const { emergencyInfo } = this.state;
		emergencyInfo.referee.address = event.target.value;
		this.setState({
			emergencyInfo
		});
	};
	updateEmergencyInfoEmployeeHMO = (event) => {
		const { emergencyInfo } = this.state;
		const value = event.target.value;
		if (value === 'add_employeeHMO') {
			const { show_add_employeeHMO } = this.state;
			this.setState({
				show_add_employeeHMO: !show_add_employeeHMO
			});
		} else {
			emergencyInfo.employeeHMO = value;
			this.setState({
				emergencyInfo
			});
		}
	};
	updateEmergencyInfoLifeInsurance = (event) => {
		const { emergencyInfo } = this.state;
		const value = event.target.value;
		if (value === 'add_lifeInsurance') {
			const { show_add_lifeInsurance } = this.state;
			this.setState({
				show_add_lifeInsurance: !show_add_lifeInsurance
			});
		} else {
			emergencyInfo.lifeInsurance = value;
			this.setState({
				emergencyInfo
			});
		}
	};
	updateEmergencyInfoImage = (event) => {
		const { emergencyInfo } = this.state;
		let currentDate = new Date();
		currentDate = currentDate.getTime();
		emergencyInfo.image = currentDate + '.png';
		this.setState({
			emergencyInfo
		});
		let file = this.refs.emergency_image.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function () {
			this.setState({
				displayImageSrc: [reader.result]
			})
		}.bind(this);
	};
	updateEmergencyInfoResume = (event) => {
		const { emergencyInfo } = this.state;
		let currentDate = new Date();
		currentDate = currentDate.getTime();
		emergencyInfo.resume = currentDate + '.pdf';
		this.setState({
			emergencyInfo
		});
		this.setState({
			displayResumeSrc: event.target.files[0]
		});
	};
	onDocumentLoadSuccess = (e) => {
		console.log("success", e);
	};


	savePersonalInfo = (e) => {
		e.preventDefault();
		const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = this.state;

		let formData = new FormData();
		const employee = { _id, personalInfo, jobInfo, emergencyInfo, educationInfo };
		formData.append('employee', JSON.stringify(employee));
		const token = 'dummy token'; // localStorage.getItem('token');
		fetch(`${URLS.backendEmployees}/update`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				Authorization: `Token ${token}`,
			},
			body: formData,
		})
			.then(res => res.json())
			.then((result) => {
				console.log(result);
				// this.props.updateEmployeesList(result.employee);
			});
	};
	saveJobInfo = (e) => {
		e.preventDefault();
		const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = this.state;
		let formData = new FormData();
		const employee = { _id, personalInfo, jobInfo, emergencyInfo, educationInfo };
		formData.append('employee', JSON.stringify(employee));
		const token = 'dummy token'; // localStorage.getItem('token');
		fetch(`${URLS.backendEmployees}/update`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				Authorization: `Token ${token}`,
			},
			body: formData,
		})
			.then(res => res.json())
			.then((result) => {
				console.log(result);
				// this.props.updateEmployeesList(result.employee);
			});
	};
	saveEmergencyInfo = (e) => {
		e.preventDefault();
		const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo } = this.state;
		let formData = new FormData();
		const employee = { _id, personalInfo, jobInfo, emergencyInfo, educationInfo };
		formData.append('employee', JSON.stringify(employee));
		if(this.refs.emergency_image.files && this.refs.emergency_image.files[0])
			formData.append('file', this.refs.emergency_image.files[0], emergencyInfo.image);
		if(this.refs.emergency_resume.files && this.refs.emergency_resume.files[0])
			formData.append('file', this.refs.emergency_resume.files[0], emergencyInfo.resume);
		const token = 'dummy token'; // localStorage.getItem('token');
		fetch(`${URLS.backendEmployees}/update`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				Authorization: `Token ${token}`,
			},
			body: formData,
		})
			.then(res => res.json())
			.then((result) => {
				console.log(result);
				this.setState({
					emergencyInfo : result.employee.emergencyInfo
				})
				// this.props.updateEmployeesList(result.employee);
			});
	};
	saveEducationInfo = (e) => {
		e.preventDefault();
		const { _id, personalInfo, jobInfo, educationInfo, emergencyInfo, educationCertificateFiles } = this.state;
		let formData = new FormData();
		const employee = { _id, personalInfo, jobInfo, emergencyInfo, educationInfo };
		formData.append('employee', JSON.stringify(employee));
		for (let i = 0; i < educationInfo.length; i++) {
			if(educationCertificateFiles[i].file && educationCertificateFiles[i].file !=="")
				formData.append('file', educationCertificateFiles[i].file, educationInfo[i].certificate);
		}
		const token = 'dummy token'; // localStorage.getItem('token');
		fetch(`${URLS.backendEmployees}/update`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				Authorization: `Token ${token}`,
			},
			body: formData,
		})
			.then(res => res.json())
			.then((result) => {
				console.log(result);
				// this.props.updateEmployeesList(result.employee);
			});
	};
	editProject = (_id) => {
		this.setState({ editProject: _id })
	};

	getEditProjectDataFromId = (id) => {
		const { projects } = this.state;
		return projects.find(item => id === item._id)
	};

	render() {
		const { personalInfo, jobInfo, emergencyInfo, educationInfo, jobGrades, departments, locations, educationCertificateFiles, displayImageSrc, displayResumeSrc, pdfPageNumber, employeeHMOs, lifeInsurances, supervisors,projects,editProject, employeesList, clientList, projectTasks } = this.state;
		const editProjectData = this.getEditProjectDataFromId(editProject);
		const profileImage = (emergencyInfo && emergencyInfo.image) ? (URLS.backendStatic + '/' + emergencyInfo.image) : '/assets/img/profiles/avatar-02.jpg';
		const supervisorProfileImage = (supervisors.length && jobInfo) ? supervisors.map((item) => { return item.id === jobInfo.supervisor ? (URLS.backendStatic + '/' + item.image) : '/assets/img/profiles/avatar-02.jpg' }) : '/assets/img/profiles/avatar-02.jpg';
		const displayImage = displayImageSrc ? displayImageSrc : URLS.backendStatic + '/' + emergencyInfo.image;
		const displayResume = displayResumeSrc ? displayResumeSrc : URLS.backendStatic + '/' + emergencyInfo.resume;
		let displayEducationCertificates = [];
		for (let i = 0; i < educationInfo.length; i++) {
			let sFileUrl = URLS.backendStatic + '/' + educationInfo[i].certificate;
			let fff = educationCertificateFiles[i] && educationCertificateFiles[i].file;
			if (fff) {
				displayEducationCertificates.push(fff);
			} else {
				displayEducationCertificates.push(sFileUrl);
			}
		}
		return (
			<body>
				<Helmet>
					<meta charSet='utf-8' />
					<meta name='robots' content='noindex, nofollow' />
					<title>Fexspace HR</title>
				</Helmet>
				<div className='main-wrapper'>

					<Topbar />
					<Sidebar />
					<div className='page-wrapper'>

						{/*<!-- Page Content -->*/}
						<div className='content container-fluid'>

							{/*<!-- Page Title -->*/}
							<div className='row'>
								<div className='col-sm-12'>
								<Breadcrumb>
									<BreadcrumbItem><Link to={`${URLS.dashboard}`}>Dashboard</Link></BreadcrumbItem>
									<BreadcrumbItem><Link to={`${URLS.employees}`}>Employees</Link></BreadcrumbItem>
                            		<BreadcrumbItem active>My Profile</BreadcrumbItem>
                        		</Breadcrumb>
									{/* <h4 className='page-title'>My Profile</h4> */}
								</div>
							</div>
							{/*<!-- /Page Title -->*/}

							<div className='card-box mb-0'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='profile-view'>
											<div className='profile-img-wrap'>
												<div className='profile-img'>
													<img alt='' src={emergencyInfo && emergencyInfo.image ? (URLS.backendStatic + '/' + emergencyInfo.image) : '/assets/img/profiles/avatar-02.jpg'} />
												</div>
											</div>
											<div className='profile-basic'>
												<div className='row'>
													<div className='col-md-5'>
														<div className='profile-info-left'>
															<h3 className='user-name m-t-0 mb-0'>{personalInfo.name.first + ' ' + personalInfo.name.middle + ' ' + personalInfo.name.last}</h3>
															<h6 className='text-muted'>{departments.length && departments.map((item) => { return item._id === jobInfo.department ? item.name : '' })}</h6>
															<small className='text-muted'>{jobInfo.title}</small>
															<div className='staff-id'>{jobInfo.employeeId}</div>
															<div className='small doj text-muted'>{jobInfo.startDate}</div>
															<div className='staff-msg'><a className='btn btn-custom' href='Javascript:void(0);'>Send Message</a></div>
														</div>
													</div>
													<div className='col-md-7'>
														<ul className='personal-info'>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Phone: </div>
																<div className='text' style={{ flex: 1 }}><a href='Javascript:void(0);'> {personalInfo.phone.home} </a></div>
															</div>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Email: </div>
																<div className='text' style={{ flex: 1 }}><a href='Javascript:void(0);'> {personalInfo.email} </a></div>
															</div>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Birthday: </div>
																<div className='text' style={{ flex: 1 }}> {personalInfo.birth} </div>
															</div>
															<div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Address: </div>
																<div className='text' style={{ flex: 1 }}> {this.getAddressValue(personalInfo.address.apartment, personalInfo.address.street, personalInfo.address.city, personalInfo.address.state, personalInfo.address.zip)} </div>
															</div>
															{/* <div style={{ display: 'flex', padding: '4px 0px' }}>
																<div className='title' style={{ flex: 1 }}>Reports to: </div>
																<div className='text' style={{ flex: 1 }}>
																	<div className='avatar-box'>
																		<div className='avatar avatar-xs'>
																			<img src={supervisorProfileImage} alt='' />
																		</div>
																	</div>
																	<a href={`${jobInfo.supervisor}`}>{supervisors.length && supervisors.map((item) => { return item.id === jobInfo.supervisor ? item.name : '' })}</a>
																</div>
															</div> */}
														</ul>
													</div>
												</div>
											</div>
											{/* <div className='pro-edit'><a data-target='#profile_info' data-toggle='modal' className='edit-icon' href='Javascript:void(0);'><i className='fa fa-pencil'></i></a></div> */}
										</div>
									</div>
								</div>
							</div>

							<div className='card-box tab-box'>
								<div className='row user-tabs'>
									<div className='col-lg-12 col-md-12 col-sm-12 line-tabs'>
										<ul className='nav nav-tabs nav-tabs-bottom'>
											<li className='nav-item'><a href='#emp_profile' data-toggle='tab' className='nav-link active'>Profile</a></li>
											<li className='nav-item'><a href='#emp_projects' data-toggle='tab' className='nav-link'>Projects</a></li>
											<li className='nav-item'><a href='#tasks' data-toggle='tab' className='nav-link'>Tasks</a></li>
											{/* <li className='nav-item'><a href='#bank_statutory' data-toggle='tab' className='nav-link'>Bank & Statutory <small className='text-danger'>(Admin Only)</small></a></li> */}
											<li className='nav-item'><a href='#emp_skills' data-toggle='tab' className='nav-link'>Skills/Certification</a></li>
											<li className='nav-item'><a href='#emp_trainings' data-toggle='tab' className='nav-link'>Training</a></li>
											<li className='nav-item'><a href='#emp_documents' data-toggle='tab' className='nav-link'>Documents</a></li>
										</ul>
									</div>
								</div>
							</div>

							<div className='tab-content'>

								{/*<!-- Profile Info Tab -->*/}
								<div id='emp_profile' className='pro-overview tab-pane fade show active'>
									<div className='row'>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Personal Information <a href='Javascript:void(0);' className='edit-icon' data-toggle='modal' data-target='#personal_info_modal'><i className='fa fa-pencil'></i></a></h3>
												<ul className='personal-info'>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Name:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.name.first + ' ' + personalInfo.name.middle + ' ' + personalInfo.name.last}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Address:</div>
														<div className='text' style={{ flex: 1 }}>{this.getAddressValue(personalInfo.address.apartment, personalInfo.address.street, personalInfo.address.city, personalInfo.address.state, personalInfo.address.zip)}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Tel:</div>
														<div className='text' style={{ flex: 1 }}><a href='Javascript:void(0);'>{this.getPhoneValue(personalInfo.phone.home, personalInfo.phone.alternate)}</a></div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Email:</div>
														<div className='text' style={{ flex: 1 }}><a href='Javascript:void(0);'>{personalInfo.email}</a></div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Govt ID:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.govtId}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Birthday:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.birth}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Marital Status:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.martial}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Employment of spouse:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.spouse.name}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Spouse’s Employer:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.spouse.employer}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Spouse’s Work Phone:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.spouse.phone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Previous Employee:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.prevEmployee.employee}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Previous Employer Phone:</div>
														<div className='text' style={{ flex: 1 }}>{personalInfo.prevEmployee.phone}</div>
													</div>
												</ul>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Emergency Contact <a href='Javascript:void(0);' className='edit-icon' data-toggle='modal' data-target='#emergency_contact_modal'><i className='fa fa-pencil'></i></a></h3>
												<ul className='personal-info'>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Name:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.name.first + ' ' + emergencyInfo.name.middle + ' ' + emergencyInfo.name.last}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Address:</div>
														<div className='text' style={{ flex: 1 }}>{this.getAddressValue(emergencyInfo.address.apartment, emergencyInfo.address.street, emergencyInfo.address.city, emergencyInfo.address.state, emergencyInfo.address.zip)}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Phone: </div>
														<div className='text' style={{ flex: 1 }}>{this.getPhoneValue(emergencyInfo.phone.primary, emergencyInfo.phone.secondary)}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Relationship:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.relation}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Guarantor:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.guarantor.title + ' ' + emergencyInfo.guarantor.name}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Guarantor Phone:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.guarantor.phone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Guarantor Address:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.guarantor.address}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Referee:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.referee.title + ' ' + emergencyInfo.referee.name}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Referee Phone:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.referee.phone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Referee Address:</div>
														<div className='text' style={{ flex: 1 }}>{emergencyInfo.referee.address}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Employee HMO:</div>
														<div className='text' style={{ flex: 1 }}>{employeeHMOs.length && employeeHMOs.map((item) => { return item._id === emergencyInfo.employeeHMO ? item.name : '' })}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Group Life Insurance:</div>
														<div className='text' style={{ flex: 1 }}>{lifeInsurances.length && lifeInsurances.map((item) => { return item._id === emergencyInfo.lifeInsurance ? item.name : '' })}</div>
													</div>
												</ul>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Job Information <a href='Javascript:void(0);' className='edit-icon' data-toggle='modal' data-target='#job_information_modal'><i className='fa fa-pencil'></i></a></h3>
												<ul className='personal-info'>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Job Title:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.title}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Employee ID:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.employeeId}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Job Grade:</div>
														<div className='text' style={{ flex: 1 }}>{jobGrades.length && jobGrades.map((item) => { return item._id === jobInfo.grade ? item.name : '' })}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Supervisor:</div>
														<div className='text' style={{ flex: 1 }}>
															{
																supervisors.length && jobInfo.supervisor.length && supervisors.map((item) => {
																	if(jobInfo.supervisor.indexOf(item.id) > -1)
																		return (<span>
																					<a href={`${item.id}`} style={{textDecoration: 'underline'}}>{item.name}</a>&nbsp;&nbsp;
																			 </span>);
																	else
																		return <span></span>;
																	}
																)
															}
														</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Department:</div>
														<div className='text' style={{ flex: 1 }}>{departments.length && departments.map((item) => { return item._id === jobInfo.department ? item.name : '' })}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Work Location:</div>
														<div className='text' style={{ flex: 1 }}>{locations.length && locations.map((item) => { return item._id === jobInfo.workLocation ? item.name : '' })}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Email:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.email}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Work Phone:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.workPhone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Cell Phone:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.cellPhone}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Start Date:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.startDate}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Salary:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.salary + ' ' + jobInfo.salaryBasis}</div>
													</div>
													<div style={{ display: 'flex', padding: '4px 0px' }}>
														<div className='title' style={{ flex: 1 }}>Status:</div>
														<div className='text' style={{ flex: 1 }}>{jobInfo.status}</div>
													</div>
												</ul>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='card-box profile-box'>
												<h3 className='card-title'>Education Information <a href='Javascript:void(0);' className='edit-icon' data-toggle='modal' data-target='#education_info'><i className='fa fa-pencil'></i></a></h3>
												<div className='experience-box'>
													<ul className='experience-list'>
														{educationInfo.map((education) => {
															return (
																<li>
																	<div className='experience-user'>
																		<div className='before-circle'></div>
																	</div>
																	<div className='experience-content'>
																		<div className='timeline-content'>
																			<a href='#/' className='name'>{education.institution}</a>
																			<div>{education.subject}</div>
																			<div>Grade: {education.grade}</div>
																			<span className='time'>{education.startDate + ' - ' + education.completeDate}</span>
																		</div>
																	</div>
																</li>
															)
														})}
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*<!-- /Profile Info Tab -->*/}

								{/*<!-- Projects Tab -->*/}
								<div className='tab-pane fade' id='emp_projects'>
								<div className="row staff-grid-row">
								{projects.length ? projects.map((project) => {
									let projectTeam = [];
									let projectLeader = [];
									if(project.team && project.team.length){
										projectTeam = project.team.map(employee => {
											let name = employee.personalInfo.name.first;
											name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
											name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
											const id = employee._id;
											const image = employee.emergencyInfo.image;
											return {name: name, id: id, image: image};							
										})
									}
									if(project.projectLeader && project.projectLeader.length){
										projectLeader = project.projectLeader.map(employee => {
											let name = employee.personalInfo.name.first;
											name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
											name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
											const id = employee._id;
											const image = employee.emergencyInfo.image;
											return {name: name, id: id, image: image};
										})
									}
									return <Project key={project._id} project={project} projectTeamList={projectTeam} projectLeaderList={projectLeader} onEdit={this.editProject} />
								}):''}
							</div>
								</div>
								<EditProjectModal mode='edit' project={editProjectData} updateProjectsList={this.updateProjectsList} clientList={clientList} employeesList={employeesList}/>
								{/*<!-- /Projects Tab -->*/}

								{/*<!-- Bank Statutory Tab -->*/}
								<div className='tab-pane fade' id='bank_statutory'>
									<div className='card'>
										<div className='card-body'>
											<h3 className='card-title'> Basic Salary Information</h3>
											<form>
												<div className='row'>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Salary basis <span className='text-danger'>*</span></label>
															<select className='select'>
																<option>Select salary basis type</option>
																<option>Hourly</option>
																<option>Daily</option>
																<option>Weekly</option>
																<option>Monthly</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Salary amount </label>
															<div className='input-group'>
																<div className='input-group-prepend'>
																	<span className='input-group-text'>$</span>
																</div>
																<input type='text' className='form-control' placeholder='Type your salary amount' value='0.00' />
															</div>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Payment type</label>
															<select className='select'>
																<option>Select payment type</option>
																<option>Bank transfer</option>
																<option>Check</option>
																<option>Cash</option>
															</select>
														</div>
													</div>
												</div>
												<hr />
												<h3 className='card-title'> PF Information</h3>
												<div className='row'>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>PF contribution</label>
															<select className='select'>
																<option>Select PF contribution</option>
																<option>Yes</option>
																<option>No</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>PF No. <span className='text-danger'>*</span></label>
															<select className='select'>
																<option>Select PF contribution</option>
																<option>Yes</option>
																<option>No</option>
															</select>
														</div>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Employee PF rate</label>
															<select className='select'>
																<option>Select PF contribution</option>
																<option>Yes</option>
																<option>No</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Additional rate <span className='text-danger'>*</span></label>
															<select className='select'>
																<option>Select additional rate</option>
																<option>0%</option>
																<option>1%</option>
																<option>2%</option>
																<option>3%</option>
																<option>4%</option>
																<option>5%</option>
																<option>6%</option>
																<option>7%</option>
																<option>8%</option>
																<option>9%</option>
																<option>10%</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Total rate</label>
															<input type='text' className='form-control' placeholder='N/A' value='11%' />
														</div>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Employee PF rate</label>
															<select className='select'>
																<option>Select PF contribution</option>
																<option>Yes</option>
																<option>No</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Additional rate <span className='text-danger'>*</span></label>
															<select className='select'>
																<option>Select additional rate</option>
																<option>0%</option>
																<option>1%</option>
																<option>2%</option>
																<option>3%</option>
																<option>4%</option>
																<option>5%</option>
																<option>6%</option>
																<option>7%</option>
																<option>8%</option>
																<option>9%</option>
																<option>10%</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Total rate</label>
															<input type='text' className='form-control' placeholder='N/A' value='11%' />
														</div>
													</div>
												</div>

												<hr />
												<h3 className='card-title'> ESI Information</h3>
												<div className='row'>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>ESI contribution</label>
															<select className='select'>
																<option>Select ESI contribution</option>
																<option>Yes</option>
																<option>No</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>ESI No. <span className='text-danger'>*</span></label>
															<select className='select'>
																<option>Select ESI contribution</option>
																<option>Yes</option>
																<option>No</option>
															</select>
														</div>
													</div>
												</div>
												<div className='row'>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Employee ESI rate</label>
															<select className='select'>
																<option>Select ESI contribution</option>
																<option>Yes</option>
																<option>No</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Additional rate <span className='text-danger'>*</span></label>
															<select className='select'>
																<option>Select additional rate</option>
																<option>0%</option>
																<option>1%</option>
																<option>2%</option>
																<option>3%</option>
																<option>4%</option>
																<option>5%</option>
																<option>6%</option>
																<option>7%</option>
																<option>8%</option>
																<option>9%</option>
																<option>10%</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div className='form-group'>
															<label className='col-form-label'>Total rate</label>
															<input type='text' className='form-control' placeholder='N/A' value='11%' />
														</div>
													</div>
												</div>

												<div className='submit-section'>
													<button className='btn btn-primary submit-btn' type='submit'>Save</button>
												</div>
											</form>
										</div>
									</div>
								</div>
								{/*<!-- /Bank Statutory Tab -->*/}
								<div className='tab-pane fade' id='emp_skills'>
									<EmployeeSkills eId={this.state._id} />
								</div>
								<div className='tab-pane fade' id='emp_trainings'>
									<EmployeeTraining eId={this.state._id} />
								</div>
								<div className='tab-pane fade' id='emp_documents'>
									<DocumentModal />
								</div>
								<div id='tasks' className='tab-pane fade'>
									<div className='project-task'>
										<ul className='nav nav-tabs nav-tabs-top nav-justified mb-0'>
											<li className='nav-item'><a className='nav-link active' href='#all_tasks' data-toggle='tab' aria-expanded='true'>All Tasks</a></li>
											<li className='nav-item'><a className='nav-link' href='#pending_tasks' data-toggle='tab' aria-expanded='false'>Pending Tasks</a></li>
											<li className='nav-item'><a className='nav-link' href='#under_reviewed_tasks' data-toggle='tab' aria-expanded='false'>Under Reviewing</a></li>
											<li className='nav-item'><a className='nav-link' href='#completed_tasks' data-toggle='tab' aria-expanded='false'>Completed Tasks</a></li>
										</ul>
										<AllTypeTasksEmployee projectTasks={projectTasks}/>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Page Content -->*/}

						{/*<!-- Profile Modal -->*/}
						<div id='profile_info' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Profile Information</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<form>
											<div className='row'>
												<div className='col-md-12'>
													<div className='profile-img-wrap edit-img'>
														<img className='inline-block' src='assets/img/profiles/avatar-02.jpg' alt='user' />
														<div className='fileupload btn'>
															<span className='btn-text'>edit</span>
															<input className='upload' type='file' />
														</div>
													</div>
													<div className='row'>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>First Name</label>
																<input type='text' className='form-control' value='John' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Last Name</label>
																<input type='text' className='form-control' value='Doe' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Birth Date</label>
																<div className='cal-icon'>
																	<input className='form-control datetimepicker' type='text' value='05/06/1985' />
																</div>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Gender</label>
																<select className='select form-control'>
																	<option value='male selected'>Male</option>
																	<option value='female'>Female</option>
																</select>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-md-12'>
													<div className='form-group'>
														<label>Address</label>
														<input type='text' className='form-control' value='4487 Snowbird Lane' />
													</div>
												</div>
												<div className='col-md-6'>
													<div className='form-group'>
														<label>State</label>
														<input type='text' className='form-control' value='New York' />
													</div>
												</div>
												<div className='col-md-6'>
													<div className='form-group'>
														<label>Country</label>
														<input type='text' className='form-control' value='United States' />
													</div>
												</div>
												<div className='col-md-6'>
													<div className='form-group'>
														<label>Pin Code</label>
														<input type='text' className='form-control' value='10523' />
													</div>
												</div>
												<div className='col-md-6'>
													<div className='form-group'>
														<label>Phone Number</label>
														<input type='text' className='form-control' value='631-889-3206' />
													</div>
												</div>
												<div className='col-md-6'>
													<div className='form-group'>
														<label>Department <span className='text-danger'>*</span></label>
														<select className='select'>
															<option>Select Department</option>
															<option>Web Development</option>
															<option>IT Management</option>
															<option>Marketing</option>
														</select>
													</div>
												</div>
												<div className='col-md-6'>
													<div className='form-group'>
														<label>Designation <span className='text-danger'>*</span></label>
														<select className='select'>
															<option>Select Designation</option>
															<option>Web Designer</option>
															<option>Web Developer</option>
															<option>Android Developer</option>
														</select>
													</div>
												</div>
												<div className='col-md-6'>
													<div className='form-group'>
														<label>Reports To <span className='text-danger'>*</span></label>
														<select className='select'>
															<option>-</option>
															<option>Wilmer Deluna</option>
															<option>Lesley Grauer</option>
															<option>Jeffery Lalor</option>
														</select>
													</div>
												</div>
											</div>
											<div className='submit-section'>
												<button className='btn btn-primary submit-btn'>Submit</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Profile Modal -->*/}

						{/*<!-- Personal Info Modal -->*/}
						<div id='personal_info_modal' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Personal Information</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<Form onSubmit={this.savePersonalInfo}>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Full Name
												</Form.Label>
												<Col sm={10}>
													<Row>
														<Col sm={4}>
															<Form.Control placeholder='First name' value={personalInfo.name.first}
																onChange={this.updatePersonalInfoFirstName.bind(this)} />
														</Col>
														<Col sm={4}>
															<Form.Control placeholder='Last name' value={personalInfo.name.last}
																onChange={this.updatePersonalInfoLastName.bind(this)} />
														</Col>
														<Col sm={4}>
															<Form.Control placeholder='Middle Initial'
																value={personalInfo.name.middle}
																onChange={this.updatePersonalInfoMiddleName.bind(this)} />
														</Col>
													</Row>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Address
												</Form.Label>
												<Col sm={10}>
													<Form.Row>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='Apartment/Unit #'
																value={personalInfo.address.apartment}
																onChange={this.updatePersonalInfoAddressApartment.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={8}>
															<Form.Control placeholder='Street Address'
																value={personalInfo.address.street}
																onChange={this.updatePersonalInfoAddressStreet.bind(this)} />
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='City' value={personalInfo.address.city}
																onChange={this.updatePersonalInfoAddressCity.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='State' value={personalInfo.address.state}
																onChange={this.updatePersonalInfoAddressState.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='Zip code' value={personalInfo.address.zip}
																onChange={this.updatePersonalInfoAddressZip.bind(this)} />
														</Form.Group>
													</Form.Row>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Home Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={personalInfo.phone.home}
														onChange={this.updatePersonalInfoPhoneHome.bind(this)} />
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Alternate Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={personalInfo.phone.alternate}
														onChange={this.updatePersonalInfoPhoneAlternate.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Email
												</Form.Label>
												<Col sm={10}>
													<Form.Control placeholder='Email' value={personalInfo.email}
														onChange={this.updatePersonalInfoEmail.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													SSN or Gov’t ID:
												</Form.Label>
												<Col sm={10}>
													<Form.Control placeholder='Gov’t ID:' value={personalInfo.govtId}
														onChange={this.updatePersonalInfoGovtId.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Gender:
												</Form.Label>
												<Col sm={6}>
													<Form.Control as='select' value={personalInfo.gender}
														onChange={this.updatePersonalGender.bind(this)}>
														<option>Choose...</option>
														<option value='Male'>Male</option>
														<option value='Female'>Female</option>
														<option value='Others'>Others</option>
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Birth Date
												</Form.Label>
												<Col sm={4}>
													<div classname='datePicker'>
														<i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
														<Datepicker value={personalInfo.birth}
															closeOnSelect={true}
															onChange={this.updatePersonalInfoBirth.bind(this)} />
													</div>

												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Marital Status
												</Form.Label>
												<Col sm={4}>
													<Form.Control as='select' value={personalInfo.martial}
														onChange={this.updatePersonalInfoMartial.bind(this)}>
														<option>Choose...</option>
														<option value='Single'>Single</option>
														<option value='Married'>Married</option>
														<option value='Divorce'>Divorce</option>
														<option value='Widow'>Widow</option>
														<option value='Widower'>Widower</option>
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Spouse’s Name
												</Form.Label>
												<Col sm={10}>
													<Form.Control value={personalInfo.spouse.name}
														onChange={this.updatePersonalInfoSpouseName.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Spouse’s Employer
												</Form.Label>
												<Col sm={4}>
													<Form.Control placeholder='Spouse’s Employer'
														value={personalInfo.spouse.employer}
														onChange={this.updatePersonalInfoSpouseEmployer.bind(this)} />
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Spouse’s Work Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control placeholder='Spouse’s Work Phone'
														value={personalInfo.spouse.phone}
														onChange={this.updatePersonalInfoSpousePhone.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Previous Employee
												</Form.Label>
												<Col sm={4}>
													<Form.Control placeholder='Previous Employee'
														value={personalInfo.prevEmployee.employee}
														onChange={this.updatePersonalInfoPrevEmployeeEmployee.bind(this)} />
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Previous Employer Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control placeholder='Previous Employer Phone'
														value={personalInfo.prevEmployee.phone}
														onChange={this.updatePersonalInfoPrevEmployeePhone.bind(this)} />
												</Col>
											</Form.Group>
											<div className="submit-section">
												<button className="btn btn-primary submit-btn" data-toggle="modal"
													data-target="#personal_info_modal">Save
												</button>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
						<div id='job_information_modal' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Job Information</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<Form onSubmit={this.saveJobInfo}>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Job Title
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={jobInfo.title}
														onChange={this.updateJobInfoTitle.bind(this)} />
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Employee ID
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={jobInfo.employeeId}
														onChange={this.updateJobInfoEmployeeId.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Job Grade
												</Form.Label>
												<Col sm={4}>
													<Form.Control as='select' value={jobInfo.grade}
														onChange={this.updateJobInfoGrade.bind(this)}>
														<option>Select</option>
														<option value='add_job'>Add New</option>
														<option disabled>__________________</option>
														{
															jobGrades.map(jobGrade => {
																return <option value={jobGrade._id}>{jobGrade.name}</option>
															})
														}
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Supervisor
												</Form.Label>
												<Col sm={4}>
												{
                                        			supervisors.length && <Multiselect options={supervisors} onSelectOptions={this.updateJobInfoSupervisor} selectedOptions={jobInfo.supervisor}/>
                                    			}
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Department
												</Form.Label>
												<Col sm={4}>
													<Form.Control as='select' value={jobInfo.department} onChange={this.updateJobInfoDepartment.bind(this)}>
														<option>Select</option>
														{
															departments.map((dep) => {
																return <option value={dep._id}>{dep.name}</option>
															})
														}
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Work Location
												</Form.Label>
												<Col sm={4}>
													<Form.Control as='select' value={jobInfo.workLocation}
														onChange={this.updateJobInfoWorkLocation.bind(this)}>
														<option>Select</option>
														<option value='add_location'>Add New</option>
														<option disabled>__________________</option>
														{
															locations.map(location => {
																return <option value={location._id}>{location.name}</option>
															})
														}
													</Form.Control>
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Email
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={jobInfo.email}
														onChange={this.updateJobInfoEmail.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Work Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={jobInfo.workPhone}
														onChange={this.updateJobInfoWorkPhone.bind(this)} />
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Cell Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={jobInfo.cellPhone}
														onChange={this.updateJobInfoCellPhone.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Start Date
												</Form.Label>
												<Col sm={4}>
													<div classname='datePicker'>
														<i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
														<Datepicker value={jobInfo.startDate}
															closeOnSelect={true}
															onChange={this.updateJobInfoStartDate.bind(this)} />
													</div>

												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Salary
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={jobInfo.salary}
														onChange={this.updateJobInfoSalary.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Status
												</Form.Label>
												<Col sm={4}>
													<Form.Control as='select' value={jobInfo.status}
														onChange={this.updateJobInfoStatus.bind(this)}>
														<option>Choose...</option>
														<option value='Internship'>Internship</option>
														<option value='Probation'>Probation</option>
														<option value='Trainee'>Trainee</option>
														<option value='Confirmed'>Confirmed</option>
													</Form.Control>
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Salary Basis
												</Form.Label>
												<Col sm={4}>
													<Form.Control as='select' value={jobInfo.salaryBasis}
														onChange={this.updateJobInfoSalaryBasis.bind(this)}>
														<option>Choose...</option>
														<option value='Hourly'>Hourly</option>
														<option value='Daily'>Daily</option>
														<option value='Daily'>Weekly</option>
														<option value='Monthly'>Monthly</option>
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
                                    <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Contractor
                                    </Form.Label>
                                    <Col sm={4}>
                                        <div className="form-check" style={{height:'44px',padding:'6px 0', display:'inline-block'}}>
                                            <input style={{height:'30px',width:'30px'}} type='checkbox' 
                                                checked={jobInfo.contract}
                                                onChange={this.updateJobInfoContract.bind(this)} />
                                            <label style={{ margin: '10px',verticalAlign: 'bottom'}}>Check if contract based</label>
                                        </div>
                                    </Col>
                                    {jobInfo.contract && <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                                        Contract Type
                                    </Form.Label>}
                                    {jobInfo.contract && <Col sm={4}>
                                        <Form.Control as='select' value={jobInfo.contractType}
                                            onChange={this.updateJobInfoContractType.bind(this)}>
                                                <option value=''>Choose...</option>
                                                {
                                                    projects.length ? projects.map(project => {
                                                        return <option value={project._id}>{project.name}</option>
                                                    }) : <option value=''>Choose...</option>
                                                }
                                        </Form.Control>
                                    </Col>}
                                </Form.Group>
											<div className="submit-section">
												<button className="btn btn-primary submit-btn" data-toggle="modal"
													data-target="#job_information_modal">Save
												</button>
											</div>
										</Form>
									</div>
								</div>
							</div>
							<AddJobGradeModal updateJobGradesList={this.updateJobGradesList} isShow={this.state.show_add_job} changeShowJobState={this.changeShowJobState} />
							<AddLocationModal updateLocationsList={this.updateLocationsList} isShow={this.state.show_add_location} changeShowLocationState={this.changeShowLocationState} />
						</div>
						{/*<!-- /Personal Info Modal -->*/}

						{/*<!-- Family Info Modal -->*/}
						<div id='family_info_modal' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'> Family Informations</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<form>
											<div className='form-scroll'>
												<div className='card-box'>
													<h3 className='card-title'>Family Member <a href='Javascript:void(0);' className='delete-icon'><i className='fa fa-trash-o'></i></a></h3>
													<div className='row'>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Name <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Relationship <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Date of birth <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Phone <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
													</div>
												</div>
												<div className='card-box'>
													<h3 className='card-title'>Education Informations <a href='Javascript:void(0);' className='delete-icon'><i className='fa fa-trash-o'></i></a></h3>
													<div className='row'>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Name <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Relationship <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Date of birth <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group'>
																<label>Phone <span className='text-danger'>*</span></label>
																<input className='form-control' type='text' />
															</div>
														</div>
													</div>
													<div className='add-more'>
														<a href='Javascript:void(0);'><i className='fa fa-plus-circle'></i> Add More</a>
													</div>
												</div>
											</div>
											<div className='submit-section'>
												<button className='btn btn-primary submit-btn'>Submit</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Family Info Modal -->*/}

						{/*<!-- Emergency Contact Modal -->*/}
						<div id='emergency_contact_modal' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Emergency Information</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<Form onSubmit={this.saveEmergencyInfo}>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Full Name
												</Form.Label>
												<Col sm={10}>
													<Row>
														<Col sm={4}>
															<Form.Control placeholder='First name' value={emergencyInfo.name.first}
																onChange={this.updateEmergencyInfoNameFirst.bind(this)} />
														</Col>
														<Col sm={4}>
															<Form.Control placeholder='Last name' value={emergencyInfo.name.last}
																onChange={this.updateEmergencyInfoNameLast.bind(this)} />
														</Col>
														<Col sm={4}>
															<Form.Control placeholder='Middle Initial'
																value={emergencyInfo.name.middle}
																onChange={this.updateEmergencyInfoNameMiddle.bind(this)} />
														</Col>
													</Row>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Address
												</Form.Label>
												<Col sm={10}>
													<Form.Row>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='Apartment/Unit #'
																value={emergencyInfo.address.apartment}
																onChange={this.updateEmergencyInfoAddressApartment.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={8}>
															<Form.Control placeholder='Street Address'
																value={emergencyInfo.address.street}
																onChange={this.updateEmergencyInfoAddressStreet.bind(this)} />
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='City' value={emergencyInfo.address.city}
																onChange={this.updateEmergencyInfoAddressCity.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='State' value={emergencyInfo.address.state}
																onChange={this.updateEmergencyInfoAddressState.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={4}>
															<Form.Control placeholder='Zip code' value={emergencyInfo.address.zip}
																onChange={this.updateEmergencyInfoAddressZip.bind(this)} />
														</Form.Group>
													</Form.Row>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Home Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={emergencyInfo.phone.primary}
														onChange={this.updateEmergencyInfoPhonePrimary.bind(this)} />
												</Col>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Alternate Phone
												</Form.Label>
												<Col sm={4}>
													<Form.Control value={emergencyInfo.phone.secondary}
														onChange={this.updateEmergencyInfoPhoneSecondary.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Relationship
												</Form.Label>
												<Col sm={10}>
													<Form.Control value={emergencyInfo.relation}
														onChange={this.updateEmergencyInfoRelation.bind(this)} />
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Guarantor
												</Form.Label>
												<Col sm={10}>
													<Form.Row>
														<Form.Group as={Col} sm={2}>
															<Form.Control placeholder='Title' value={emergencyInfo.guarantor.title}
																onChange={this.updateEmergencyInfoGuarantorTitle.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={5}>
															<Form.Control placeholder='Guarantor Name'
																value={emergencyInfo.guarantor.name}
																onChange={this.updateEmergencyInfoGuarantorName.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={5}>
															<Form.Control placeholder='Guarantor Phone'
																value={emergencyInfo.guarantor.phone}
																onChange={this.updateEmergencyInfoGuarantorPhone.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={12}>
															<Form.Control placeholder='Guarantor Address'
																value={emergencyInfo.guarantor.address}
																onChange={this.updateEmergencyInfoGuarantorAddress.bind(this)} />
														</Form.Group>
													</Form.Row>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Referee
												</Form.Label>
												<Col sm={10}>
													<Form.Row>
														<Form.Group as={Col} sm={2}>
															<Form.Control placeholder='Title' value={emergencyInfo.referee.title}
																onChange={this.updateEmergencyInfoRefereeTitle.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={5}>
															<Form.Control placeholder='Referee Name'
																value={emergencyInfo.referee.name}
																onChange={this.updateEmergencyInfoRefereeName.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={5}>
															<Form.Control placeholder='Referee Phone'
																value={emergencyInfo.referee.phone}
																onChange={this.updateEmergencyInfoRefereePhone.bind(this)} />
														</Form.Group>
														<Form.Group as={Col} sm={12}>
															<Form.Control placeholder='Referee Address'
																value={emergencyInfo.referee.address}
																onChange={this.updateEmergencyInfoRefereeAddress.bind(this)} />
														</Form.Group>
													</Form.Row>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Employee HMO
												</Form.Label>
												<Col sm={10}>
													<Form.Control as='select' value={emergencyInfo.employeeHMO}
														onChange={this.updateEmergencyInfoEmployeeHMO.bind(this)}>
														<option>Select</option>
														<option value='add_employeeHMO'>Add New</option>
														<option disabled>__________________</option>
														{
															employeeHMOs.map(location => {
																return <option value={location._id}>{location.name}</option>
															})
														}
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Group Life Insurance
												</Form.Label>
												<Col sm={10}>
													<Form.Control as='select' value={emergencyInfo.lifeInsurance}
														onChange={this.updateEmergencyInfoLifeInsurance.bind(this)}>
														<option>Select</option>
														<option value='add_lifeInsurance'>Add New</option>
														<option disabled>__________________</option>
														{
															lifeInsurances.map(lifeInsurance => {
																return <option value={lifeInsurance._id}>{lifeInsurance.name}</option>
															})
														}
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Upload Employee Photo
												</Form.Label>
												<Col sm={10}>
													<div className="file-upload-div">
														<div className="file-preview">
															<img style={{ height: '120px', width: '110px' }} src={displayImage} />
														</div>

														<div className="upload-btn-wrapper">
															<button className="custom-upload-btn">Browse</button>
															<input type='file' label='Upload File' ref="emergency_image"
																onChange={this.updateEmergencyInfoImage.bind(this)} />
														</div>
													</div>

												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label style={{ fontSize: '14px' }} column sm={2}>
													Upload Employee Resume
												</Form.Label>
												<Col sm={10}>
													<div className="file-upload-div">
														<div className="file-preview">
															<Document file={displayResume}
																onLoadSuccess={this.onDocumentLoadSuccess}
																noData={<div style={{ width: '110px', height: '120px', border: '1px solid #bebebe' }}></div>}>
																<Page pageNumber={pdfPageNumber} height='120' width='120' className='change-pdf-size' />
															</Document>
														</div>

														<div className="upload-btn-wrapper">
															<button className="custom-upload-btn">Browse</button>
															<input type='file' label='Upload File' ref="emergency_resume"
																onChange={this.updateEmergencyInfoResume.bind(this)} />
														</div>
													</div>
												</Col>
											</Form.Group>
											<div className="submit-section">
												<button className="btn btn-primary submit-btn" data-toggle="modal"
													data-target="#emergency_contact_modal">Save
												</button>
											</div>
										</Form>
										<AddEmployeeHMOModal updateEmployeeHMOsList={this.updateEmployeeHMOsList} isShow={this.state.show_add_employeeHMO} changeShowEmployeeHMOState={this.changeShowEmployeeHMOState} />
										<AddLifeInsuranceModal updateLifeInsurancesList={this.updateLifeInsurancesList} isShow={this.state.show_add_lifeInsurance} changeShowLifeInsuranceState={this.changeShowLifeInsuranceState} />

									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Emergency Contact Modal -->*/}

						{/*<!-- Education Modal -->*/}
						<div id='education_info' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'> Education Information</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<Form onSubmit={this.saveEducationInfo}>
											{
												educationInfo.map((info, idx) => {
													return (
														<Form style={{
															border: "1px solid #e3e3e3",
															borderRadius: "5px",
															padding: "20px 10px",
															margin: "10px"
														}}>
															<Form.Group>
																Education Information
															</Form.Group>
															<Form.Group as={Row}>
																<Form.Label style={{ fontSize: '14px' }} column sm={2}>
																	Institution
																</Form.Label>
																<Col sm={4}>
																	<Form.Control value={info.institution} name="institution"
																		id={"institution" + idx}
																		onChange={this.handleEducationInfoField(idx)} />
																</Col>
																<Form.Label style={{ fontSize: '14px' }} column sm={2}>
																	Subject
																</Form.Label>
																<Col sm={4}>
																	<Form.Control value={info.subject} name="subject"
																		id={"subject" + idx}
																		onChange={this.handleEducationInfoField(idx)} />
																</Col>
															</Form.Group>
															<Form.Group as={Row}>
																<Form.Label style={{ fontSize: '14px' }} column sm={2}>
																	Starting Date
																</Form.Label>
																<Col sm={4}>
																	<div classname='datePicker'>
																		<i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
																		<Datepicker value={info.startDate} name="startDate"
																			id={"startDate" + idx}
																			closeOnSelect={true}
																			onChange={this.handleEducationInfoStartField(idx)} />
																	</div>

																</Col>
																<Form.Label style={{ fontSize: '14px' }} column sm={2}>
																	Complete Date
																</Form.Label>
																<Col sm={4}>
																	<div classname='datePicker'>
																		<i class="fa fa-calendar fa-xs calendar-icon" aria-hidden="true"></i>
																		<Datepicker value={info.completeDate} name="completeDate"
																			id={"completeDate" + idx}
																			closeOnSelect={true}
																			onChange={this.handleEducationInfoCompleteField(idx)} />
																	</div>

																</Col>
															</Form.Group>
															<Form.Group as={Row}>
																<Form.Label style={{ fontSize: '14px' }} column sm={2}>
																	Degree
																</Form.Label>
																<Col sm={4}>
																	<Form.Control value={info.degree} name="degree"
																		id={"degree" + idx}
																		onChange={this.handleEducationInfoField(idx)} />
																</Col>
																<Form.Label style={{ fontSize: '14px' }} column sm={2}>
																	Grade
																</Form.Label>
																<Col sm={4}>
																	<Form.Control value={info.grade} name="grade" id={"grade" + idx}
																		onChange={this.handleEducationInfoField(idx)} />
																</Col>
															</Form.Group>
															<Form.Group as={Row}>
																<Form.Label style={{ fontSize: '14px' }} column sm={2}>
																	Upload Employee Certificate
																</Form.Label>
																<Col sm={10}>
																	<div className="file-upload-div">
																		<div className="file-preview">
																			<Document file={displayEducationCertificates[idx]}
																				onLoadSuccess={this.onDocumentLoadSuccess}
																				noData={<div style={{ width: '110px', height: '120px', border: '1px solid #bebebe' }}></div>}>
																				<Page pageNumber={pdfPageNumber} height='120' width='120' className='change-pdf-size' />
																			</Document>
																		</div>

																		<div className="upload-btn-wrapper">
																			<button className="custom-upload-btn">Browse</button>
																			<input type='file' label='Upload File' name="certificate" ref={'certificate_' + idx}
																				onChange={this.handleEducationInfoFileField(idx)} />
																		</div>
																	</div>
																</Col>
															</Form.Group>
														</Form>
													);
												})
											}
											<div className="add-more">
												<a href="Javascript:void(0);" onClick={this.addMoreEducationInfo}><i
													className="fa fa-plus-circle" /> Add More</a>
											</div>

											<div className="submit-section">
												<button className="btn btn-primary submit-btn" data-toggle="modal"
													data-target="#education_info">Save
												</button>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Education Modal -->*/}

						{/*<!-- Experience Modal -->*/}
						<div id='experience_info' className='modal custom-modal fade' role='dialog'>
							<div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Experience Informations</h5>
										<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>
										<form>
											<div className='form-scroll'>
												<div className='card-box'>
													<h3 className='card-title'>Experience Informations <a href='Javascript:void(0);' className='delete-icon'><i className='fa fa-trash-o'></i></a></h3>
													<div className='row'>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<input type='text' className='form-control floating' value='Digital Devlopment Inc' />
																<label className='focus-label'>Company Name</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<input type='text' className='form-control floating' value='United States' />
																<label className='focus-label'>Location</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<input type='text' className='form-control floating' value='Web Developer' />
																<label className='focus-label'>Job Position</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<div className='cal-icon'>
																	<input type='text' className='form-control floating datetimepicker' value='01/07/2007' />
																</div>
																<label className='focus-label'>Period From</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<div className='cal-icon'>
																	<input type='text' className='form-control floating datetimepicker' value='08/06/2018' />
																</div>
																<label className='focus-label'>Period To</label>
															</div>
														</div>
													</div>
												</div>
												<div className='card-box'>
													<h3 className='card-title'>Experience Informations <a href='Javascript:void(0);' className='delete-icon'><i className='fa fa-trash-o'></i></a></h3>
													<div className='row'>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<input type='text' className='form-control floating' value='Digital Devlopment Inc' />
																<label className='focus-label'>Company Name</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<input type='text' className='form-control floating' value='United States' />
																<label className='focus-label'>Location</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<input type='text' className='form-control floating' value='Web Developer' />
																<label className='focus-label'>Job Position</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<div className='cal-icon'>
																	<input type='text' className='form-control floating datetimepicker' value='01/07/2007' />
																</div>
																<label className='focus-label'>Period From</label>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group form-focus'>
																<div className='cal-icon'>
																	<input type='text' className='form-control floating datetimepicker' value='08/06/2018' />
																</div>
																<label className='focus-label'>Period To</label>
															</div>
														</div>
													</div>
													<div className='add-more'>
														<a href='Javascript:void(0);'><i className='fa fa-plus-circle'></i> Add More</a>
													</div>
												</div>
											</div>
											<div className='submit-section'>
												<button className='btn btn-primary submit-btn'>Submit</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{/*<!-- /Experience Modal -->*/}

					</div>
					{/*<!-- /Page Wrapper -->*/}

				</div>
				{/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
				<div className='sidebar-overlay' data-reff=''></div>
			</body>
		)
	}
}

const mapStateToProps = state => {
	return {
		User: state.api.User,
	}
}

const mapDispatchToProps = dispatch => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Profile))

